import React, { useState, useEffect } from "react";
import "./styles.css";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";

const CloseButton = styled(Button)({
  position: "absolute",
  top: "0px",
  right: "0px",
  background: "none",
  border: "none",
  cursor: "pointer",
  fontSize: "1rem",
  padding: "0px",
  borderRadius: "25px", // Hacer que el borde sea completamente redondo
});

function ModelUrl({ isVisible, onClose }) {
  const [url, setUrl] = useState(localStorage.getItem("Url") || "");
  const [titulo, setTitulo] = useState(localStorage.getItem("Titulo") || "");
  const [isUrlValid, setIsUrlValid] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setIsUrlValid(!!url.trim());
    setTitulo(titulo);
  }, [url, titulo]);

  if (!isVisible) return null;

  const handleUrlChange = (event) => {
    const newUrl = event.target.value;
    localStorage.setItem("Url", newUrl);
    window.dispatchEvent(new Event("storage"));
    setUrl(newUrl);
  };

  const handleTituloChange = (event) => {
    const newTitulo = event.target.value;
    localStorage.setItem("Titulo", newTitulo);
    window.dispatchEvent(new Event("storage"));
    setTitulo(newTitulo);
  };

  const handelClose = (e) => {
    if (e.target.id === "close") onClose();
  };

  const validateURL = (url) => {
    const regex =
      /^(https:\/\/|http:\/\/)([a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})([/\w.-]*)*\/?$/;
    return regex.test(url);
  };

  const handleSaveClick = () => {
    if (isUrlValid) {
      if (validateURL(url)) {
        setErrorMessage("");
        onClose();
      } else {
        setErrorMessage(
          "Página web incorrecta. Asegúrate de que la URL tenga un protocolo válido (http:// o https://)."
        );
      }
    } else {
      setErrorMessage("Ingrese su página web para continuar.");
    }
  };

  const handleCancel = () => {
    localStorage.setItem("Url", "");
    localStorage.setItem("Titulo", "");
    window.dispatchEvent(new Event("storage"));
    setUrl("");
    setTitulo("");
    setErrorMessage("");
    onClose();
  };

  return (
    <Backdrop
      sx={{
        color: "#000",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={isVisible}
    >
      <div>
        <div className="modal-container">
          <div className="modal-body">
            <CloseButton variant="outlined" onClick={onClose}>
              x
            </CloseButton>
            <div className="input-container">
              <div className="modal-header">
                <h5>Título sitio web</h5>
              </div>
              <div className="form-floating">
                <input
                  type="text"
                  name="titulo"
                  value={titulo}
                  onChange={handleTituloChange}
                  className="input-register"
                  placeholder=""
                />
              </div>
              <div className="example" style={{ textAlign: "right" }}>
                Ejemplo:{" "}
                <span style={{ color: "blue" }}>Tarjetas de presentación </span>
              </div>
              <div className="modal-header">
                <h5>Url sitio web</h5>
              </div>
              <div className="form-floating">
                <input
                  type="text"
                  name="url"
                  value={url}
                  onChange={handleUrlChange}
                  className="input-register"
                  placeholder=""
                />
              </div>
              <div className="example" style={{ textAlign: "right" }}>
                Ejemplo:{" "}
                <span style={{ color: "blue" }}>https://www.sitioweb.com</span>
              </div>
              {errorMessage && (
                <div className="error-message">{errorMessage}</div>
              )}
              <div className="linea-botones">
                <button onClick={handleCancel} className="cancel-button">
                  Vaciar
                </button>
                <button
                  type="button"
                  onClick={handleSaveClick}
                  // disabled={!isUrlValid}
                  className="submit-button"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
}

export default ModelUrl;
