/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { MainListItems, SecondaryListItems } from "./ListItems";
import AuthService from "../../../auth-service";
import { Reports } from "./Reports";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ListarProfesion from "./ListarProfesion";
import DashboardTarget from "./DashboardTarget";
import Promociones from "./Promociones";
import Usuarios from "./Usuarios";
import Negocios from "./Negocios";
import Ajustes from "./Ajustes";
import DetallesEntidadComercial from "../../Negocios/DetallesEntidadComercial";

const settings = ["Cerrar Sesión"];
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

function DashboardContent() {
  const baseUrl = process.env.REACT_APP_BACKEND_URL;
  const [perfil, setPerfil] = React.useState("");
  const [nombre, setNombre] = React.useState("");
  const tarjetaId = localStorage.getItem("tarjetaID");

  const [open, setOpen] = React.useState(false);
  const [abrir, setAbrir] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const [mostrarReportes, setMostrarReportes] = useState(false);
  const [mostrarProfesiones, setMostrarProfesiones] = useState(false);
  const [mostrarTarjeta, setMostrarTarjeta] = useState(true);
  const [mostrarPromociones, setMostrarPromociones] = useState(false);
  const [mostrarUsuarios, setMostrarUsuarios] = useState(false);
  const [mostrarNegocios, setMostrarNegocios] = useState(false);
  const [mostrarNegocio, setMostrarNegocio] = useState(false);
  const [mostrarAjustes, setMostrarAjustes] = useState(false);
  const [rol, setRol] = React.useState("");
  const [comercio, setComercio] = useState([]);

  useEffect(() => {
    const { authorisation } = JSON.parse(localStorage.getItem("user"));
    const userComercios = authorisation?.data?.comercios;
    let rolActualizado = authorisation?.data?.rol;
    let comercioF = userComercios.find((comercio) => comercio.rol !== "USER");

    if (userComercios) {
      userComercios.forEach((comercio) => {
        if (comercio.rol !== "USER") {
          rolActualizado = comercio.rol;
          comercioF = comercio.comercio;
        }
      });
    }
    if (authorisation?.data?.user_tarjeta.length === 0) {
      console.log("No hay tarjeta", authorisation?.data?.user_tarjeta.length);
      rolActualizado = "NA";
    }
    setComercio(comercioF);
    setRol(rolActualizado);
  }, []);

  useEffect(() => {
    fetchDatos();
  }, [tarjetaId]);

  const fetchDatos = async () => {
    try {
      const { data } = await axios.post(
        baseUrl + `usuario/tarjeta/ver/${tarjetaId}`,
        null // aquí debe ir el body, pero en este caso no es necesario enviar datos adicionales
      );
      if (data) {
        setPerfil(data.data.img_perfil);
        setNombre(data.data.nombre);
      }
    } catch (error) {
      console.error("Error al obtener los datos del usuario", error);
    }
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleShowReportes = () => {
    setMostrarTarjeta(false); // Oculta la tarjeta si estaba visible
    setMostrarProfesiones(false); // Oculta el listado de profesiones si estaba visible
    setMostrarReportes(true);
    setMostrarPromociones(false);
    setMostrarUsuarios(false);
    setMostrarNegocios(false);
    setMostrarAjustes(false);
    setMostrarNegocio(false);
  };

  const handleShowProfesiones = () => {
    setMostrarReportes(false); // Oculta el listado de reportes si estaba visible
    setMostrarTarjeta(false); // Oculta la tarjeta si estaba visible
    setMostrarProfesiones(true);
    setMostrarPromociones(false);
    setMostrarUsuarios(false);
    setMostrarNegocios(false);
    setMostrarAjustes(false);
    setMostrarNegocio(false);
  };

  const handleShowTarjeta = () => {
    setMostrarReportes(false); // Oculta el listado de reportes si estaba visible
    setMostrarProfesiones(false); // Oculta el listado de profesiones si estaba visible
    setMostrarTarjeta(true);
    setMostrarPromociones(false);
    setMostrarUsuarios(false);
    setMostrarNegocios(false);
    setMostrarAjustes(false);
    setMostrarNegocio(false);
  };

  const handleShowPromociones = () => {
    setMostrarReportes(false); // Oculta el listado de reportes si estaba visible
    setMostrarProfesiones(false); // Oculta el listado de profesiones si estaba visible
    setMostrarTarjeta(false); // Oculta la tarjeta si estaba visible
    setMostrarPromociones(true);
    setMostrarUsuarios(false);
    setMostrarNegocios(false);
    setMostrarAjustes(false);
    setMostrarNegocio(false);
  };

  const handleShowUsuarios = () => {
    setMostrarReportes(false); // Oculta el listado de reportes si estaba visible
    setMostrarProfesiones(false); // Oculta el listado de profesiones si estaba visible
    setMostrarTarjeta(false); // Oculta la tarjeta si estaba visible
    setMostrarPromociones(false); // Oculta las promociones si estaba visible
    setMostrarUsuarios(true);
    setMostrarNegocios(false);
    setMostrarAjustes(false);
    setMostrarNegocio(false);
  };

  const handleShowNegocios = () => {
    setMostrarReportes(false); // Oculta el listado de reportes si estaba visible
    setMostrarProfesiones(false); // Oculta el listado de profesiones si estaba visible
    setMostrarTarjeta(false); // Oculta la tarjeta si estaba visible
    setMostrarPromociones(false); // Oculta las promociones si estaba visible
    setMostrarUsuarios(false);
    setMostrarNegocios(true);
    setMostrarAjustes(false);
    setMostrarNegocio(false);
  };

  const handleShowAjustes = () => {
    setMostrarReportes(false); // Oculta el listado de reportes si estaba visible
    setMostrarProfesiones(false); // Oculta el listado de profesiones si estaba visible
    setMostrarTarjeta(false); // Oculta la tarjeta si estaba visible
    setMostrarPromociones(false); // Oculta las promociones si estaba visible
    setMostrarUsuarios(false);
    setMostrarNegocios(false);
    setMostrarAjustes(true);
    setMostrarNegocio(false);
  };

  const handleShowNegocio = () => {
    setMostrarNegocio(true);
    setMostrarReportes(false); // Oculta el listado de reportes si estaba visible
    setMostrarProfesiones(false); // Oculta el listado de profesiones si estaba visible
    setMostrarTarjeta(false); // Oculta la tarjeta si estaba visible
    setMostrarPromociones(false); // Oculta las promociones si estaba visible
    setMostrarUsuarios(false);
    setMostrarNegocios(false);
    setMostrarAjustes(false);
  };

  const handleLogout = () => {
    AuthService.logout();
    window.location.href = "/";
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h4"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              OnlyTAP
            </Typography>
            <Box sx={{ flexGrow: 0 }}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={perfil ?? nombre}>
                  {perfil ? (
                    <img
                      alt={"perfil"}
                      src={perfil}
                      style={{ width: "50px" }}
                    />
                  ) : (
                    nombre?.charAt(0)
                  )}
                </Avatar>
              </IconButton>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem
                    key={setting}
                    onClick={() => {
                      if (setting === "Cerrar Sesión") {
                        handleLogout();
                      } else {
                        handleCloseUserMenu();
                      }
                    }}
                  >
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            <MainListItems
              userRole={rol}
              onShowReportes={handleShowReportes}
              onShowProfesiones={handleShowProfesiones}
              onShowTarjeta={handleShowTarjeta}
              onShowPromociones={handleShowPromociones}
              onShowUsuarios={handleShowUsuarios}
              onShowNegocios={handleShowNegocios}
              onShowNegocio={handleShowNegocio}
              onShowManagement={handleShowAjustes}
            />
            <Divider sx={{ my: 1 }} />
            {SecondaryListItems}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
            width: "100%",
          }}
        >
          <Toolbar />
          <Container maxWidth sx={{ mt: 5, mb: 4 }}>
            {mostrarReportes ? (
              <Reports />
            ) : mostrarProfesiones ? (
              <ListarProfesion />
            ) : mostrarTarjeta ? (
              <DashboardTarget />
            ) : mostrarPromociones ? (
              <Promociones rol={rol} entidad={comercio} />
            ) : mostrarUsuarios ? (
              <Usuarios />
            ) : mostrarNegocios ? (
              <Negocios />
            ) : mostrarNegocio ? (
              <DetallesEntidadComercial entidad={comercio} />
            ) : mostrarAjustes ? (
              <Ajustes />
            ) : (
              <ToastContainer />
            )}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}
