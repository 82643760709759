import React, { useEffect, useState } from "react";
import Form from "./usuariosForm";
import axios from "axios";
import Button from "@mui/material/Button";
import ListUsuarios from "./ListUsers";

function Usuarios() {
  const baseUrl = process.env.REACT_APP_BACKEND_URL;
  const [tarjeta, setTarjeta] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [negocios, setNegocios] = useState([]);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

  useEffect(() => {
    const entidadesLocalStorage = JSON.parse(
      localStorage.getItem("entidadesComerciales")
    );
    if (entidadesLocalStorage) {
      setNegocios(entidadesLocalStorage);
    }
  }, []);

  const handleAbrir = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const tarjetaGuardada = JSON.parse(localStorage.getItem("Tarjeta"));
    setTarjeta(tarjetaGuardada.wallet);
  }, []);

  const [rol, setRol] = React.useState("");

  useEffect(() => {
    const { authorisation } = JSON.parse(localStorage.getItem("user"));
    const userComercios = authorisation?.data?.comercios;
    let rolActualizado = authorisation?.data?.rol; // Asignar rol inicial

    if (userComercios) {
      userComercios.forEach((comercio) => {
        if (comercio.rol !== "USER") {
          rolActualizado = comercio.rol;
        }
      });
    }
    setRol(rolActualizado);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        };
        const { data } = await axios.post(
          baseUrl + "comercio/listar/todos",
          null,
          config
        );
        console.log(data.data);
        const negocios = data.data;
        setNegocios(negocios);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span>Rol: {rol}</span>
        {(rol === "ADMIN" ||
          rol === "PROPIETARIO" ||
          rol === "GERENTE" ||
          rol === "CAJERO") && (
          <Button
            onClick={handleAbrir}
            variant="contained"
            sx={{
              width: "30%",
              marginBottom: "10px",
              margin: "9px 0px",
            }}
          >
            Crear
          </Button>
        )}
      </div>
      {open && <Form negocios={negocios} role={rol} />}
      <div
        className=""
        style={{
          borderRadius: "10px",
          border: "1px solid #E0E0E0",
          padding: "20px",
          backgroundColor: "white",
        }}
      >
        <h2>Usuarios</h2>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
            textAlign: "right", // Alineación a la derecha
          }}
        >
          <ListUsuarios user={user.authorisation.data} negocios={negocios} />
        </div>
      </div>
    </div>
  );
}

export default Usuarios;
