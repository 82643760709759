
import React, { useEffect, useState }  from 'react';
import { TableHead, Box, TableRow, TableSortLabel } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { StyledTableCell } from './TableFunctions';
import PropTypes from 'prop-types'

export const EnhancedTableHead = (props) => {

    const [estilo, setEstilo] = useState(null)

    const { order, orderBy, onRequestSort, headCells } = props
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    }

    useEffect(() => {
        // console.log(interfaz)
        if (localStorage.getItem("interfaz")) {
            // console.log(JSON.parse(localStorage.getItem("interfaz")))
            const slug = JSON.parse(localStorage.getItem("interfaz"))
            setEstilo(slug)
        }
    }, [])

    return (
        <TableHead>
            <TableRow
                sx={{
                    background: (!estilo)?'linear-gradient(90deg,#053d6b,#437294)':estilo.color_gradient
                }}>
                {headCells.map((headCell) => (
                    <StyledTableCell
                        key={headCell.id}
                        align="left"
                        padding="normal"
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{ color: (!estilo)?'white':'black' }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc'
                                        ? 'sorted descending'
                                        : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
}
