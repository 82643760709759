import React, { useState, useEffect } from "react";
import "./styles.css";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";

const CloseButton = styled(Button)({
  position: "absolute",
  top: "0px",
  right: "0px",
  background: "none",
  border: "none",
  cursor: "pointer",
  fontSize: "1rem",
  padding: "0px",
  borderRadius: "25px", // Hacer que el borde sea completamente redondo
});
function ModelJob({ isVisible, onClose }) {
  const [profesion, setProfesion] = useState(
    localStorage.getItem("Profesion") || ""
  );
  const [isProfesionValid, setIsProfesionValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setIsProfesionValid(!!profesion.trim());
  }, [profesion]);

  if (!isVisible) return null;

  const handleProfesionChange = (event) => {
    const newProfesion = event.target.value;
    localStorage.setItem("Profesion", newProfesion);
    window.dispatchEvent(new Event("storage"));
    setProfesion(newProfesion);
  };

  const handelClose = (e) => {
    if (e.target.id === "close") onClose();
  };

  const validateJob = (job) => {
    if (job.endsWith(" ")) {
      job = job.slice(0, -1);
    }
    const nameRegex = /^[a-zA-ZáéíóúñÁÉÍÓÚÑ\s.]+$/;
    return nameRegex.test(job);
  };

  const handleSaveClick = () => {
    if (isProfesionValid) {
      if (validateJob(profesion)) {
        setErrorMessage("");
        onClose();
      } else {
        setErrorMessage("Profesión incorrecta");
      }
    } else {
      setErrorMessage("Ingrese su profesión para continuar.");
    }
  };

  const handleCancel = () => {
    localStorage.setItem("Profesion", "");
    window.dispatchEvent(new Event("storage"));
    setProfesion("");
    setErrorMessage("");
    onClose();
  };

  return (
    <Backdrop
      sx={{
        color: "#000",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={isVisible}
    >
      <div className="modal-container">
        <div className="modal-body">
          <CloseButton variant="outlined" onClick={onClose}>
            x
          </CloseButton>
          <div className="input-container">
            <div className="modal-header">
              <h5>Profesión</h5>
            </div>
            <div className="form-floating">
              <input
                type="text"
                name="profesion"
                value={profesion}
                onChange={handleProfesionChange}
                className="input-register my-3"
                placeholder=""
              />
            </div>
            <div className="example" style={{ textAlign: "right" }}>
              Ejemplo:{" "}
              <span style={{ color: "blue" }}>Ingeniero en Software</span>
            </div>
            {errorMessage && (
              <div className="error-message">{errorMessage}</div>
            )}
            <div className="linea-botones">
              <button onClick={handleCancel} className="cancel-button">
                Vaciar
              </button>
              <button
                type="button"
                onClick={handleSaveClick}
                className="submit-button"
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
}

export default ModelJob;
