import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import MuiDialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import Swal from "sweetalert2";
import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Grid,
} from "@mui/material";
import axios from "axios";

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));

const DialogTitleRoot = styled(MuiDialogTitle)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(2),
  "& .closeButton": {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const DialogTitle = (props) => {
  const { children, onClose } = props;
  return (
    <DialogTitleRoot disableTypography>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className="closeButton"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitleRoot>
  );
};

const baseUrl = process.env.REACT_APP_BACKEND_URL;

const AsignarUsuarioModal = ({
  usuario,
  tarjeta,
  handleClose,
  handleConercioCreado,
}) => {
  console.log(usuario, tarjeta, handleClose);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      //lógica para enviar los datos a tu backend
      console.log(usuario, tarjeta);
      setLoading(true);

      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      };

      const response = await axios.post(
        baseUrl + `usuario/entidad/crear`,
        {
          usuario_id: usuario.id,
          comercio_id: tarjeta.id,
          rol: "PROPIETARIO",
          registrado_por: user.id,
        },
        { headers }
      );
      const { data } = response;
      console.log(response);
      if (response.status === 201) {
        Swal.fire({
          icon: "success",
          title: "Comercio creado y usuario asignado con éxito",
          text: "",
          confirmButtonText: "Aceptar",
        });
        handleConercioCreado(data);
        setLoading(false);
        handleCloseDialog();
        handleClose();
      }
    } catch (error) {
      setLoading(false);
      // Revisamos si el error proviene de una respuesta del servidor y tiene un estado 422
      if (error.response && error.response.status === 422) {
        const errores = error.response.data; // Obtenemos los detalles del error del servidor

        // Convertimos los mensajes de error en un array de strings
        const mensajesDeError = Object.values(errores.data).flat(); // Usamos .flat() para aplanar en caso de que haya arrays anidados

        // Unimos todos los mensajes de error en un solo string, separados por un salto de línea
        const textoDeErrores = mensajesDeError.join("\n");

        // Mostramos el Swal con los errores específicos
        Swal.fire({
          icon: "error",
          title: "Ha ocurrido un error al crear el negocio",
          text: "\n" + textoDeErrores,
          confirmButtonText: "Aceptar",
          heightAuto: false, // Asegura que el modal se muestre correctamente si hay muchos errores
        });
      } else {
        // Manejo de otros tipos de errores no relacionados con la respuesta del servidor
        Swal.fire({
          icon: "error",
          title: "Error al crear el negocio",
          text: "Ha ocurrido un error desconocido al crear el negocio",
          confirmButtonText: "Aceptar",
        });
      }
      console.error("Error al crear el negocio:", error);
    }
  };

  useEffect(() => {
    const userDataJSON = localStorage.getItem("user");
    if (userDataJSON) {
      const { authorisation } = JSON.parse(userDataJSON);
      setUser(authorisation.data);
    }
  }, []);

  return (
    <div>
      <StyledButton
        variant="outlined"
        type="submit"
        color="primary"
        onClick={handleOpenDialog}
        sx={{
          width: "max-content",
          fontWeight: "bold",
          border: "2px solid",
          color: "#25D366",
          background: "#ffffff",
          borderRadius: "10px",
          margin: "0 auto",
        }}
      >
        Asignar
      </StyledButton>

      <Dialog
        open={openDialog}
        // onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          textAlign: "center",
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            fontWeight: "100",
          }}
        >
          Confirmar asignación de propietario
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div>
              {" "}
              ¿Está seguro de que desea asignar a{" "}
              <strong>
                {usuario?.nombres} {usuario?.apellidos}
              </strong>{" "}
              como PROPIETARIO de <strong>{tarjeta.nombre_comercial}</strong>?
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className="linea-botones">
            <button
              type="button"
              onClick={handleCloseDialog}
              className="cancel-button"
            >
              Cancelar
            </button>
            <div>
              <button
                type="button"
                onClick={handleSubmit}
                className="submit-button"
              >
                Asignar
              </button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AsignarUsuarioModal;
