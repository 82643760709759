import React, { useState, useEffect } from "react";
import "react-phone-number-input/style.css";
import "./styles.css";
import TextField from "@mui/material/TextField";
import axios from "axios";
import Swal from "sweetalert2";
import { FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";

const baseUrl = process.env.REACT_APP_BACKEND_URL;

const ModalActivarEfectivo = ({
  isVisible,
  onClose,
  max,
  id,
  actualizarEfectivo,
}) => {
  const [numTarjetas, setNumTarjetas] = React.useState("");
  const [estado, setEstado] = React.useState(true);
  const [tipoVenta, setTipoVenta] = useState("only");
  useEffect(() => {
    if (max <= 0) {
      setTipoVenta("reviews");
    } else {
      setTipoVenta("only");
    }
  }, [max]);
  useEffect(() => {
    if (numTarjetas === "") {
      setEstado(true);
    } else {
      setEstado(false);
    }
  }, [numTarjetas]);

  if (!isVisible) return null;
  const handleChangeTarjetas = (event) => {
    let newNumTarjeta = event.target.value;
    if (newNumTarjeta < 1) {
      newNumTarjeta = 1;
    } else if (tipoVenta === "only" && newNumTarjeta > max) {
      newNumTarjeta = max;
    }
    setNumTarjetas(newNumTarjeta);
  };

  const handleSaveClick = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      };

      let campoCobrar;

      if (tipoVenta === "only") {
        campoCobrar = "clic_cobrar";
      } else if (tipoVenta === "reviews") {
        campoCobrar = "efectivo_reviews";
      } else if (tipoVenta === "menu") {
        campoCobrar = "efectivo_menu";
      }

      const response = await axios.post(
        baseUrl + `usuario/tarjeta/actualizar/${id}`,
        { [campoCobrar]: numTarjetas },
        { headers }
      );
      if (response.status === 200) {
        actualizarEfectivo(id, numTarjetas, campoCobrar);
        Swal.fire({
          icon: "success",
          title: "Efectivo activado",
          text: "Se ha activado el efectivo correctamente",
          confirmButtonText: "Aceptar",
        });
        onClose();
      }
    } catch (error) {
      console.error("Error al actualizar el estado de la tarjeta:", error);
    }
  };

  const handleCancel = () => {
    window.dispatchEvent(new Event("storage"));
    setNumTarjetas("");
    onClose();
  };

  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={isVisible}
    >
      <div className="modal-container">
        <div className="modal-body">
          <div className="input-container">
            <div className="modal-header">
              <h5>Activar ventas</h5>
              <Button onClick={onClose} className="close-button">
                X
              </Button>
            </div>
            <div>
              <div className="column-container">
                {/* Primera columna con RadioGroups */}
                {max > 0 ? (
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="only"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="only"
                      control={<Radio />}
                      label="OnlyTap"
                      onChange={(event) => setTipoVenta(event.target.value)}
                    />
                    <FormControlLabel
                      value="reviews"
                      control={<Radio />}
                      label="Reviews"
                      onChange={(event) => setTipoVenta(event.target.value)}
                    />
                    <FormControlLabel
                      value="menu"
                      control={<Radio />}
                      label="Menu"
                      onChange={(event) => setTipoVenta(event.target.value)}
                    />
                  </RadioGroup>
                ) : (
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="reviews"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="reviews"
                      control={<Radio />}
                      label="Reviews"
                      onChange={(event) => setTipoVenta(event.target.value)}
                    />
                    <FormControlLabel
                      value="menu"
                      control={<Radio />}
                      label="Menu"
                      onChange={(event) => setTipoVenta(event.target.value)}
                    />
                  </RadioGroup>
                )}
              </div>

              <div className="column-container">
                {/* Segunda columna con Inputs */}

                <TextField
                  id="outlined-basic"
                  value={numTarjetas}
                  onChange={handleChangeTarjetas}
                  label={
                    tipoVenta === "only"
                      ? "Nº OnlyTap vendidas"
                      : tipoVenta === "reviews"
                      ? "Nº Reviews vendidas"
                      : tipoVenta === "menu"
                      ? "Ganancia"
                      : ""
                  }
                  type="number"
                  variant="outlined"
                  sx={{
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: "10px",
                    margin: "10px 0px",
                  }}
                  InputProps={{
                    inputProps: {
                      min: 1,
                      ...(tipoVenta === "only" && { max: max }),
                    },
                  }}
                />
              </div>
            </div>

            <div className="linea-botones">
              <button onClick={handleCancel} className="cancel-button">
                Cancelar
              </button>
              <button
                onClick={handleSaveClick}
                disabled={estado}
                className="submit-button"
              >
                Activar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default ModalActivarEfectivo;
