import { useState } from "react"

/**
 **HOOK PARA VALIDAR CAMPOS QUE SON STRINGS DE NÚMEROS COMO CÉDULAS, TELEFONOS, ETC
 * @param initialValues: objeto con los valores que serán validados
 * @returns values: objeto con los valores recibidos
 * @returns validInputChange: función para cambiar los valores anteriores. Debe ir en el 'onChange' del input
 * @returns reset: función para reestablecer los valores al inicial
 */
export const useNumericStringValues = ( initialValues = {} ) => {
    
    const regexNum = new RegExp('^[0-9]+$', 'i');
    const [values, setValues] = useState(initialValues);

    //recibe el event
    const validInputChange = ({target}) =>{
        if (regexNum.test(target.value) || target.value.length === 0) {
            setValues({
                ...values,
                [target.name]: target.value
            })
        }
    }

    const reset = () => {
        setValues(initialValues)
    }

    return [ values, validInputChange, reset ]
}