import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import ModalDetalles from "./ModalDetalles";
import { SimpleCard } from "../../compontents/SimpleCard";
import { useTheme } from "@mui/system";
import {
  Box,
  Grid,
  Icon,
  styled,
  Badge,
  Avatar,
  Container,
} from "@mui/material";
import ModalCrearUsuario from "../Dashboard/Components/modals/modalCrearUsuario";

const Parrafo = styled("h5")(({ theme }) => ({
  padding: "0px",
  color: "#242532",
  fontSize: "16px",
  fontWeight: "bold",
  margin: "10px 0px 0px 0px",
}));

const ParrafoC = styled("h5")(({ theme }) => ({
  padding: "0px",
  color: "#242532",
  fontSize: "14px",
  fontWeight: "normal",
  margin: "0px 0px 20px 0px",
  align: "center",
}));

const TipoUsuario = styled("small")(({ bgcolor }) => ({
  height: 30,
  width: "max-content",
  alignSelf: "center",
  fontSize: "14px",
  color: "#ffffff",
  padding: "10px 15px",
  borderRadius: "4px",
  background: bgcolor,
  borderRadius: "50px",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));

/**
 *
 * @param  listaEntidades los nuevos registros que vienen de 10en 10 de la paginación
 * @param  getPage función para obtener la pagina que se consulta
 * @param  totalRegs el total de registros que trae la consulta a la api
 * @returns  el componente tabla
 */

export const Usuarios = ({
  listaEntidades = [],
  getPage,
  totalRegs,
  entidad,
}) => {
  const [estilo, setEstilo] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // console.log(interfaz)
    if (localStorage.getItem("interfaz")) {
      // console.log(JSON.parse(localStorage.getItem("interfaz")))
      const slug = JSON.parse(localStorage.getItem("interfaz"));
      setEstilo(slug);
    }
  }, []);

  const history = useNavigate();

  const [entidadName, setEntidadName] = useState("");

  const theme = useTheme();

  const createUser = () => {
    setShowModal(true);
  };

  console.log("lista", listaEntidades);

  const coloresRol = {
    ADMIN: "#053D6B",
    PROPIETARIO: "#667AD9",
    GERENTE: "#DA4040",
    CAJERO: "#1F858A",
    MESERO: "#2BAE5A",
    COCINERO: "#FFA000",
    USER: "#4CAF50",
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container>
            {listaEntidades.map((entidad, index) => (
              <Grid
                key={index}
                item
                xs={12}
                sm={5}
                md={3}
                lg={2}
                sx={{
                  borderLeft: `6px solid ${coloresRol[entidad?.rol]}`,
                  borderRadius: "5px",
                  textAlignLast: "center",
                  boxShadow:
                    "inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0  0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3)",
                  margin: "10px 20px 10px 20px",
                  minWidth: "233px",
                }}
              >
                <SimpleCard alignItems="center">
                  <Container
                    sx={{
                      borderBottom: "2px solid #DCDCDC",
                      margin: "20px 0px 10px 0px",
                      paddingBottom: "20px",
                    }}
                  >
                    <Box display="flex">
                      <Box
                        alignItems="center"
                        sx={{
                          display: "inline-block",
                          height: "80px",
                          marginBottom: "16px",
                          marginLeft: "auto",
                          marginRight: "auto",
                          position: "relative",
                          textAlign: "center",
                          width: "80px",
                        }}
                      >
                        <Badge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                        >
                          <Avatar
                            src={
                              entidad?.usuario?.user_tarjeta[0]?.img_perfil
                                ? entidad?.usuario?.user_tarjeta[0]?.img_perfil
                                : ""
                            }
                            sx={{
                              cursor: "pointer",
                              width: 80,
                              height: 80,
                            }}
                          />
                        </Badge>
                      </Box>
                    </Box>
                    <Parrafo>
                      {entidad?.usuario?.nombres} {entidad?.usuario?.apellidos}
                    </Parrafo>
                    <ParrafoC>{entidadName}</ParrafoC>
                    <TipoUsuario bgcolor={coloresRol[entidad?.rol]}>
                      {entidad?.rol}
                    </TipoUsuario>
                  </Container>
                  <ModalDetalles user={entidad?.usuario} />
                </SimpleCard>
              </Grid>
            ))}
            <Grid
              item
              xs={12}
              sm={5}
              md={3}
              lg={2}
              sx={{
                border: "4px solid",
                borderStyle: "dashed",
                borderRadius: "10px",
                textAlignLast: "center",
                boxShadow:
                  "inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0  0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3)",
                margin: "10px 20px 10px 20px",
                color: !estilo ? "#437294" : estilo.color_principal,
                minWidth: "233px",
              }}
            >
              <SimpleCard alignItems="center">
                <Box display="flex" sx={{ padding: "50px 0px 10px 0px" }}>
                  <Box
                    alignItems="center"
                    sx={{
                      display: "inline-block",
                      height: "80px",
                      marginBottom: "16px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      position: "relative",
                      textAlign: "center",
                      width: "80px",
                    }}
                  >
                    <Badge
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                    >
                      <Avatar
                        sx={{
                          cursor: "pointer",
                          width: 80,
                          height: 80,
                          background: !estilo
                            ? "#437294"
                            : estilo.color_principal,
                        }}
                      />
                    </Badge>
                  </Box>
                </Box>
                <Box sx={{ pt: 3, display: "flex", justifyContent: "center" }}>
                  <StyledButton
                    variant="outlined"
                    type="submit"
                    color="primary"
                    onClick={createUser}
                    sx={{
                      width: "max-content",
                      fontWeight: "bold",
                      border: "2px solid",
                      color: !estilo ? "#437294" : estilo.color_principal,
                      borderRadius: "10px",
                    }}
                  >
                    <Icon sx={{ mr: "10px" }}>add</Icon>
                    Agregar nuevo usuario
                  </StyledButton>
                </Box>
              </SimpleCard>
            </Grid>
          </Grid>
        </Grid>
        <ModalCrearUsuario
          isVisible={showModal}
          onClose={() => setShowModal(false)}
          negocios={entidad}
        />
      </Grid>
    </>
  );
};
