import React, { useState } from "react";
import { uploadFileToFB } from "../../../../firebaseConfig";
import { Button, InputLabel, Input, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import "./styles.css";
import Backdrop from "@mui/material/Backdrop";

const UploadFormContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "16px",
  border: "2px dashed",
  borderColor: "#3f51b5",
  borderRadius: "4px",
  backgroundColor: "#f5f5f5",
  color: "#616161",
  outline: "none",
  transition: "border .24s ease-in-out",
  "&:hover": {
    borderColor: "#283593",
  },
});

const CloseButton = styled(Button)({
  position: "absolute",
  top: "0px",
  right: "0px",
  background: "none",
  border: "none",
  cursor: "pointer",
  fontSize: "1rem",
  padding: "0px",
  borderRadius: "25px", // Hacer que el borde sea completamente redondo
});

function ModelPictureProfile({ isVisible, onClose }) {
  const [imageUrl, setImageUrl] = useState("");
  const [showImage, setShowImage] = useState(false);
  const [imgFile, setImgFile] = useState(null);
  const [urlPerfil, setUrlPerfil] = useState("");
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleImageChange = async (e) => {
    setLoading(true);

    const file = e.target.files[0];
    const validExts = ["png", "jpg", "jpeg", ".PNG"];

    if (file) {
      const ext = file.name.split(".")[1];
      const uploadedUrl = await uploadFileToFB(file, "registerPerfil");
      setUrlPerfil(uploadedUrl.url);
      if (!validExts.includes(ext)) {
        alert(
          "Error",
          `El archivo con extensión .${ext} no está permitido como logo`,
          "error"
        );
        setLoading(false);
        return;
      }
      setImgFile(file);
    }
    const url = URL.createObjectURL(file);
    setImageUrl(url);
    setShowImage(true);
    //localStorage.setItem("FotoPerfil", urlPerfil);
    setLoading(false);
  };

  const handleRemoveImage = () => {
    localStorage.removeItem("FotoPerfil");
    window.dispatchEvent(new Event("storage"));
    setImageUrl("");
    setShowImage(false);
    setImgFile(null);
    setUrlPerfil("");
    setUrl("");
    setLoading(false);
    setErrorMessage("");
    onClose();
  };

  if (!isVisible) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (imgFile !== null) {
      setLoading(true);
      const uploadedUrl = await uploadFileToFB(imgFile, "storagePerfil");
      setLoading(false);
      localStorage.setItem("FotoPerfil", urlPerfil);
      const perfil = localStorage.getItem("FotoPerfil");
      if (perfil === null || perfil === "undefined" || perfil === "") {
        localStorage.setItem(
          "FotoPortada",
          "https://us.123rf.com/450wm/redicka/redicka1410/redicka141000095/32216620-sin-fisuras-patr%C3%B3n-geom%C3%A9trico-negro-para-su-dise%C3%B1o-de-portada-dise%C3%B1o-de-libros-fondo-del-sitio.jpg"
        );
      }
      setUrl(uploadedUrl.url);
      window.dispatchEvent(new Event("storage"));
      setErrorMessage("");
      onClose();
    } else {
      setErrorMessage("Seleccione una imagen.");
    }
  };

  return (
    <Backdrop
      sx={{
        color: "#000",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={isVisible}
    >
      <div>
        <div className="modal-container">
          <div className="modal-body">
            <CloseButton variant="outlined" onClick={onClose}>
              x
            </CloseButton>
            <div className="input-container">
              <div className="modal-header">
                <h5>Foto de perfil</h5>
              </div>
              {showImage ? (
                <>
                  <div className="form-floating mb-5">
                    <div className="image-preview-container">
                      <img
                        src={imageUrl}
                        alt="Preview"
                        width={200}
                        height={200}
                      />
                      <button
                        type="button"
                        className="remove-image-button"
                        onClick={handleRemoveImage}
                      >
                        X
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <UploadFormContainer>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <InputLabel
                        htmlFor="upload-input"
                        style={{ marginBottom: "8px" }}
                      >
                        Subir foto selfie
                      </InputLabel>
                      <Input
                        id="upload-input"
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleImageChange}
                        className="input-register my-3"
                      />
                      <label htmlFor="upload-input">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                        >
                          Seleccionar foto
                        </Button>
                      </label>
                    </>
                  )}
                </UploadFormContainer>
              )}
              <div className="example" style={{ textAlign: "right" }}>
                Archivos permitidos:{" "}
                <span style={{ color: "blue" }}>
                  "png", "jpg", "jpeg", ".PNG"
                </span>
              </div>
              {errorMessage && (
                <div className="error-message">{errorMessage}</div>
              )}
              <div className="linea-botones">
                <button
                  className="cancel-button"
                  onClick={handleRemoveImage}
                  disabled={loading}
                >
                  Vaciar
                </button>
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="submit-button"
                  disabled={loading}
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
}

export default ModelPictureProfile;
