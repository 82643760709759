import React, { useEffect, useState } from "react";
import axios from "axios";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CardActionArea from "@mui/material/CardActionArea";
import Avatar from "@mui/material/Avatar";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import facebookIcon from "../../assets/img/iconos/facebook.png";
import x from "../../assets/img/iconos/x.jpg";
import instagramIcon from "../../assets/img/iconos/instagram.png";
import linkedinIcon from "../../assets/img/iconos/linkedin.png";
import youtubeIcon from "../../assets/img/iconos/youtube.png";
import tiktokIcon from "../../assets/img/iconos/tiktok.png";
import whatsapp from "../../assets/img/iconos/WhatsApp.jpeg";
import telegramIcon from "../../assets/img/iconos/telegram.png";
import Box from "@mui/material/Box";
import LanguageIcon from "@mui/icons-material/Language";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import AddLocationAltSharpIcon from "@mui/icons-material/AddLocationAltSharp";
import EmailIcon from "@mui/icons-material/Email";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import TelegramIcon from "@mui/icons-material/Telegram";
import ExampleComponent from "./Qr";
import { saveAs } from "file-saver";
import logoMarcaAgua from "../../assets/img/logo.png";
import Button from "@mui/material/Button";
import PhoneIcon from "@mui/icons-material/Phone";
import { styled } from "@mui/material/styles";
import Swal from "sweetalert2";
import loadFont from "react-font-loader";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { createChainedFunction } from "@mui/material";

const MyButton = styled(Button)({
  backgroundColor: "#ffffff ", // color de fondo transparente
  color: "#000000", // color de texto
  borderRadius: " 50px 50px 50px 50px", // borde redondeado// sombra
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
  "&:hover": {
    backgroundColor: "#9c9c9c", // color de fondo al pasar el mouse
    color: "white", // color de texto al pasar el mouse
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
  },
  fontSize: "1.2rem", // tamaño de fuente
  display: "flex",
  alignItems: "center",
  width: "100%",
  textTransform: "none",
});

const MyPhoneIcon = styled(PhoneIcon)({
  marginRight: "0.5rem", // espacio a la derecha del ícono
});
const baseUrl = process.env.REACT_APP_BACKEND_URL;

async function aumentarWeb(data) {
  // console.log("clics web");
  try {
    // obtener las veces que se presiono el boton guardar desde BD
    // console.log(data);
    const clicsWeb = parseInt(data.clics_sitio_web, 10);
    // aumentar el contador en 1 por cada visita realizada a la presentacion
    const nuevoClicsWeb = clicsWeb + 1;
    // almacenar el contador en BD por persistencia
    await axios.post(baseUrl + `usuario/tarjeta/actualizar/${data.id}`, {
      clics_sitio_web: nuevoClicsWeb,
    });
  } catch (error) {
    console.error(error);
  }
}
async function aumentarSocial(data) {
  try {
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };

    // obtener las veces que se presiono el boton guardar desde BD
    const clicSocial = await axios.post(
      baseUrl + `sociales/clic`,
      { id: data },
      { headers }
    );
    // aumentar el contador en 1 por cada visita realizada a la presentacion
    const nuevoClicsCorreo = parseInt(clicSocial.data.data, 10) + 1;
    // almacenar el contador en BD por persistencia
    const respo = await axios.post(
      baseUrl + `sociales/actualizar/label`,
      { id: data, clics_realizados: nuevoClicsCorreo },
      { headers }
    );
  } catch (error) {
    console.error(error);
  }
}
async function aumentarCorreo(data) {
  // console.log("clics correo");
  try {
    // obtener las veces que se presiono el boton guardar desde BD
    const clicsCorreo = parseInt(data.clics_correo, 10);
    // aumentar el contador en 1 por cada visita realizada a la presentacion
    const nuevoClicsCorreo = clicsCorreo + 1;
    // almacenar el contador en BD por persistencia
    await axios.post(baseUrl + `usuario/tarjeta/actualizar/${data.id}`, {
      clics_correo: nuevoClicsCorreo,
    });
  } catch (error) {
    console.error(error);
  }
}
async function aumentarGuardar(data) {
  try {
    // obtener las veces que se presiono el boton guardar desde BD
    const clicsGuardar = parseInt(data.clics_guardar, 10);
    // aumentar el contador en 1 por cada visita realizada a la presentacion
    const nuevoClicsGuardar = clicsGuardar + 1;
    // almacenar el contador en BD por persistencia
    await axios.post(baseUrl + `usuario/tarjeta/actualizar/${data.id}`, {
      clics_guardar: nuevoClicsGuardar,
    });
  } catch (error) {
    console.error(error);
  }
}

export default function Tarjeta({ data }) {
  useEffect(() => {
    // console.log(data);
    setDatos(data);
    setId(data.id);
  }, [data]);
  const font = loadFont(
    "https://fonts.gstatic.com/s/hind/v14/HindMadurai-Regular.ttf"
  );
  const [datos, setDatos] = useState(data);
  const [shareUrl, setShareUrl] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [viewQr, setViewQr] = useState(false);
  const [twitterUrl, setTwitterUrl] = useState("");
  const [facebookUrl, setFacebookUrl] = useState("");
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [tiktokUrl, setTiktokUrl] = useState("");
  const [linkedinUrl, setLinkedinUrl] = useState("");
  const [instagramUrl, setInstagramUrl] = useState("");
  const [urlWhatsapp, setUrlWhatsapp] = useState("");
  const [id, setId] = useState();
  const phoneNumber = "593987441522";
  const defaultMessag =
    "Me gustaría obtener mi tarjeta digital. He conseguido este código de descuento: " +
    id;

  useEffect(() => {
    if (data.sociales_tarjeta) {
      const socialMedia = data.sociales_tarjeta;

      const twitterUrlObject = socialMedia.find(
        (social) => social.text_label === "twitter"
      );
      const twitterUrl = twitterUrlObject ? twitterUrlObject : "";

      const facebookUrlObject = socialMedia.find(
        (social) => social.text_label === "facebook"
      );
      const facebookUrl = facebookUrlObject ? facebookUrlObject : "";

      const youtubeUrlObject = socialMedia.find(
        (social) => social.text_label === "youtube"
      );
      const youtubeUrl = youtubeUrlObject ? youtubeUrlObject : "";

      const tiktokUrlObject = socialMedia.find(
        (social) => social.text_label === "tiktok"
      );
      const tiktokUrl = tiktokUrlObject ? tiktokUrlObject : "";

      const instagramUrlObject = socialMedia.find(
        (social) => social.text_label === "instagram"
      );
      const instagramUrl = instagramUrlObject ? instagramUrlObject : "";

      const linkedinUrlObject = socialMedia.find(
        (social) => social.text_label === "linkedin"
      );
      const linkedinUrl = linkedinUrlObject ? linkedinUrlObject : "";
      setTwitterUrl(twitterUrl);
      setFacebookUrl(facebookUrl);
      setYoutubeUrl(youtubeUrl);
      setTiktokUrl(tiktokUrl);
      setInstagramUrl(instagramUrl);
      setLinkedinUrl(linkedinUrl);
    }
  }, [data]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCopyUrl = () => {
    try {
      navigator.clipboard.writeText(shareUrl); // Copia el enlace al portapapeles
      setAnchorEl(null);
      Swal.fire({
        title: "Enlace copiado al portapapeles",
        icon: "success",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleShare = (platform) => {
    switch (platform) {
      case "WhatsApp":
        const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          shareUrl
        )}`;
        window.open(whatsappUrl, "_blank");
        break;

      case "Facebook":
        const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          shareUrl
        )}`;
        window.open(facebookUrl, "_blank");
        break;

      case "Telegram":
        const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(
          shareUrl
        )}`;
        window.open(telegramUrl, "_blank");
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    const tarjetaId = localStorage.getItem("tarjetaID");
    const shareUrl1 = `https://onlytap.proatek.com/OnlyTap/Presentacion/${tarjetaId}`; // URL que deseas compartir
    setShareUrl(shareUrl1);
  }, []);

  const handleSaveContact = () => {
    // console.log(datos);
    aumentarGuardar(datos);
    const vCardData = [
      "BEGIN:VCARD",
      "VERSION:3.0",
      `N:${datos.nombre};;;;`,
      `ORG:${datos.profesion}`,
      `TITLE:${datos.profesion}`,
      `TEL;TYPE=work,voice;VALUE=uri:${datos.telefono}`,
      `EMAIL:${datos.correo}`,
      `URL:${datos.sitio_web}`,
      `ADR;TYPE=work;LABEL="${datos.direccion}":;;${datos.direccion}`,
      `NOTE:Contacto agregado mediante OnlyTap`,

      "END:VCARD",
    ].join("\n");

    const blob = new Blob([vCardData], { type: "text/vcard;charset=utf-8" });
    saveAs(blob, `${datos.nombre}"OnlyTap".vcf`);
  };

  const handleClick = (numeroTelefono) => {
    window.location.href = `tel:${numeroTelefono}`;
  };

  const handleQr = () => {
    setViewQr(!viewQr);
  };

  const defaultMessage =
    "¡Hola! Obtuve este contacto al escanear tu tarjeta Onlytap...";

  const openWhatsApp = (telefono) => {
    const url = `https://api.whatsapp.com/send?phone=${telefono}&text=${encodeURIComponent(
      defaultMessage
    )}`;
    window.open(url, "_blank");
  };

  const openTelegram = (telefono) => {
    const url = `https://t.me/${telefono}?text=${encodeURIComponent(
      defaultMessage
    )}`;
    window.open(url, "_blank");
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const openWhatsAp = () => {
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      defaultMessag
    )}`;
    window.open(url, "_blank");
    if (datos) {
      console.log(datos);
      const clicReferidos = parseInt(datos.clic_referidos, 10);
      const nuevoClicReferidos = clicReferidos + 1;
      axios.post(baseUrl + `usuario/tarjeta/actualizar/${id}`, {
        clic_referidos: nuevoClicReferidos,
      });
    }
  };

  return (
    <Card
      sx={{
        maxWidth: isSmallScreen ? "100%" : "400px",
        minWidth: isSmallScreen ? "100%" : "400px",
        boxShadow: "0px 6px 18px rgba(0, 0, 0, 0.3)",
        borderRadius: "50px",
        height: isSmallScreen ? "100%" : "auto",
        width: isSmallScreen ? "100%" : "auto",
      }}
    >
      <CardActionArea style={{ backgroundColor: "#000000" }}>
        {datos.img_portada ? (
          <div
            style={{
              position: "relative",
            }}
          >
            <CardMedia
              component="img"
              height="200"
              image={datos.img_portada}
              alt="Imagen de perfil"
            />
            <img
              src={logoMarcaAgua}
              alt="Marca de agua"
              style={{
                position: "absolute",
                bottom: "10px",
                right: "10px",
                width: "25%", // Ajusta el tamaño de la marca de agua según tus necesidades
                height: "10%", // Ajusta el tamaño de la marca de agua según tus necesidades
                opacity: "0.7", // Ajusta la opacidad de la marca de agua según tus necesidades
              }}
            />
          </div>
        ) : (
          <div
            style={{
              position: "relative",
            }}
          >
            <CardMedia
              component="img"
              height="200"
              image="https://us.123rf.com/450wm/redicka/redicka1410/redicka141000095/32216620-sin-fisuras-patr%C3%B3n-geom%C3%A9trico-negro-para-su-dise%C3%B1o-de-portada-dise%C3%B1o-de-libros-fondo-del-sitio.jpg"
              alt="Imagen de perfil"
            />
            <img
              src={logoMarcaAgua}
              alt="Marca de agua"
              style={{
                position: "absolute",
                bottom: "10px",
                right: "10px",
                width: "25%", // Ajusta el tamaño de la marca de agua según tus necesidades
                height: "10%", // Ajusta el tamaño de la marca de agua según tus necesidades
                opacity: "0.7", // Ajusta la opacidad de la marca de agua según tus necesidades
              }}
            />
          </div>
        )}

        <CardContent
          style={{
            backgroundColor: "#ffffff",
            padding: "0px",
            borderRadius: "0px 30px 0px 0px",
            minHeight: "490px",
          }}
        >
          <div
            className="author"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "6px",
            }}
          >
            {datos && datos.img_perfil && datos.img_perfil ? (
              <Avatar
                src={datos.img_perfil}
                className="avatar border-gray"
                alt="Imagen de perfil"
                sx={{ border: "5px solid #ffffff" }}
              />
            ) : (
              <Avatar
                src="https://w7.pngwing.com/pngs/52/368/png-transparent-user-profile-computer-icons-avatar-avatar-heroes-monochrome-desktop-wallpaper.png"
                className="avatar border-gray"
                alt="Imagen de perfil"
              />
            )}
          </div>
          <CardContent
            style={{
              backgroundColor: "#eeeee",
              padding: "0px 10px 0px 10px",
            }}
          >
            {/* Nombre y profesión */}
            <Box display="flex" flexDirection="column" alignItems="center">
              {datos.nombre && (
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  style={{
                    margin: "0px 0px 0px 0px",
                    fontFamily: font,
                    fontWeight: "bold",
                  }}
                >
                  {datos.nombre}
                </Typography>
              )}
              {datos && datos.profesion && datos.profesion && (
                <Typography
                  gutterBottom
                  variant="h7"
                  component="div"
                  style={{ fontFamily: font }}
                >
                  {datos.profesion}
                </Typography>
              )}
            </Box>
            {/* Guardar contacto */}
            <ListItem
              style={{
                padding: "0px 0px 0px 0px",
              }}
            >
              <CardContent
                style={{
                  backgroundColor: "#ffffff",
                  borderRadius: "10px",
                  padding: "0px 10px",
                  width: "100%",
                }}
              >
                {/*  Boton guardar contacto */}
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  style={{
                    padding: "0px 0px 8px 0px",
                  }}
                >
                  <MyButton
                    onClick={handleSaveContact}
                    style={{ fontSize: "14px" }}
                  >
                    <Box display="flex" alignItems="center">
                      <span>
                        <b style={{ padding: "0 20px" }}>Guardar Contacto</b>
                      </span>
                    </Box>
                  </MyButton>
                </Box>
              </CardContent>
            </ListItem>
            {/* Botones de acción Qr y compartir */}
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <div style={{ marginRight: "10px" }}>
                <MyButton
                  onClick={handleQr}
                  style={{
                    fontSize: "14px",
                    borderRadius: "50px",
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <span>
                      <b>QR</b>
                    </span>
                  </Box>
                </MyButton>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <MyButton
                  onClick={handleMenuOpen}
                  style={{
                    fontSize: "14px",
                    borderRadius: "50px",
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <span>
                      <b>Compartir</b>
                    </span>
                  </Box>
                </MyButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleCopyUrl}>
                    <ListItemIcon>
                      <ContentCopyIcon />
                    </ListItemIcon>
                    Copiar enlace
                  </MenuItem>
                  <MenuItem onClick={() => handleShare("WhatsApp")}>
                    <ListItemIcon>
                      <WhatsAppIcon />
                    </ListItemIcon>
                    WhatsApp
                  </MenuItem>
                  <MenuItem onClick={() => handleShare("Facebook")}>
                    <ListItemIcon>
                      <FacebookIcon />
                    </ListItemIcon>
                    Facebook
                  </MenuItem>
                  <MenuItem onClick={() => handleShare("Telegram")}>
                    <ListItemIcon>
                      <TelegramIcon />
                    </ListItemIcon>
                    Telegram
                  </MenuItem>
                </Menu>
              </div>
            </Box>
          </CardContent>
          {viewQr ? (
            <ExampleComponent />
          ) : (
            <div style={{ maxHeight: "510px" }}>
              {" "}
              {/* // Botones de acción redes sociales */}
              <ListItem
                style={{
                  padding: "10px 10px 10px 10px",
                  display: "flex", // Establece el contenedor como un flexbox
                  justifyContent: "space-between",
                }}
              >
                <CardContent
                  style={{
                    backgroundColor: "#ffffff",
                    borderRadius: "10px",
                    padding: "0px 10px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  {facebookUrl && (
                    <div className="social-icon-container-1">
                      <a
                        href={facebookUrl.tipo_social}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => aumentarSocial(facebookUrl.id)}
                      >
                        <img
                          className={`social-icon`}
                          src={facebookIcon}
                          alt="Icono de Facebook"
                        />
                      </a>
                    </div>
                  )}
                  {twitterUrl && (
                    <div className="social-icon-container-1">
                      <a
                        href={twitterUrl.tipo_social}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => aumentarSocial(twitterUrl.id)}
                      >
                        <img
                          className={`social-icon`}
                          src={x}
                          alt="Icono de Facebook"
                        />
                      </a>
                    </div>
                  )}
                  {instagramUrl && (
                    <div className="social-icon-container-1">
                      <a
                        href={instagramUrl.tipo_social}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => aumentarSocial(instagramUrl.id)}
                      >
                        <img
                          className={`social-icon`}
                          src={instagramIcon}
                          alt="Icono de Facebook"
                        />
                      </a>
                    </div>
                  )}
                  {linkedinUrl && (
                    <div className="social-icon-container-1">
                      <a
                        href={linkedinUrl.tipo_social}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => aumentarSocial(linkedinUrl.id)}
                      >
                        <img
                          className={`social-icon`}
                          src={linkedinIcon}
                          alt="Icono de Facebook"
                        />
                      </a>
                    </div>
                  )}
                  {youtubeUrl && (
                    <div className="social-icon-container-1">
                      <a
                        href={youtubeUrl.tipo_social}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => aumentarSocial(youtubeUrl.id)}
                      >
                        <img
                          className={`social-icon`}
                          src={youtubeIcon}
                          alt="Icono de Facebook"
                        />
                      </a>
                    </div>
                  )}
                  {tiktokUrl && (
                    <div className="social-icon-container-1">
                      <a
                        href={tiktokUrl.tipo_social}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => aumentarSocial(tiktokUrl.id)}
                      >
                        <img
                          className={`social-icon`}
                          src={tiktokIcon}
                          alt="Icono de Facebook"
                        />
                      </a>
                    </div>
                  )}
                  {datos && datos.whatsapp && datos.whatsapp && (
                    <div className="social-icon-container-1">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => openWhatsApp(datos.whatsapp)}
                      >
                        <img
                          className={`social-icon`}
                          src={whatsapp}
                          alt="Icono de Facebook"
                        />
                      </a>
                    </div>
                  )}
                  {datos && datos.telegram && datos.telegram && (
                    <div className="social-icon-container-1">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => openTelegram(datos.telegram)}
                      >
                        <img
                          className={`social-icon`}
                          src={telegramIcon}
                          alt="Icono de Facebook"
                        />
                      </a>
                    </div>
                  )}
                </CardContent>
              </ListItem>
              {/* // Informacion de contacto */}
              <ListItem
                style={{
                  padding: "10px",
                }}
              >
                <CardContent
                  style={{
                    backgroundColor: "#ffffff",
                    borderRadius: "10px",
                    padding: "0px 10px",
                    width: "100%",
                  }}
                >
                  {/* // Boton de acción whatsapp */}
                  {datos && datos.sitio_web && datos.sitio_web && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      style={{
                        padding: "0px 0px 8px 0px",
                      }}
                    >
                      <MyButton
                        style={{
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        <Link
                          href={datos.sitio_web}
                          underline="none"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => aumentarWeb(datos)}
                          style={{
                            color: "#000000",
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            textDecoration: "none",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <div
                              className="social-icon-container"
                              style={{ marginRight: "5px", marginLeft: "5px" }}
                            >
                              <LanguageIcon />
                            </div>
                            <div style={{ flex: 1 }}>
                              <Typography
                                gutterBottom
                                variant="h7"
                                component="div"
                                style={{
                                  margin: "0px",
                                  fontFamily: font,
                                  textAlign: "center",
                                }}
                              >
                                {datos.titulo ?? datos.sitio_web}
                              </Typography>
                            </div>
                            <div
                              className="social-icon-container"
                              style={{ margin: "0px", color: "transparent" }}
                            >
                              <LanguageIcon />
                            </div>
                          </div>
                        </Link>
                      </MyButton>
                    </Box>
                  )}
                  {datos && datos.direccion && datos.direccion && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      style={{
                        padding: "0px 0px 8px 0px",
                      }}
                    >
                      <MyButton
                        style={{
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        <Link
                          href={`${datos.direccion}`}
                          underline="none"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => aumentarWeb(datos)}
                          style={{
                            color: "#000000",
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            textDecoration: "none",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <div
                              className="social-icon-container"
                              style={{ marginRight: "5px", marginLeft: "5px" }}
                            >
                              <AddLocationAltSharpIcon />
                            </div>
                            <div style={{ flex: 1 }}>
                              <Typography
                                gutterBottom
                                variant="h7"
                                component="div"
                                style={{
                                  fontFamily: font,
                                  margin: "0px 0px 0px 0px",
                                }}
                              >
                                Ubicación
                              </Typography>
                            </div>
                            <div
                              className="social-icon-container"
                              style={{ margin: "0px", color: "transparent" }}
                            >
                              <AddLocationAltSharpIcon />
                            </div>
                          </div>
                        </Link>
                      </MyButton>
                    </Box>
                  )}
                  {datos && datos.correo && datos.correo && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      style={{
                        padding: "0px 0px 8px 0px",
                      }}
                    >
                      <MyButton
                        style={{
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        <Link
                          href={`mailto:${datos.correo}`}
                          underline="none"
                          onClick={() => aumentarCorreo(datos)}
                          style={{
                            color: "#000000",
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            textDecoration: "none",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <div
                              className="social-icon-container"
                              style={{ marginRight: "5px", marginLeft: "5px" }}
                            >
                              <EmailIcon />
                            </div>
                            <div style={{ flex: 1 }}>
                              <Typography
                                gutterBottom
                                variant="h7"
                                component="div"
                                style={{
                                  fontFamily: font,
                                  margin: "0px 0px 0px 0px",
                                }}
                              >
                                {datos.correo}
                              </Typography>
                              <ListItemText primary=""></ListItemText>
                            </div>
                            <div
                              className="social-icon-container"
                              style={{ margin: "0px", color: "transparent" }}
                            >
                              <EmailIcon />
                            </div>
                          </div>
                        </Link>
                      </MyButton>
                    </Box>
                  )}
                  {datos && datos.telefono && datos.telefono && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      style={{
                        padding: "0px 0px 8px 0px",
                      }}
                    >
                      <MyButton
                        style={{
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        <Link
                          href=""
                          target="_blank"
                          rel="noopener noreferrer"
                          underline="none"
                          onClick={() => handleClick(datos.telefono)}
                          style={{
                            color: "#000000",
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            textDecoration: "none",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <div
                              className="social-icon-container"
                              style={{ marginRight: "5px", marginLeft: "5px" }}
                            >
                              <MyPhoneIcon />
                            </div>
                            <div style={{ flex: 1 }}>
                              <Typography
                                gutterBottom
                                variant="h7"
                                component="div"
                                style={{
                                  fontFamily: font,
                                  margin: "0px 0px 0px 0px",
                                }}
                              >
                                {datos.telefono}
                              </Typography>
                              <ListItemText primary=""></ListItemText>
                            </div>
                            <div
                              className="social-icon-container"
                              style={{ margin: "0px", color: "transparent" }}
                            >
                              <MyPhoneIcon />
                            </div>
                          </div>
                        </Link>
                      </MyButton>
                    </Box>
                  )}
                </CardContent>
              </ListItem>
            </div>
          )}
        </CardContent>
        <footer
          style={{
            position: "sticky",
            bottom: "0", // Fija el footer en la parte inferior del contenedor
            // width: "100%",
            textAlign: "center",
            zIndex: 1,
            backgroundColor: "#ffffff", // Color de fondo opcional
            padding: "6px 60px", // Espaciado opcional
          }}
        >
          <MyButton
            onClick={openWhatsAp}
            sx={{
              margin: "0px",
              padding: "0px",
              backgroundColor: "#000000",
              color: "#ffffff",
              fontSize: "14px",
            }}
          >
            <Box display="flex" alignItems="center">
              <span>
                <b>Solicitar una tarjeta</b>
              </span>
            </Box>
          </MyButton>
          <p
            style={{
              margin: "0px",
              padding: "0px",
              fontSize: "12px",
              fontFamily: font,
            }}
          >
            ¡Obtén $5 de descuento!
          </p>
        </footer>
      </CardActionArea>
    </Card>
  );
}
