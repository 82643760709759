import React, { useState, useEffect } from "react";
import { Button, CircularProgress, Backdrop } from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";

const baseUrl = process.env.REACT_APP_BACKEND_URL;

function UserCreationForm({ negocios, role }) {
  console.log(negocios);
  const { authorisation } = JSON.parse(localStorage.getItem("user"));
  const usuario = authorisation.data.id;
  const rol = role;
  const [formValues, setFormValues] = useState({
    nombres: "",
    apellidos: "",
    correo: "",
    password: "Onlytap2024",
    rol: "",
    identificacion: "",
    registrado_por: usuario,
    negocio: "", // Nuevo campo para el negocio
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [rolesPermitidos, setRolesPermitidos] = useState([]);
  const [entidades, setEntidades] = useState([]);
  const [user, setUser] = useState("");

  useEffect(() => {
    const entidadesLocalStorage = JSON.parse(
      localStorage.getItem("entidadesComerciales")
    );
    if (entidadesLocalStorage) {
      setEntidades(entidadesLocalStorage);
    }
    const { authorisation } = JSON.parse(localStorage.getItem("user"));
    setUser(authorisation.data.id);
  }, []);

  useEffect(() => {
    console.log(rol);
    const rolesPermitidos = {
      ADMIN: [
        "PROPIETARIO",
        "GERENTE",
        "CAJERO",
        "MESERO",
        "COCINERO",
        "USUARIO",
      ],
      PROPIETARIO: ["GERENTE", "CAJERO", "MESERO", "COCINERO"],
      GERENTE: ["CAJERO", "MESERO", "COCINERO"],
      CAJERO: ["MESERO", "COCINERO"],
    };

    setRolesPermitidos(rolesPermitidos[rol]);
  }, [rol]);

  useEffect(() => {
    if (!negocios.length) {
      setFormValues({ ...formValues, negocio: negocios.id });
    }
  }, [negocios]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    if (errorMessage) setErrorMessage(""); // Limpiar el mensaje de error al modificar cualquier campo
  };

  const handleCancel = () => {
    setFormValues({
      nombres: "",
      apellidos: "",
      correo: "",
      password: "",
      rol: "",
      identificacion: "",
      registrado_por: "",
      negocio: "", // Limpiar también el campo del negocio
    });
    setLoading(false);
    setErrorMessage("");
  };

  const validateForm = () => {
    // Aquí puedes ajustar la validación según sea necesario, por ejemplo:
    const requiredFields = [
      "nombres",
      "apellidos",
      "correo",
      "password",
      "rol",
      "identificacion",
      "negocio", // Agrega el campo negocio a los campos requeridos
    ];
    for (let field of requiredFields) {
      if (!formValues[field]) {
        setErrorMessage(`El campo ${field} es obligatorio.`);
        return false;
      }
    }
    // Agrega aquí otras validaciones específicas que necesites.
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      // Si la validación falla, detiene la ejecución.
      return;
    }

    // Validar el rol y permitir o denegar la creación del usuario
    if (!rolesPermitidos.includes(formValues.rol)) {
      setErrorMessage("No tienes permiso para crear usuarios con este rol.");
      return;
    }

    try {
      // Aquí debes agregar la lógica para enviar los datos a tu backend
      // Por ahora, simularemos una petición exitosa después de un breve retraso
      console.log(formValues);
      setLoading(true);
      const response = await axios.post(baseUrl + "usuario/crear", formValues);
      const { data } = response;
      console.log(response);
      if (response.status === 201) {
        const objetoJSON = {
          usuario_id: data.data.id,
          comercio_id: formValues.negocio,
          rol: data.data.rol,
          estado: 1,
          registrado_por: user,
        };

        console.log(objetoJSON);

        const token = localStorage.getItem("token");
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        };

        const usuarioComercio = await axios.post(
          baseUrl + `usuario/entidad/crear`,
          JSON.stringify(objetoJSON),
          { headers }
        );
        if (usuarioComercio.status === 201) {
          console.log("Usuario Asignado con éxito", usuarioComercio);
          Swal.fire({
            icon: "success",
            title: "Usuario Creado y Asignado con éxito",
            text: "",
            confirmButtonText: "Aceptar",
          });
          // onClose();
        }
        setLoading(false);
        handleCancel();
      }

      // setTimeout(async () => {
      //   setLoading(false);
      //   Swal.fire({
      //     icon: "success",
      //     title: "Usuario creado",
      //     text: "El usuario ha sido creado con éxito",
      //     confirmButtonText: "Aceptar",
      //   });
      //   handleCancel();
      // }, 2000);
    } catch (error) {
      setLoading(false);
      // Revisamos si el error proviene de una respuesta del servidor y tiene un estado 422
      if (error.response && error.response.status === 422) {
        const errores = error.response.data; // Obtenemos los detalles del error del servidor

        // Convertimos los mensajes de error en un array de strings
        const mensajesDeError = Object.values(errores.data).flat(); // Usamos .flat() para aplanar en caso de que haya arrays anidados

        // Unimos todos los mensajes de error en un solo string, separados por un salto de línea
        const textoDeErrores = mensajesDeError.join("\n");

        // Mostramos el Swal con los errores específicos
        Swal.fire({
          icon: "error",
          title: "Ha ocurrido un error al crear el usuario",
          text: "\n" + textoDeErrores,
          confirmButtonText: "Aceptar",
          heightAuto: false, // Asegura que el modal se muestre correctamente si hay muchos errores
        });
      } else {
        // Manejo de otros tipos de errores no relacionados con la respuesta del servidor
        Swal.fire({
          icon: "error",
          title: "Error al crear el usuario",
          text: "Ha ocurrido un error desconocido al crear el usuario",
          confirmButtonText: "Aceptar",
        });
      }
      console.error("Error al crear el usuario:", error);
    }
  };

  return (
    <div
      style={{
        borderRadius: "10px",
        border: "1px solid #E0E0E0",
        padding: "20px",
        backgroundColor: "white",
      }}
    >
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col">
            <h5>Nombres *</h5>
            <input
              type="text"
              name="nombres"
              value={formValues.nombres}
              onChange={handleChange}
              className="input-register"
              style={{
                width: "100%",
                borderRadius: "10px",
                backgroundColor: "#F5F5F5",
                padding: "10px",
              }}
              required
            />
          </div>
          <div className="col">
            <h5>Apellidos *</h5>
            <input
              type="text"
              name="apellidos"
              value={formValues.apellidos}
              onChange={handleChange}
              className="input-register"
              style={{
                width: "100%",
                borderRadius: "10px",
                backgroundColor: "#F5F5F5",
                padding: "10px",
              }}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <br />
            <h5>Correo *</h5>
            <input
              type="email"
              name="correo"
              value={formValues.correo}
              onChange={handleChange}
              className="input-register"
              style={{
                width: "100%",
                borderRadius: "10px",
                backgroundColor: "#F5F5F5",
                padding: "10px",
              }}
              required
            />
          </div>
          <div className="col">
            <br />
            <h5>Identificación *</h5>
            <input
              type="number"
              name="identificacion"
              value={formValues.identificacion}
              onChange={handleChange}
              className="input-register"
              style={{
                width: "100%",
                borderRadius: "10px",
                backgroundColor: "#F5F5F5",
                padding: "10px",
              }}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <br />
            <h5>Rol *</h5>
            <select
              name="rol"
              value={formValues.rol}
              onChange={handleChange}
              className="input-register"
              style={{
                width: "100%",
                borderRadius: "10px",
                backgroundColor: "#F5F5F5",
                padding: "10px",
              }}
              required
            >
              <option value="">Seleccione un rol</option>
              {rolesPermitidos.map((rolPermitido) => (
                <option key={rolPermitido} value={rolPermitido}>
                  {rolPermitido}
                </option>
              ))}
            </select>
          </div>
          {rol === "ADMIN" && (
            <div className="col">
              <br />
              <h5>Negocio *</h5>
              <select
                name="negocio"
                value={negocios.length ? formValues.negocio : negocios.id}
                onChange={handleChange}
                className="input-register"
                style={{
                  width: "100%",
                  borderRadius: "10px",
                  backgroundColor: "#F5F5F5",
                  padding: "10px",
                }}
                required
                disabled={!negocios.length}
              >
                <option value="">Seleccione un negocio</option>
                {negocios.length ? (
                  negocios.map((negocio) => (
                    <option key={negocio.id} value={negocio.id}>
                      {negocio.nombre_comercial}
                    </option>
                  ))
                ) : (
                  <option key={negocios.id} value={negocios.id}>
                    {negocios.nombre_comercial}
                  </option>
                )}
              </select>
            </div>
          )}
        </div>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <div className="linea-botones">
          <button
            type="button"
            onClick={handleCancel}
            className="cancel-button"
          >
            Vaciar
          </button>
          <button
            type="button"
            onClick={handleSubmit}
            className="submit-button"
          >
            Guardar
          </button>
        </div>
      </form>
    </div>
  );
}

export default UserCreationForm;
