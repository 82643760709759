import {
  LinearProgress,
  Box,
  Button,
  Grid,
  styled,
  Badge,
  Avatar,
  Icon,
  Menu,
  MenuItem,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/system";
import React from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MuiDialogActions from "@mui/material/DialogActions";
import { useState, useEffect } from "react";
import ModalAccesos from "./ModalAccesos";
import { useNavigate, useParams } from "react-router-dom";
import ModalDesactivar from "./ModalDesactivar";
import ModalRecuperarContraseña from "./ModalRecuperarContraseña";

const UserInfo = styled("div")(({ theme }) => ({
  paddingRight: "33px",
  paddingLeft: "33px",
  paddingTop: "0",
  paddingBottom: "0",
  textAlign: "center",
}));

const H5 = styled("h5")(({ theme }) => ({
  padding: "0px",
  color: "#053D6B",
  fontSize: "16px",
  fontWeight: "bold",
  marginTop: "10px",
  marginBottom: "10px",
}));

const Parrafo = styled("h5")(({ theme }) => ({
  padding: "0px",
  color: "#242532",
  fontSize: "16px",
  fontWeight: "normal",
  marginTop: "10px",
  marginBottom: "10px",
}));

const Titulo = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#053D6B",
}));

const GridContainer = styled(Grid)(() => ({
  display: "flex",
  alignContent: "start",
  alignItems: "center",
  borderBottom: "2px solid #DCDCDC",
}));

const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  "&.root": { margin: 0, padding: theme.spacing(1) },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));

export default function ModalDetalles(usuario) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleCloseMenu() {
    setAnchorEl(null);
  }

  const [estilo, setEstilo] = useState(null);
  const { user } = usuario;
  const { user_tarjeta } = user;
  const [cargando, setCargando] = useState(false);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  console.log(user, user_tarjeta, "user, user_tarjeta");
  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  const handleEdit = () => {};

  useEffect(() => {
    // console.log(interfaz)
    if (localStorage.getItem("interfaz")) {
      // console.log(JSON.parse(localStorage.getItem("interfaz")))
      const slug = JSON.parse(localStorage.getItem("interfaz"));
      setEstilo(slug);
    }
  }, []);

  return (
    <Box>
      <StyledButton
        variant="outlined"
        type="submit"
        color="primary"
        onClick={handleClickOpen}
        sx={{
          width: "max-content",
          fontWeight: "bold",
          border: "2px solid",
          color: !estilo ? "#437294" : estilo.color_principal,
          borderRadius: "10px",
        }}
      >
        Ver Detalles
      </StyledButton>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Titulo
          id="responsive-dialog-title"
          sx={{ background: !estilo ? "#053D6B" : estilo.color_gradient }}
        >
          <IconButton
            aria-label="Close"
            className="closeButton"
            onClick={handleClose}
            sx={{
              color: theme.palette.grey[500],
              position: "absolute",
              right: theme.spacing(1),
              top: theme.spacing(1),
            }}
          >
            <CloseIcon />
          </IconButton>
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
            <UserInfo>
              <Box display="flex" alignItems="center">
                <Box
                  alignItems="center"
                  sx={{
                    display: "inline-block",
                    height: "150px",
                    marginBottom: "16px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    position: "relative",
                    textAlign: "center",
                    width: "150px",
                  }}
                >
                  <Badge
                    overlap="circular"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                  >
                    <Avatar
                      src={user_tarjeta[0]?.img_perfil}
                      sx={{
                        cursor: "pointer",
                        width: 150,
                        height: 150,
                      }}
                    />
                  </Badge>
                </Box>
              </Box>
            </UserInfo>
          </Grid>
        </Titulo>

        <Grid container sx={{ paddingRight: "30px", paddingLeft: "30px" }}>
          <GridContainer container spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <H5>Nombre</H5>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Parrafo>{user.nombres}</Parrafo>
            </Grid>
          </GridContainer>

          <GridContainer container spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <H5>Apellido</H5>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Parrafo>{user.apellidos}</Parrafo>
            </Grid>
          </GridContainer>

          <GridContainer container spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <H5>Identificacion</H5>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Parrafo>{user.identificacion}</Parrafo>
            </Grid>
          </GridContainer>

          <GridContainer container spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <H5>Correo</H5>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Parrafo>{user.correo}</Parrafo>
            </Grid>
          </GridContainer>
          <GridContainer container spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <H5>Rol</H5>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Parrafo>{user.rol}</Parrafo>
            </Grid>
          </GridContainer>

          {user_tarjeta.length !== 0 && (
            <>
              <GridContainer container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <H5>Celular</H5>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Parrafo>{user_tarjeta[0]?.telefono}</Parrafo>
                </Grid>
              </GridContainer>
            </>
          )}
        </Grid>
        <LinearProgress value={0} variant="determinate" className="progress" />

        <DialogActions sx={{ alignSelf: "start" }}>
          <Box>
            <StyledButton
              variant="contained"
              color="primary"
              type="submit"
              align="left"
              disabled={cargando}
              onClick={handleClick}
              sx={{
                color: "#ffffff",
                width: "max-content",
                background: !estilo ? "#053D6B" : estilo.color_principal,
                borderRadius: "10px",
                fontWeight: "bold",
              }}
            >
              <Icon sx={{ mr: "10px" }}>settings</Icon>
              Opciones
            </StyledButton>

            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              <StyledButton
                variant="contained"
                color="primary"
                type="submit"
                disabled={cargando}
                onClick={handleEdit}
                sx={{
                  color: "#ffffff",
                  width: "max-content",
                  background: !estilo ? "#053D6B" : estilo.color_principal,
                  borderRadius: "10px",
                  fontWeight: "bold",
                }}
              >
                <Icon sx={{ mr: "10px" }}>create</Icon>
                Editar
              </StyledButton>

              <ModalAccesos user={user} />

              <ModalDesactivar id={user.id} />

              <ModalRecuperarContraseña user={user} />
            </Menu>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
