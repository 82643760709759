import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./styles.css";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";

const CloseButton = styled(Button)({
  position: "absolute",
  top: "0px",
  right: "0px",
  background: "none",
  border: "none",
  cursor: "pointer",
  fontSize: "1rem",
  padding: "0px",
  borderRadius: "25px", // Hacer que el borde sea completamente redondo
});

function ModelPhone({ isVisible, onClose }) {
  const storedPhone = localStorage.getItem("Phone");
  const [phone, setPhone] = useState(storedPhone || "");
  const [isPhoneValid, setIsPhoneValid] = useState(phone);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setIsPhoneValid(!!phone.trim());
  }, [phone]);

  if (!isVisible) return null;

  const handlePhoneChange = (event) => {
    const newPhone = event;
    if (newPhone !== undefined) {
      localStorage.setItem("Phone", newPhone);
      window.dispatchEvent(new Event("storage"));
      setPhone(newPhone);
    }
  };

  const isValidPhoneNumber = (phoneNumber) => {
    // Expresión regular para verificar si la cadena es un número de teléfono de 10 dígitos
    const phoneRegex = /^\+?\d{10,13}$/;

    return phoneRegex.test(phoneNumber.trim());
  };

  const handleSaveClick = () => {
    if (isPhoneValid) {
      if (isValidPhoneNumber(phone)) {
        setErrorMessage("");
        onClose();
      } else {
        setErrorMessage("Número incorrecto");
      }
    } else {
      setErrorMessage("Ingrese un número para continuar.");
    }
  };

  const handleCancel = () => {
    localStorage.removeItem("Phone"); // Remove the "Phone" key from local storage
    window.dispatchEvent(new Event("storage"));
    setPhone("");
    setErrorMessage("");
    onClose();
  };

  return (
    <Backdrop
      sx={{
        color: "#000",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={isVisible}
    >
      <div>
        <div className="modal-container">
          <div className="modal-body">
            <CloseButton variant="outlined" onClick={onClose}>
              x
            </CloseButton>
            <div className="input-container">
              <div className="modal-header">
                <h5>Celular</h5>
              </div>
              <PhoneInput
                placeholder="Ingresa tu numero"
                name="phone"
                value={phone}
                onChange={handlePhoneChange}
                defaultCountry="EC"
                className="input-register my-3"
              />
              <div className="example" style={{ textAlign: "right" }}>
                Ejemplo: <span style={{ color: "blue" }}>0987654321</span>
              </div>
              {errorMessage && (
                <div className="error-message">{errorMessage}</div>
              )}
              <div className="linea-botones">
                <button onClick={handleCancel} className="cancel-button">
                  Vaciar
                </button>
                <button
                  type="button"
                  onClick={handleSaveClick}
                  // disabled={!isPhoneValid}
                  className="submit-button"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
}

export default ModelPhone;
