import  {useState, useEffect, useRef} from 'react'

export const usePagination = ( data=[], registrosLength=0, getPage=()=>{},  ) => {

  const [registros, setRegistros] = useState([]);
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(true);
  const pagesRef= useRef([page]);

  
  useEffect(() => { //en el hook
    setLoading(true);
    const aConcatenar = [...data, ...registros];

    let noReps = [];
    let lookUpObject = {};

    for(let i in aConcatenar){
      lookUpObject[aConcatenar[i]['id']]=aConcatenar[i];
    }

    for (let i in lookUpObject) {
       noReps.push(lookUpObject[i])
    }

    setRegistros([...noReps]);    
    setLoading(false);
  }, [data])

  useEffect(() => {
      if(!pagesRef.current.includes(page)){
          pagesRef.current.push(page)
      }
  }, [registrosLength, page])
  
  const lastPage = new Set([0])

  useEffect(() => {
      //console.log('Page del hijo: ', page);
      getPage(page)
  }, [page, getPage])


  
  const handleChangePage = (event, newPage) => { 
    if(!pagesRef.current.some(p=>p===newPage)){
        setLoading(true)
    }
    
    lastPage.add(newPage)
    setPage(newPage)
    getPage(newPage)
    // setLoading(false)
  }


  return {
    page,
    handleChangePage,
    loading,
    registros
  }
}
