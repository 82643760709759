import React, { useState, useEffect } from "react";

const Listado = () => {
  const [allCards, setAllCards] = useState([]);
  const [filtroProfesion, setFiltroProfesion] = useState(""); // Nuevo estado para el filtro

  useEffect(() => {
    const allCards = JSON.parse(localStorage.getItem("allCards"));
    setAllCards(allCards);
  }, []);

  // Función para manejar cambios en el input de filtro
  const handleFiltroProfesionChange = (event) => {
    setFiltroProfesion(event.target.value);
  };

  // Función para filtrar las tarjetas por profesión
  const filteredCards = allCards.filter(
    (card) =>
      card.profesion &&
      card.profesion.toLowerCase().includes(filtroProfesion.toLowerCase())
  );

  return (
    <div className="listado">
      <input
        type="text"
        placeholder="Buscar por profesión"
        value={filtroProfesion}
        onChange={handleFiltroProfesionChange}
      />
      <table>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Profesion</th>
            <th>Telefono</th>
            <th>Tarjeta</th>
          </tr>
        </thead>
        <tbody>
          {filteredCards.map((card) => (
            <tr key={card.id}>
              <td>{card.nombre}</td>
              <td>{card.profesion}</td>
              <td>{card.telefono}</td>
              <td>
                <button
                  onClick={() => {
                    window.location.href = `/OnlyTap/Presentacion/${card.id}`;
                  }}
                >
                  Ver
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Listado;
