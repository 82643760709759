import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Table,
  TableBody,
  Box,
  Paper,
  TableContainer,
  Grid,
  Icon,
  Skeleton,
  IconButton,
  styled,
} from "@mui/material";
import { esES } from "@mui/material/locale";
import Button from "@mui/material/Button";
import { EnhancedTableHead } from "../../compontents/EnhancedTableHead";
import { PaginatorServer } from "../../compontents/Paginator";
import { usePagination } from "../../compontents/usePagination";
import {
  getComparator,
  StyledTableCell,
  StyledTableRow,
  Container,
  stableSort,
} from "../../compontents/TableFunctions";
import { useNavigate, Link } from "react-router-dom";
import DetallesEntidadComercial from "./DetallesEntidadComercial";
import axios from "axios";
import Switch from "@mui/material/Switch";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import {
  ModalActivarEfectivo,
  ModaPagarEfectivo,
  ModalActivarPuntos,
  ModalVerPagos,
  ModalVerPuntos,
  ModalCanjearPuntos,
} from "../Dashboard/Components/modals/index";

const SmallEstadoActivo = styled("small")(({ activo }) => ({
  height: 30,
  width: "max-content",
  alignSelf: "center",
  fontSize: 20,
  fontWeight: "bold",
  color: activo ? "#84CDA4" : "#F16360",
  padding: "2px 8px",
  borderRadius: "4px",
  overflow: "hidden",
  background: activo ? "#DAF4E9" : "#FDEDED",
  boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)",
}));

const SmallTipoEntidad = styled("small")(({ tipoEntidad }) => ({
  height: 30,
  width: "max-content",
  alignSelf: "center",
  fontSize: 20,
  fontWeight: "bold",
  color: "white",
  padding: "2px 8px",
  borderRadius: "4px",
  overflow: "hidden",
  background:
    tipoEntidad === "RESTAURANTE"
      ? "#66B9BF"
      : tipoEntidad === "RETAIL"
      ? "#FF5733"
      : "#EEAA7B",
  boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));

const theme = createTheme(esES);

/**
 *
 * @param  listaEntidades los nuevos registros que vienen de 10en 10 de la paginación
 * @param  getPage función para obtener la pagina que se consulta
 * @param  totalRegs el total de registros que trae la consulta a la api
 * @returns  el componente tabla
 */

export const UserTarjetaTable = ({
  listaEntidades = [],
  getPage,
  totalRegs,
  role,
  puntos,
}) => {
  const [estilo, setEstilo] = useState(null);
  const [entidad, setEntidad] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE, setAnchorE] = React.useState(null);
  const baseUrl = process.env.REACT_APP_BACKEND_URL;
  const [pagarTarjeta, setPagarTarjeta] = useState(null);
  const [id, setId] = useState(null);
  const [ShowModalActivar, setShowModalActivar] = useState(false);
  const [ShowModalPagar, setShowModalPagar] = useState(false);
  const [ShowModalVerPuntos, setShowModalVerPuntos] = useState(false);
  const [ShowModalPuntos, setShowModalPuntos] = useState(false);
  const [ShowModalCanjear, setShowModalCanjear] = useState(false);
  const [ShowModalPagos, setShowModalPagos] = useState(false);
  const [listPagos, setListPagos] = useState([]);
  const [listPuntos, setListPuntos] = useState([]);
  const [maximo, setMaximo] = useState(null);
  const [tarjeta, setTarjeta] = useState(null);

  let headCell = [
    {
      id: "Estado",
      numeric: false,
      disablePadding: false,
      label: "",
    },
    {
      id: "Nombre",
      numeric: false,
      disablePadding: false,
      label: "Nombre",
    },
    {
      id: "Profesion",
      numeric: false,
      disablePadding: false,
      label: "Profesion",
    },
    {
      id: "Tarjeta",
      numeric: false,
      disablePadding: false,
      label: "Tarjeta",
    },
    {
      id: "Puntos",
      numeric: false,
      disablePadding: false,
      label: "Puntos",
    },
  ];
  const [headCells, setHeadCells] = useState(headCell);

  /**
   * Cabecera de la tabla
   */

  useEffect(() => {
    if (role === "ADMIN") {
      // Crea una copia de headCells y agrega el nuevo objeto
      const updatedHeadCells = [
        ...headCells,
        {
          id: "Efectivo",
          numeric: false,
          disablePadding: true,
          label: "Efectivo",
        },
      ];

      // Actualiza el estado con la nueva copia de headCells
      setHeadCells(updatedHeadCells);
      console.log(updatedHeadCells);
    }
  }, [role]); // Asegúrate de incluir `role` en la lista de dependencias

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");

  const { page, handleChangePage, loading, registros } = usePagination(
    listaEntidades,
    listaEntidades.length,
    getPage
  );

  const handleRequestSort = (event, property) => {
    // console.log('handleRequestSort called')
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    // setPage(0);
  };

  const skeletonArray = Array(10).fill("");

  const handleEditClick = async (row) => {
    // console.log("row: ", row);
    setEntidad(row);
    setOpen(true);
    // console.log('iD: ', id)
    // await navigate(`/entidad/detalles/${id}`);
  };

  onclose = () => {
    setOpen(false);
  };

  const cambiarEstadoTarjeta = async (idTarjeta, nuevoEstado) => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      };

      const response = await axios.post(
        baseUrl + `usuario/tarjeta/actualizar/${idTarjeta}`,
        { estado: nuevoEstado },
        { headers }
      );
      if (response.status === 200) {
        const nuevasTarjetas = listaEntidades.map((tarjeta) => {
          if (tarjeta.id === idTarjeta) {
            return {
              ...tarjeta,
              estado: nuevoEstado,
            };
          }
          return tarjeta;
        });
        // setTarjetas(nuevasTarjetas);
      }
    } catch (error) {
      console.error("Error al actualizar el estado de la tarjeta:", error);
    }
  };

  const handleMenuClos = () => {
    setAnchorE(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOpe = (event, row) => {
    setTarjeta(row);
    setAnchorE(event.currentTarget);
  };

  const handleMenuOpen = (event, row) => {
    setTarjeta(row);
    setAnchorEl(event.currentTarget);
  };

  const handleAbrir = (max, idList) => {
    setAnchorEl(null);
    let referidos = parseInt(max.clic_referidos);
    let pagados = parseInt(max.clic_pagados);
    let cobrar = parseInt(max.clic_cobrar);

    console.log(referidos - pagados - cobrar, idList);

    setMaximo(referidos - pagados - cobrar);
    setId(idList);
    setShowModalActivar(true);
  };

  const handlePagar = (row) => {
    setAnchorEl(null);
    setPagarTarjeta(row);
    setShowModalPagar(true);
  };

  const handleVer = (pagos, idList) => {
    setAnchorEl(null);
    setListPagos(pagos);
    setShowModalPagos(true);
  };

  const handlePuntos = (tarjeta) => {
    setAnchorE(null);
    setId(tarjeta);
    setShowModalPuntos(true);
  };

  const handleCanjear = (tarjeta) => {
    setAnchorE(null);
    setId(tarjeta);
    setShowModalCanjear(true);
  };

  const handleVerWallet = (pagos) => {
    // console.log(pagos);
    setAnchorE(null);
    setListPuntos(pagos);
    setShowModalVerPuntos(true);
  };

  const activarModal = () => {
    setShowModalCanjear(true);
  };

  return (
    <>
      <Container>
        <Grid container>
          <Grid item xs={12} md={12}>
            <Box with="100%" overflow="auto">
              <ThemeProvider theme={theme}>
                {!open ? (
                  <>
                    <Paper sx={{ with: "100%", overflow: "scroll" }}>
                      <TableContainer sx={{ maxHeight: "100%" }}>
                        <Table
                          sx={{ minWidth: 650 }}
                          size="small"
                          aria-label="a dense table"
                        >
                          <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={registros.length}
                            headCells={headCells}
                          />

                          <TableBody>
                            {!loading &&
                              stableSort(
                                registros,
                                getComparator(order, orderBy)
                              )
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((row, i) => (
                                  <StyledTableRow key={row.id}>
                                    <StyledTableCell align="left">
                                      <Switch
                                        checked={row.estado === "1"}
                                        color="primary"
                                        onChange={(event) =>
                                          cambiarEstadoTarjeta(
                                            row.id,
                                            event.target.checked ? 1 : 0
                                          )
                                        }
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.nombre}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.profesion}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.id}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      <StyledButton
                                        variant="outlined"
                                        type="submit"
                                        color="primary"
                                        onClick={(event) =>
                                          handleMenuOpe(event, row)
                                        }
                                        sx={{
                                          width: "max-content",
                                          fontWeight: "bold",
                                          border: "2px solid",
                                          color: !estilo
                                            ? "#437294"
                                            : estilo.color_principal,
                                          borderRadius: "10px",
                                        }}
                                      >
                                        Detalles
                                      </StyledButton>
                                      {anchorE !== null && (
                                        <Menu
                                          anchorEl={anchorE}
                                          open={Boolean(anchorE)}
                                          onClose={handleMenuClos}
                                        >
                                          <MenuItem>
                                            <StyledButton
                                              variant="outlined"
                                              type="submit"
                                              color="primary"
                                              onClick={() =>
                                                handlePuntos(
                                                  tarjeta,
                                                  tarjeta.id
                                                )
                                              }
                                              sx={{
                                                width: "max-content",
                                                fontWeight: "bold",
                                                border: "2px solid",
                                                color: !estilo
                                                  ? "#437294"
                                                  : estilo.color_principal,
                                                borderRadius: "10px",
                                              }}
                                            >
                                              Activar Puntos
                                            </StyledButton>
                                          </MenuItem>
                                          {puntos && (
                                            <>
                                              <MenuItem>
                                                <StyledButton
                                                  variant="outlined"
                                                  type="submit"
                                                  color="primary"
                                                  onClick={() =>
                                                    handleCanjear(tarjeta)
                                                  }
                                                  disabled={
                                                    !tarjeta.wallet.length
                                                  }
                                                  sx={{
                                                    width: "max-content",
                                                    fontWeight: "bold",
                                                    border: "2px solid",
                                                    color: !estilo
                                                      ? "#437294"
                                                      : estilo.color_principal,
                                                    borderRadius: "10px",
                                                  }}
                                                >
                                                  Canjear puntos{" "}
                                                </StyledButton>
                                              </MenuItem>
                                              <MenuItem>
                                                <StyledButton
                                                  variant="outlined"
                                                  type="submit"
                                                  color="primary"
                                                  onClick={() =>
                                                    handleVerWallet(
                                                      tarjeta.wallet
                                                    )
                                                  }
                                                  disabled={
                                                    !tarjeta.wallet.length
                                                  }
                                                  sx={{
                                                    width: "max-content",
                                                    fontWeight: "bold",
                                                    border: "2px solid",
                                                    color: !estilo
                                                      ? "#437294"
                                                      : estilo.color_principal,
                                                    borderRadius: "10px",
                                                  }}
                                                >
                                                  Ver puntos
                                                </StyledButton>
                                              </MenuItem>
                                            </>
                                          )}
                                        </Menu>
                                      )}
                                    </StyledTableCell>
                                    {role === "ADMIN" && (
                                      <StyledTableCell align="left">
                                        <div style={{ marginLeft: "10px" }}>
                                          <StyledButton
                                            variant="outlined"
                                            type="submit"
                                            color="primary"
                                            onClick={(event) =>
                                              handleMenuOpen(event, row)
                                            }
                                            sx={{
                                              width: "max-content",
                                              fontWeight: "bold",
                                              border: "2px solid",
                                              color: !estilo
                                                ? "#437294"
                                                : estilo.color_principal,
                                              borderRadius: "10px",
                                            }}
                                          >
                                            Detalles
                                          </StyledButton>
                                          {anchorEl !== null && (
                                            <Menu
                                              anchorEl={anchorEl}
                                              open={Boolean(anchorEl)}
                                              onClose={handleMenuClose}
                                            >
                                              <MenuItem>
                                                <StyledButton
                                                  variant="outlined"
                                                  type="submit"
                                                  color="primary"
                                                  onClick={() =>
                                                    handleAbrir(
                                                      tarjeta,
                                                      tarjeta.id
                                                    )
                                                  }
                                                  sx={{
                                                    width: "max-content",
                                                    fontWeight: "bold",
                                                    border: "2px solid",
                                                    color: !estilo
                                                      ? "#437294"
                                                      : estilo.color_principal,
                                                    borderRadius: "10px",
                                                  }}
                                                >
                                                  Activar
                                                </StyledButton>
                                              </MenuItem>
                                              <MenuItem>
                                                <StyledButton
                                                  variant="outlined"
                                                  type="submit"
                                                  color="primary"
                                                  onClick={() =>
                                                    handlePagar(tarjeta)
                                                  }
                                                  disabled={
                                                    !tarjeta.informacion_bancaria ||
                                                    parseInt(
                                                      tarjeta.clic_cobrar
                                                    ) *
                                                      5 +
                                                      parseInt(
                                                        tarjeta.efectivo_reviews
                                                      ) *
                                                        10 +
                                                      parseInt(
                                                        tarjeta.efectivo_menu
                                                      ) ===
                                                      0
                                                  }
                                                  sx={{
                                                    width: "max-content",
                                                    fontWeight: "bold",
                                                    border: "2px solid",
                                                    color: !estilo
                                                      ? "#437294"
                                                      : estilo.color_principal,
                                                    borderRadius: "10px",
                                                  }}
                                                >
                                                  Pagar{" "}
                                                  {parseInt(
                                                    tarjeta.clic_cobrar
                                                  ) *
                                                    5 +
                                                    parseInt(
                                                      tarjeta.efectivo_reviews
                                                    ) *
                                                      10 +
                                                    parseInt(
                                                      tarjeta.efectivo_menu
                                                    ) !==
                                                  0
                                                    ? parseInt(
                                                        tarjeta.clic_cobrar
                                                      ) *
                                                        5 +
                                                      parseInt(
                                                        tarjeta.efectivo_reviews
                                                      ) *
                                                        10 +
                                                      parseInt(
                                                        tarjeta.efectivo_menu
                                                      )
                                                    : " "}
                                                </StyledButton>
                                              </MenuItem>
                                              <MenuItem>
                                                <StyledButton
                                                  variant="outlined"
                                                  type="submit"
                                                  color="primary"
                                                  onClick={() =>
                                                    handleVer(
                                                      tarjeta.pagos,
                                                      tarjeta.id
                                                    )
                                                  }
                                                  disabled={
                                                    !tarjeta.pagos.length
                                                  }
                                                  sx={{
                                                    width: "max-content",
                                                    fontWeight: "bold",
                                                    border: "2px solid",
                                                    color: !estilo
                                                      ? "#437294"
                                                      : estilo.color_principal,
                                                    borderRadius: "10px",
                                                  }}
                                                >
                                                  Ver
                                                </StyledButton>
                                              </MenuItem>
                                            </Menu>
                                          )}
                                        </div>

                                        {/* </Link> */}
                                      </StyledTableCell>
                                    )}
                                  </StyledTableRow>
                                ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                    <PaginatorServer
                      rowsPerPage={rowsPerPage}
                      handleChangePage={handleChangePage}
                      page={page}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                      length={totalRegs}
                    />
                  </>
                ) : (
                  entidad && (
                    <DetallesEntidadComercial
                      entidad={entidad}
                      handleClose={onclose}
                    />
                  )
                )}
              </ThemeProvider>
            </Box>
          </Grid>
          <ModalActivarEfectivo
            isVisible={ShowModalActivar}
            onClose={() => setShowModalActivar(false)}
            max={maximo}
            id={id}
            // actualizarEfectivo={actualizarEfectivo}
          />
          <ModaPagarEfectivo
            isVisible={ShowModalPagar}
            onClose={() => setShowModalPagar(false)}
            tarjeta={pagarTarjeta}
            // actualizarPago={actualizarPago}
          />
          <ModalVerPagos
            isVisible={ShowModalPagos}
            onClose={() => setShowModalPagos(false)}
            pagos={listPagos}
          />
          <ModalActivarPuntos
            isVisible={ShowModalPuntos}
            onClose={() => setShowModalPuntos(false)}
            id={id}
            // actualizarPuntos={actualizarPuntos}
          ></ModalActivarPuntos>
          <ModalCanjearPuntos
            isVisible={ShowModalCanjear}
            onClose={() => setShowModalCanjear(false)}
            id={id}
            // actualizarPuntos={actualizarPuntos}
            activarModal={activarModal}
            puntos={puntos}
          />
          <ModalVerPuntos
            isVisible={ShowModalVerPuntos}
            onClose={() => setShowModalVerPuntos(false)}
            pagos={listPuntos}
            puntosG={puntos}
          />
        </Grid>
      </Container>
    </>
  );
};
