import { styled } from '@mui/system'
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import {  TableRow} from '@mui/material'

export const descendingComparator = (a, b, orderBy)=>{
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export const getComparator = ( order, orderBy ) => {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);

}

// const theme = createTheme(esES)
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        //backgroundColor: theme.palette.common.black,
         color: theme.palette.common.orange,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    }
}))

export const Container = styled('div')(({ theme }) => ({
    margin: '20px 4px',
    [theme.breakpoints.down('sm')]: {
        margin: '10px',
    },
    '& .breadcrumb': {
        marginBottom: '0px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '6px',
        },
    },
}))

export const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}