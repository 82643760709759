import { Box, Button, Grid, styled, Icon } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/system';
import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MuiDialogActions from '@mui/material/DialogActions';
import { useState, useEffect } from 'react';
import { Alert, Snackbar } from "@mui/material";


const UserInfo = styled('div')(({ theme }) => ({
  paddingRight: '33px',
  paddingLeft: '33px',
  paddingTop: '0',
  paddingBottom: '0',
  textAlign: 'center',
}))

const H5 = styled('h5')(({ theme }) => ({
  padding: '0px',
  color: '#053D6B',
  fontSize: '16px',
  fontWeight: 'bold',
  marginTop: '10px',
  marginBottom: '10px'
}))

const Parrafo = styled('h5')(({ theme }) => ({
  padding: '0px',
  color: '#242532',
  fontSize: '16px',
  fontWeight: 'normal',
  marginTop: '10px',
  marginBottom: '10px'
}))

const Titulo = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: '#053D6B'
}));

const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  '&.root': { margin: 0, padding: theme.spacing(1) },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));

export default function ModalRecuperarContraseña(usuario) {

  const { user } = usuario

  //estado Snackbar respuesta correcta en la llamada a endpoint
  const [openSn, setOpenSn] = React.useState(false);
  function handleClick() {
    setOpenSn(true);
  }
  function handleCloseSn(_, reason) {
    if (reason === "clickaway") {
      return;
    }
    setOpenSn(false);
    handleClose();
  }

  //estado Snackbar respuesta error en la llamada a endpoint
  const [openEr, setOpenEr] = React.useState(false);
  function handleClickEr() {
    setOpenEr(true);
  }
  function handleCloseEr(_, reason) {
    if (reason === "clickaway") {
      return;
    }
    setOpenEr(false);
    handleClose();
  }

  const [estilo, setEstilo] = useState(null)

  const [cargando, setCargando] = useState(false);

  // console.log("user,contact", user, contacto)

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  const handleForgotPassword = async () => {
  //   setCargando(true)
  //   try {

  //     const resp = await noTokenFetch('auth/password/recuperar-password', { correo: user.correo }, 'POST');
  //     const body = await resp.json();
  //     console.log(body)
  //     if (body.status !== 200) {
  //       console.log('Error al enviar email de recuperación: ', body.message)
  //       setCargando(false)
  //       // navigate('/login');
  //       return handleClickEr();
  //     }

  //     console.log(body);
  //     setCargando(false)
  //     return handleClick();
  //     // navigate('/login');
  //   } catch (error) {
  //     console.log('Error al enviar email de recuperación: ', error.message)
  //     setCargando(false)
  //     return handleClickEr();
  //   }
  };

  useEffect(() => {
    // console.log(interfaz)
    if (localStorage.getItem("interfaz")) {
      // console.log(JSON.parse(localStorage.getItem("interfaz")))
      const slug = JSON.parse(localStorage.getItem("interfaz"))
      setEstilo(slug)
    }
  }, [])

  return (
    <Box>
      <StyledButton variant='outlined' type='submit' color='primary'
        onClick={handleClickOpen}
        sx={{
          width: 'max-content',
          fontWeight: 'bold',
          border: '2px solid',
          color: (!estilo) ? '#437294' : estilo.color_principal,
          borderRadius: '10px'
        }}
      >
        <Icon sx={{ mr: '10px' }}>vpn_key</Icon>
        Recuperar Contraseña
      </StyledButton>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Titulo id="responsive-dialog-title" sx={{ background: "#ffffff" }}>
          <IconButton aria-label="Close" className="closeButton" onClick={handleClose} sx={{ color: theme.palette.grey[500], position: 'absolute', right: theme.spacing(1), top: theme.spacing(1), }}>
            <CloseIcon />
          </IconButton>

          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
            <UserInfo>
              <Box display="flex" alignItems="center">
                <Box
                  alignItems="center"
                  sx={{
                    display: 'inline-block',
                    height: '169px',
                    marginBottom: '0px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    position: 'relative',
                    textAlign: 'center',
                    width: '200px',
                  }}
                >
                  <img src="/assets/images/adn/correo-electronico.png" width="90%"
                    sx={{
                      justifySelf: 'center',
                      cursor: 'pointer',
                    }}
                  />
                </Box>
              </Box>
            </UserInfo>
          </Grid>
        </Titulo>

        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ margin: 0 }}>
          <H5 sx={{ margin: 0, position: 'relative', textAlign: 'center', }}>
            Enviar correo de recuperación a la dirección
          </H5>

          <Parrafo sx={{ margin: 0, position: 'relative', textAlign: 'center', }}>
            {user.correo}
          </Parrafo>
        </Grid>

        <DialogActions>

          <StyledButton
            disabled={cargando}
            variant='outlined' type='submit' color='secondary'
            onClick={handleClose}
            sx={{
              width: 'max-content',
              fontWeight: 'bold',
              border: '2px solid #DA4040',
              borderRadius: '10px',
              color: 'crimson'
            }}
          >
            Regresar
          </StyledButton>

          <StyledButton variant="contained" color="primary" type='submit'
            disabled={cargando}
            onClick={handleForgotPassword}
            sx={{
              color: '#ffffff',
              width: 'max-content',
              background: (!estilo) ? '#053D6B' : estilo.color_principal,
              borderRadius: '10px',
              fontWeight: 'bold'
            }}>
            <Icon sx={{ mr: '10px' }}>create</Icon>
            Enviar
          </StyledButton>
        </DialogActions>

        <Snackbar open={openSn} autoHideDuration={6000} onClose={handleCloseSn}>
          <Alert onClose={handleCloseSn} severity="success" sx={{ width: "100%" }} variant="filled">
            Le enviaremos un enlace a su correo para restablecer la contraseña.
          </Alert>
        </Snackbar>

        <Snackbar open={openEr} autoHideDuration={6000} onClose={handleCloseEr}>
          <Alert onClose={handleCloseEr} sx={{ m: 1 }} severity="error" variant="filled">
            Ocurrio un error, no se pudo enviar el correo de recuperación!
          </Alert>
        </Snackbar>

      </Dialog>
    </Box >
  );
}
