import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Table,
  TableBody,
  Box,
  Paper,
  TableContainer,
  Grid,
  Icon,
  Skeleton,
  IconButton,
  styled,
} from "@mui/material";
import { esES } from "@mui/material/locale";
import Button from "@mui/material/Button";
import { EnhancedTableHead } from "../../compontents/EnhancedTableHead";
import { PaginatorServer } from "../../compontents/Paginator";
import { usePagination } from "../../compontents/usePagination";
import {
  getComparator,
  StyledTableCell,
  StyledTableRow,
  Container,
  stableSort,
} from "../../compontents/TableFunctions";
import { useNavigate, Link } from "react-router-dom";
import DetallesEntidadComercial from "./DetallesEntidadComercial";
import axios from "axios";

const SmallEstadoActivo = styled("small")(({ activo }) => ({
  height: 30,
  width: "max-content",
  alignSelf: "center",
  fontSize: 20,
  fontWeight: "bold",
  color: activo ? "#84CDA4" : "#F16360",
  padding: "2px 8px",
  borderRadius: "4px",
  overflow: "hidden",
  background: activo ? "#DAF4E9" : "#FDEDED",
  boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)",
}));

const SmallTipoEntidad = styled("small")(({ tipoEntidad }) => ({
  height: 30,
  width: "max-content",
  alignSelf: "center",
  fontSize: 20,
  fontWeight: "bold",
  color: "white",
  padding: "2px 8px",
  borderRadius: "4px",
  overflow: "hidden",
  background:
    tipoEntidad === "RESTAURANTE"
      ? "#66B9BF"
      : tipoEntidad === "RETAIL"
      ? "#FF5733"
      : "#EEAA7B",
  boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));

/**
 * Cabecera de la tabla
 */

const headCells = [
  {
    id: "Nombre Comercial",
    numeric: false,
    disablePadding: false,
    label: "Nombre Comercial",
  },
  {
    id: "Estado",
    numeric: false,
    disablePadding: false,
    label: "Estado",
  },
  {
    id: "Dirección",
    numeric: false,
    disablePadding: false,
    label: "Dirección",
  },
  {
    id: "Teléfono",
    numeric: false,
    disablePadding: false,
    label: "Teléfono",
  },
  {
    id: "Tipo de Entidad",
    numeric: false,
    disablePadding: false,
    label: "Tipo de Entidad",
  },
  {
    id: "Detalles",
    numeric: false,
    disablePadding: true,
    label: "Detalles",
  },
];

const theme = createTheme(esES);

/**
 *
 * @param  listaEntidades los nuevos registros que vienen de 10en 10 de la paginación
 * @param  getPage función para obtener la pagina que se consulta
 * @param  totalRegs el total de registros que trae la consulta a la api
 * @returns  el componente tabla
 */

export const EntidadComercialTable = ({
  listaEntidades = [],
  getPage,
  totalRegs,
}) => {
  const [estilo, setEstilo] = useState(null);
  const [entidad, setEntidad] = useState(null);
  const [open, setOpen] = React.useState(false);
  const baseUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    // console.log(interfaz)
    if (localStorage.getItem("interfaz")) {
      // console.log(JSON.parse(localStorage.getItem("interfaz")))
      const slug = JSON.parse(localStorage.getItem("interfaz"));
      setEstilo(slug);
    }
  }, []);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");

  const { page, handleChangePage, loading, registros } = usePagination(
    listaEntidades,
    listaEntidades.length,
    getPage
  );

  const handleRequestSort = (event, property) => {
    // console.log('handleRequestSort called')
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    // setPage(0);
  };

  const skeletonArray = Array(10).fill("");

  const handleEditClick = async (row) => {
    // console.log("row: ", row);
    setEntidad(row);
    setOpen(true);
    // console.log('iD: ', id)
    // await navigate(`/entidad/detalles/${id}`);
  };

  onclose = () => {
    setOpen(false);
  };

  return (
    <>
      <Container>
        <Grid container>
          <Grid item xs={12} md={12}>
            <Box with="100%" overflow="auto">
              <ThemeProvider theme={theme}>
                {!open ? (
                  <>
                    <Paper sx={{ with: "100%", overflow: "scroll" }}>
                      <TableContainer sx={{ maxHeight: "100%" }}>
                        <Table
                          sx={{ minWidth: 650 }}
                          size="small"
                          aria-label="a dense table"
                        >
                          <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={registros.length}
                            headCells={headCells}
                          />

                          <TableBody>
                            {loading &&
                              skeletonArray.map((item, index) => (
                                <StyledTableRow key={index}>
                                  {headCells.map((head) => (
                                    <StyledTableCell key={head.id}>
                                      <Skeleton
                                        variant="rectangular"
                                        width="100%"
                                        height={20}
                                      />
                                    </StyledTableCell>
                                  ))}
                                </StyledTableRow>
                              ))}
                            {!loading &&
                              stableSort(
                                registros,
                                getComparator(order, orderBy)
                              )
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((row, i) => (
                                  <StyledTableRow key={row.id}>
                                    <StyledTableCell align="left">
                                      {row.nombre_comercial}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      <SmallEstadoActivo activo={row.estado}>
                                        {row.estado ? "Activo" : "Inactivo"}
                                      </SmallEstadoActivo>
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.direccion}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {row.telefono || ""}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      <SmallTipoEntidad
                                        tipoEntidad={
                                          row.tipo_comercio.nombre_tipo
                                        }
                                      >
                                        {row.tipo_comercio.nombre_tipo}
                                      </SmallTipoEntidad>
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {/* <Link to={`/entidad/detalles/${row.id}`} > */}
                                      <StyledButton
                                        variant="outlined"
                                        type="submit"
                                        color="primary"
                                        onClick={() => handleEditClick(row)}
                                        sx={{
                                          width: "max-content",
                                          fontWeight: "bold",
                                          border: "2px solid",
                                          color: !estilo
                                            ? "#437294"
                                            : estilo.color_principal,
                                          borderRadius: "10px",
                                        }}
                                      >
                                        Ver Detalles
                                      </StyledButton>

                                      {/* </Link> */}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                    <PaginatorServer
                      rowsPerPage={rowsPerPage}
                      handleChangePage={handleChangePage}
                      page={page}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                      length={totalRegs}
                    />
                  </>
                ) : (
                  entidad && (
                    <DetallesEntidadComercial
                      entidad={entidad}
                      handleClose={onclose}
                    />
                  )
                )}
              </ThemeProvider>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
