import React, { useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import "./styles.css";
import TextField from "@mui/material/TextField";
import axios from "axios";
import Swal from "sweetalert2";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";

const baseUrl = process.env.REACT_APP_BACKEND_URL;
const accountSid = "ACe04bac2927f81f4b9244f08790aac216";
const authToken = "45b3e6e661539dde6204b491eedcaa5b";

const ModaPagarEfectivo = ({ isVisible, onClose, tarjeta, actualizarPago }) => {
  const [informacion_bancaria, setInformacion_bancaria] = useState("");
  const [valor, setValor] = useState("");

  useEffect(() => {
    if (tarjeta) {
      setInformacion_bancaria(JSON.parse(tarjeta.informacion_bancaria));
      let onlytap = parseInt(tarjeta.clic_cobrar) * 5;
      let reviews = parseInt(tarjeta.efectivo_reviews) * 10;
      let menu = parseInt(tarjeta.efectivo_menu);
      setValor(onlytap + reviews + menu);
    }
  }, [tarjeta]);

  if (!isVisible) return null;

  const handleSaveClick = async () => {
    try {
      // console.log(accountSid, authToken);
      // // Crear una solicitud a la API de Twilio
      // const response = await axios.post(
      //   "https://api.twilio.com/2010-04-01/Accounts/" +
      //     accountSid +
      //     "/Messages.json",
      //   {
      //     To: "+593 99 845 1174",
      //     From: "+1 240 724 8563",
      //     Body: "hola guapo",
      //   },
      //   {
      //     headers: {
      //       Authorization: "Basic " + btoa(accountSid + ":" + authToken),
      //     },
      //   }
      // );

      // // Comprobar el estado de la respuesta
      // if (response.status === 200) {
      //   console.log("Mensaje enviado correctamente", response);
      // } else {
      //   console.log("Error", response);
      // }

      const detalleJSON = {
        onlytap: tarjeta.clic_cobrar,
        reviews: tarjeta.efectivo_reviews,
        menu: tarjeta.efectivo_menu,
      };

      const objetoJSON = {
        user_tarjeta_id: tarjeta.id,
        detalle: JSON.stringify(detalleJSON),
        informacion_bancaria: tarjeta.informacion_bancaria,
        cantidad: valor,
      };

      console.log(objetoJSON);

      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      };

      const pago = await axios.post(
        baseUrl + `pagos/realizados/crear`,
        JSON.stringify(objetoJSON),
        { headers }
      );
      if (pago.status === 201) {
        console.log("Pago realizado con éxito", pago);
        const response = await axios.post(
          baseUrl + `usuario/tarjeta/actualizar/${tarjeta.id}`,
          {
            clic_cobrar: 0,
            clic_pagados:
              parseInt(tarjeta.clic_pagados) + parseInt(tarjeta.clic_cobrar),
            informacion_bancaria: "",
            efectivo_menu: 0,
            efectivo_reviews: 0,
          },
          { headers }
        );
        if (response.status === 200) {
          actualizarPago(tarjeta.id, tarjeta.clic_cobrar);
          Swal.fire({
            icon: "success",
            title: "Pago realizado con éxito",
            text: "",
            confirmButtonText: "Aceptar",
          });
          onClose();
        }
      }
    } catch (error) {
      console.error("Error gestionando el pago:", error);
    }
  };

  const handleCancel = () => {
    window.dispatchEvent(new Event("storage"));
    onClose();
  };

  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={isVisible}
    >
      <div className="modal-container">
        <div className="modal-body">
          <div className="input-container">
            <div className="modal-header">
              <h5>
                <b>Información para el pago de: </b> {valor}${" "}
              </h5>
              <Button onClick={onClose} className="close-button">
                X
              </Button>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr", // Dos columnas iguales
                gap: "0px", // Espacio entre las columnas
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                <strong>Nombre:</strong> <br />
              </div>

              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                {tarjeta.nombre} <br />
              </div>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr", // Dos columnas iguales
                gap: "0px", // Espacio entre las columnas
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                <strong>Correo:</strong> <br />
              </div>

              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                {tarjeta.correo} <br />
              </div>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr", // Dos columnas iguales
                gap: "0px", // Espacio entre las columnas
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                <strong>Identificación:</strong> <br />
              </div>

              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                {informacion_bancaria.identificacion} <br />
              </div>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr", // Dos columnas iguales
                gap: "0px", // Espacio entre las columnas
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                <strong>Tarjeta:</strong> <br />
              </div>

              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                {tarjeta.id} <br />
              </div>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr", // Dos columnas iguales
                gap: "0px", // Espacio entre las columnas
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                <strong>Entidad bancaria:</strong> <br />
              </div>

              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                {informacion_bancaria.entidad} <br />
              </div>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr", // Dos columnas iguales
                gap: "0px", // Espacio entre las columnas
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                <strong>Tipo de cuenta:</strong> <br />
              </div>

              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                {informacion_bancaria.tipoCuenta} <br />
              </div>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr", // Dos columnas iguales
                gap: "0px", // Espacio entre las columnas
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                <strong>Número de cuenta:</strong> <br />
              </div>

              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                {informacion_bancaria.numeroCuenta} <br />
              </div>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr", // Dos columnas iguales
                gap: "0px", // Espacio entre las columnas
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                <strong>OnlyTap vendidas:</strong> <br />
              </div>

              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                {tarjeta.clic_cobrar} <strong>Efectivo:</strong>{" "}
                {tarjeta.clic_cobrar * 5}$
              </div>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr", // Dos columnas iguales
                gap: "0px", // Espacio entre las columnas
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                <strong>Reviews vendidas:</strong> <br />
              </div>

              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                {tarjeta.efectivo_reviews} <strong>Efectivo:</strong>{" "}
                {tarjeta.efectivo_reviews * 10}$
              </div>
            </div>{" "}
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr", // Dos columnas iguales
                gap: "0px", // Espacio entre las columnas
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                <strong>Ganancias por menú:</strong>
              </div>

              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                {tarjeta.efectivo_menu}$
              </div>
            </div>
            <div className="linea-botones">
              <button onClick={handleCancel} className="cancel-button">
                Cancelar
              </button>
              <button
                type="button"
                onClick={handleSaveClick}
                // disabled={estado}
                className="submit-button"
              >
                Pagar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default ModaPagarEfectivo;
