import React, { useState, useEffect } from "react";
import "./styles.css";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";

const CloseButton = styled(Button)({
  position: "absolute",
  top: "0px",
  right: "0px",
  background: "none",
  border: "none",
  cursor: "pointer",
  fontSize: "1rem",
  padding: "0px",
  borderRadius: "25px", // Hacer que el borde sea completamente redondo
});

function ModelDescripcion({ isVisible, onClose }) {
  const [descripcion, setDescripcion] = useState(
    localStorage.getItem("Descripcion") || ""
  );
  const [isDescripcionValid, setIsDescripcionValid] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setIsDescripcionValid(!!descripcion.trim());
  }, [descripcion]);

  if (!isVisible) return null;

  const handleDescripcionChange = (event) => {
    const newDescripcion = event.target.value;
    localStorage.setItem("Descripcion", newDescripcion);
    window.dispatchEvent(new Event("storage"));
    setDescripcion(newDescripcion);
  };

  const handelClose = (e) => {
    if (e.target.id === "close") onClose();
  };

  const handleSaveClick = () => {
    if (isDescripcionValid) {
      if (descripcion) {
        setErrorMessage("");
        onClose();
      } else {
        setErrorMessage("Descripción Incorrecta");
      }
    } else {
      setErrorMessage("Ingrese una descripción para continuar.");
    }
  };

  const handleCancel = () => {
    localStorage.setItem("Descripcion", "");
    window.dispatchEvent(new Event("storage"));
    setDescripcion("");
    setErrorMessage("");
    onClose();
  };

  return (
    <Backdrop
      sx={{
        color: "#000",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={isVisible}
    >
      <div>
        <div className="modal-container">
          <div className="modal-body">
            <CloseButton variant="outlined" onClick={onClose}>
              x
            </CloseButton>
            <div className="input-container">
              <div className="modal-header">
                <h5>Descripción</h5>
              </div>
              <div className="form-floating">
                <textarea
                  type="text"
                  name="description"
                  value={descripcion}
                  onChange={handleDescripcionChange}
                  className="input-register my-3"
                  placeholder=""
                  rows="5"
                ></textarea>
              </div>
              <div className="example" style={{ textAlign: "justify" }}>
                Ejemplo:{" "}
                <span style={{ color: "blue" }}>
                  Desarrollador web con más de 10 años de experiencia en la
                  creación de sitios web personalizados y aplicaciones móviles.
                </span>
              </div>
            </div>
            {errorMessage && (
              <div className="error-message">{errorMessage}</div>
            )}
            <div className="linea-botones">
              <button onClick={handleCancel} className="cancel-button">
                Vaciar
              </button>
              <button
                type="button"
                onClick={handleSaveClick}
                className="submit-button"
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
}

export default ModelDescripcion;
