import React from 'react'

import { TablePagination, Grid} from '@mui/material'

export const PaginatorServer = ({rowsPerPage, handleChangePage, page, handleChangeRowsPerPage, length }) => {
  
    return (
      <Grid container spacing={2}>
          <Grid item xs={12} display='flex' justifyContent='center' md={12}>
              <TablePagination
                  sx={{ px: 2 }}
                  
                  rowsPerPageOptions={[20]}
                  component="div"
                  count={length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                      'aria-label': 'Previous Page',
                  }}
                  nextIconButtonProps={{
                      'aria-label': 'Next Page',
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
              />
  
          </Grid>
      </Grid>
  
    )
  }
  