import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-number-input";
import { styled } from "@mui/material/styles";
import { Button, InputLabel, Input, CircularProgress } from "@mui/material";
import { uploadFileToFB } from "../../../firebaseConfig";
import axios, { formToJSON } from "axios";
import Swal from "sweetalert2";
import Backdrop from "@mui/material/Backdrop";

const baseUrl = process.env.REACT_APP_BACKEND_URL;

const UploadFormContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "16px",
  border: "2px dashed",
  borderColor: "#3f51b5",
  borderRadius: "4px",
  backgroundColor: "#f5f5f5",
  color: "#616161",
  outline: "none",
  transition: "border .24s ease-in-out",
  "&:hover": {
    borderColor: "#283593",
  },
});

function Form({ entidad }) {
  console.log(entidad);
  const [titulo, setTitulo] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [puntos, setPuntos] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [showImage, setShowImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imgFile, setImgFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const handleTituloChange = (event) => {
    const newTitulo = event.target.value;
    setTitulo(newTitulo);
  };

  const handleDescripcionChange = (event) => {
    const newDescripcion = event.target.value;
    setDescripcion(newDescripcion);
  };

  const handlePuntosChange = (event) => {
    const newPuntos = event.target.value;
    console.log(newPuntos);
    setPuntos(newPuntos);
  };

  const handleRemoveImage = () => {
    setImageUrl("");
    setShowImage(false);
    setImgFile(null);
    setLoading(false);
    setErrorMessage("");
  };

  const handleCancel = () => {
    setTitulo("");
    setDescripcion("");
    setPuntos("");
    setImageUrl("");
    setShowImage(false);
    setImgFile(null);
    setLoading(false);
    setErrorMessage("");
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    const validExts = ["png", "jpg", "jpeg", ".PNG"];

    if (file) {
      const ext = file.name.split(".")[1];
      if (!validExts.includes(ext)) {
        alert(
          "Error",
          `El archivo con extensión .${ext} no está permitido como logo`,
          "error"
        );
        setLoading(false);
        return;
      }
      setImgFile(file);
    }
    const url = URL.createObjectURL(file);
    console.log(url);
    setImageUrl(url);
    setShowImage(true);
  };

  const handleSaveClick = async (e) => {
    e.preventDefault();
    let url = "";
    setLoading(true);
    console.log("Guardando...", titulo, descripcion, puntos, imgFile);
    try {
      if (titulo === "") {
        setErrorMessage("Ingrese un título.");
        setLoading(false);
        return;
      }
      if (descripcion === "") {
        setErrorMessage("Ingrese una descripción.");
        setLoading(false);
        return;
      }
      if (puntos === "") {
        setErrorMessage("Ingrese una cantidad de puntos.");
        setLoading(false);
        return;
      }
      if (imgFile !== null) {
        setLoading(true);
        const uploadedUrl = await uploadFileToFB(imgFile, "storagePromociones");
        if (uploadedUrl.url) {
          console.log(uploadedUrl.url);
          url = uploadedUrl.url;
        }
        setErrorMessage("");
        setLoading(false);
      } else {
        setErrorMessage("Seleccione una imagen.");
        setLoading(false);
        return;
      }

      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      };

      const response = await axios.post(
        baseUrl + `promociones/fidelizacion/crear`,
        {
          nombre: titulo,
          puntos: puntos,
          imagen: url,
          descripcion: descripcion,
          tipo:
            entidad?.configuracion_puntos?.length > 0
              ? entidad?.configuracion_puntos[0]?.tipo
              : "GLOBALES",
          comercio_id: entidad?.id ? entidad?.id : "",
        },
        { headers }
      );
      if (response.status === 201) {
        Swal.fire({
          icon: "success",
          title: "Promoción creada",
          text: "La promoción ha sido creada con éxito",
          confirmButtonText: "Aceptar",
        });
        handleCancel();
      }
      setLoading(false);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error al crear a promoción",
        text: "Ha ocurrido un error al crear la promoción",
        confirmButtonText: "Aceptar",
      });
      console.error("Error al crear la promoción:", error);
    }
  };

  return (
    <div
      className=""
      style={{
        borderRadius: "10px",
        border: "1px solid #E0E0E0",
        padding: "20px",
        backgroundColor: "white",
      }}
    >
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="row">
        <div className="col">
          <h5>Título Promoción</h5>
          <input
            type="text"
            name="titulo"
            value={titulo}
            onChange={handleTituloChange}
            className="input-register"
            placeholder=""
            style={{
              width: "100%",
              borderRadius: "10px",
              backgroundColor: "#F5F5F5",
            }}
            required={true}
          />
        </div>
        <div className="col">
          <h5>$ Valor Promocion</h5>
          <input
            type="number"
            name="puntos"
            value={puntos}
            onChange={handlePuntosChange}
            className="input-register"
            style={{
              width: "100%",
              borderRadius: "10px",
              padding: "10px",
              backgroundColor: "#F5F5F5",
              border: "1px solid #ccc",
              boxSizing: "border-box",
              // Add any other styles you need
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <br />
          <h5>Descripción</h5>
          <textarea
            type="text"
            name="description"
            value={descripcion}
            onChange={handleDescripcionChange}
            className="input-register"
            placeholder=""
            rows="5"
            style={{
              width: "100%",
              borderRadius: "10px",
              backgroundColor: "#F5F5F5",
            }}
          ></textarea>
        </div>
        <div className="col">
          <br />
          <h5>Foto de Perfil</h5>
          {showImage ? (
            <>
              <div className="">
                <div className="image-preview-container">
                  <img src={imageUrl} alt="Preview" width={200} height={200} />
                  <button
                    type="button"
                    className="remove-image-button"
                    onClick={handleRemoveImage}
                  >
                    X
                  </button>
                </div>
              </div>
            </>
          ) : (
            <UploadFormContainer
              className="input-register"
              style={{
                width: "100%",
                borderRadius: "10px",
                backgroundColor: "#F5F5F5",
              }}
            >
              {loading ? (
                <CircularProgress />
              ) : (
                <>
                  <InputLabel
                    htmlFor="upload-input"
                    style={{ marginBottom: "8px" }}
                  >
                    Subir foto selfie
                  </InputLabel>
                  <Input
                    id="upload-input"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                    className="input-register"
                  />
                  <label htmlFor="upload-input">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      Seleccionar foto
                    </Button>
                  </label>
                </>
              )}
              <div className="example" style={{ textAlign: "right" }}>
                Archivos permitidos:{" "}
                <span style={{ color: "blue" }}>
                  "png", "jpg", "jpeg", ".PNG"
                </span>
              </div>
            </UploadFormContainer>
          )}
        </div>
      </div>

      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <div className="linea-botones">
        <button onClick={handleCancel} className="cancel-button">
          Vaciar
        </button>
        <button
          type="button"
          onClick={handleSaveClick}
          className="submit-button"
        >
          Guardar
        </button>
      </div>
    </div>
  );
}

export default Form;
