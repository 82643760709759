import React, { useState, useEffect } from "react";
import "react-phone-number-input/style.css";
import "./styles.css";
import TextField from "@mui/material/TextField";
import axios, { formToJSON } from "axios";
import Swal from "sweetalert2";
import Backdrop from "@mui/material/Backdrop";
import { Button, InputLabel, Input, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";

const baseUrl = process.env.REACT_APP_BACKEND_URL;

const CloseButton = styled(Button)({
  position: "absolute",
  top: "0px",
  right: "0px",
  background: "none",
  border: "none",
  cursor: "pointer",
  fontSize: "1rem",
  padding: "0px",
  borderRadius: "25px", // Hacer que el borde sea completamente redondo
});

const ModalActivarPuntos = ({ isVisible, onClose, id }) => {
  // console.log(id);
  const [numTarjetas, setNumTarjetas] = React.useState("");
  const [estado, setEstado] = React.useState(true);
  const [descripcion, setDescripcion] = useState("");
  const [user, setUser] = useState("");
  const [rol, setRol] = useState("");
  const [tipoPuntos, setTipoPuntos] = useState("activo");
  const [comercio, setComercio] = useState("");
  const [puntos, setPuntos] = useState("");

  useEffect(() => {
    const { authorisation } = JSON.parse(localStorage.getItem("user"));
    const userDataJSON = localStorage.getItem("user");
    if (userDataJSON) {
      const { authorisation } = JSON.parse(userDataJSON);
      setUser(authorisation.data.user_tarjeta[0].id);
      setRol(authorisation.data.rol);
    }
    // console.log(rol);
    if (authorisation.data.rol !== "ADMIN") {
      const userComercios = authorisation?.data?.comercios;
      let comercioActualizado = "";

      if (userComercios) {
        // console.log(userComercios);
        userComercios.forEach((comercio) => {
          if (comercio.rol !== "USER") {
            comercioActualizado = comercio.comercio;
          }
        });
        // console.log(comercioActualizado.configuracion_puntos);

        if (comercioActualizado.configuracion_puntos[0]?.tipo === "LOCALES") {
          setTipoPuntos("activo");
        } else {
          setTipoPuntos("inactivo");
        }
      }
      // console.log(comercioActualizado);
      setComercio(comercioActualizado);
    }
  }, [isVisible, onClose, id]);

  useEffect(() => {
    if (numTarjetas === "") {
      setEstado(true);
    } else {
      setEstado(false);
    }
  }, [numTarjetas]);

  if (!isVisible) return null;

  const handleChangeTarjetas = (event) => {
    let newNumTarjeta = event.target.value;
    if (newNumTarjeta < 1) {
      newNumTarjeta = 1;
    }
    setNumTarjetas(newNumTarjeta);
  };

  const handleDescripcionChange = (event) => {
    const newDescripcion = event.target.value;
    setDescripcion(newDescripcion);
  };

  const handleSaveClick = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      };

      let puntos = parseInt(numTarjetas);

      // console.log(puntos, id, user, descripcion, comercio);

      const response = await axios.post(
        baseUrl + `puntos/fidelizacion/crear`,
        {
          cantidad: puntos,
          user_tarjeta_id: id.id,
          user_registra: user,
          detalle: descripcion,
          promocion_id: null,
          comercio_id: comercio.id,
          tipo_puntos: tipoPuntos === "activo" ? "LOCALES" : "GLOBALES",
          estado: 1,
        },
        { headers }
      );
      if (response.status === 201) {
        setNumTarjetas("");
        setDescripcion("");

        Swal.fire({
          icon: "success",
          title: "Puntos acreditados",
          text: "Se han acreditados los puntos al usuario",
          confirmButtonText: "Aceptar",
        });
        onClose();
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error al acreditar los puntos",
        text: "Ha ocurrido un error al acreditar los puntos al usuario",
        confirmButtonText: "Aceptar",
      });

      // console.error("Error al acreditar los puntos:", error);
    }
  };

  const handleCancel = () => {
    setNumTarjetas("");
    setDescripcion("");
    onClose();
  };

  const handleTipoPuntosChange = () => {
    setTipoPuntos(tipoPuntos === "activo" ? "inactivo" : "activo");
  };

  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={isVisible}
    >
      <div className="modal-container">
        <div className="modal-body">
          <div className="input-container">
            <div className="modal-header">
              <h5>
                Ingrese la cantidad de dinero que consumio el usuario:
                <br />
                <strong>ID de la tarjeta:</strong> {id.id}
                <br />
                <strong>Nombre del titular:</strong> {id.nombre}
              </h5>
              <CloseButton variant="outlined" onClick={onClose}>
                x
              </CloseButton>
            </div>
            <div>
              <div className="column-container">
                <div className="form-floating">
                  <Switch
                    checked={tipoPuntos === "activo"}
                    onChange={handleTipoPuntosChange}
                    color="primary"
                    disabled={true}
                  />
                  <Typography component="div">
                    {tipoPuntos === "activo" ? "LOCALES" : "GLOBALES"}
                  </Typography>
                  <p
                    className="label-register"
                    style={{
                      color: "black",
                      fontSize: "1.5rem",
                      fontWeight: "bold",
                      marginBottom: "0",
                      padding: "10px",
                    }}
                  >
                    $
                  </p>
                  <input
                    type="number"
                    name="puntos"
                    value={numTarjetas}
                    onChange={handleChangeTarjetas}
                    placeholder={"Consumo en dolares"}
                    className="input-register my-3"
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      padding: "10px",
                      backgroundColor: "#F5F5F5",
                      border: "1px solid #ccc",
                      boxSizing: "border-box",
                      // Add any other styles you need
                    }}
                    InputProps={{
                      inputProps: {
                        min: 1,
                      },
                    }}
                  />
                </div>
                <textarea
                  type="text"
                  name="description"
                  value={descripcion}
                  onChange={handleDescripcionChange}
                  className="input-register"
                  placeholder="Descripcion del movimiento"
                  rows="3"
                ></textarea>
              </div>
            </div>

            <div className="linea-botones">
              <button onClick={handleCancel} className="cancel-button">
                Cancelar
              </button>
              <button
                onClick={handleSaveClick}
                disabled={estado}
                className="submit-button"
              >
                Activar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default ModalActivarPuntos;
