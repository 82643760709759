// TarjetaPresentacion.js

import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const baseUrl = process.env.REACT_APP_BACKEND_URL;

const TarjetaPresentacion = () => {
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const phoneNumber = "593987441522";
  const defaultMessag =
    "Me gustaría obtener mi tarjeta digital. He conseguido este código de descuento: " +
    id;

  useEffect(() => {
    const validarYRedirigir = async () => {
      try {
        if (id) {
          // console.log(id);
          const token = localStorage.getItem("token");
          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
            },
          };
          const { data } = await axios.post(
            baseUrl + `verificar-id/${id}`,
            null, // aquí debe ir el body, pero en este caso no es necesario enviar datos adicionales
            config // aquí se pasa el objeto `config` como segundo parámetro
          );
          console.log(data);
          if (data.existe) {
            // console.log(1);
            const datos = data.data;
            const clicReferidos = parseInt(datos.clic_referidos, 10);
            const nuevoClicReferidos = clicReferidos + 1;
            const response = await axios.post(
              baseUrl + `usuario/tarjeta/actualizar/${id}`,
              {
                clic_referidos: nuevoClicReferidos,
              }
            );
            console.log(response.status);
            if (response.status === 200) {
              console.log("Redirigiendo...");
              const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
                defaultMessag
              )}`;
              window.location.href = url;
            } else {
              console.log("Error en la redirección:", response.status);
            }
          } else {
            // console.log(4);
            console.log(
              "No existe una tarjeta con ese código, vuelve a intentarlo"
            );
          }
        } else {
          // console.log(3);
          console.log(
            "No existe un codigo para verificar, vuelve a intentarlo"
          );
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(true);
    };

    validarYRedirigir();
  }, [id]);

  if (loading) {
    return (
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={true}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  // Puedes personalizar este mensaje de acuerdo al resultado de la validación.
  return <div>Validación completa. Redirigiendo...</div>;
};

export default TarjetaPresentacion;
