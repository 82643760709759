import React, { useState, useEffect } from "react";
import "react-phone-number-input/style.css";
import "./styles.css";
import TextField from "@mui/material/TextField";
import axios from "axios";
import Swal from "sweetalert2";
import { FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";

const baseUrl = process.env.REACT_APP_BACKEND_URL;

const ModalConfigurarPuntos = ({ isVisible, onClose, actualizar }) => {
  const [numTarjetas, setNumTarjetas] = React.useState("");
  const [estado, setEstado] = React.useState(true);

  useEffect(() => {
    if (numTarjetas === "") {
      setEstado(true);
    } else {
      setEstado(false);
    }
  }, [numTarjetas]);

  if (!isVisible) return null;
  const handleChangeTarjetas = (event) => {
    let newNumTarjeta = event.target.value;
    if (newNumTarjeta < 1) {
      newNumTarjeta = 0;
    }
    setNumTarjetas(newNumTarjeta);
  };

  const handleSaveClick = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      };

      const response = await axios.post(
        baseUrl + `configuracion/puntos/crear`,
        {
          comercio_id: null,
          tipo: "GLOBALES",
          cantidad_puntos: numTarjetas,
          cantidad_sellos: 0,
        },
        // { [campoCobrar]: numTarjetas },
        { headers }
      );
      if (response.status === 201) {
        actualizar(response.data);
        onClose();
        Swal.fire({
          icon: "success",
          title: "Configuración de puntos globales",
          text: "Se ha actualizado la configuración de puntos globales correctamente",
          confirmButtonText: "Aceptar",
        });
      }
    } catch (error) {
      console.error("Error al actualizar el estado de la tarjeta:", error);
    }
  };

  const handleCancel = () => {
    window.dispatchEvent(new Event("storage"));
    setNumTarjetas("");
    onClose();
  };

  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={isVisible}
    >
      <div className="modal-container">
        <div className="modal-body">
          <div className="input-container">
            <div className="modal-header">
              <h5>Configurar Puntos Globales</h5>
              <Button onClick={onClose} className="close-button">
                X
              </Button>
            </div>
            <div>
              <div className="column-container">
                {/* Segunda columna con Inputs */}

                <TextField
                  id="outlined-basic"
                  value={numTarjetas}
                  onChange={handleChangeTarjetas}
                  label={"Cantidad de puntos por dolar"}
                  type="number"
                  variant="outlined"
                  sx={{
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: "10px",
                    margin: "10px 0px",
                  }}
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                  }}
                />
              </div>
            </div>

            <div className="linea-botones">
              <button onClick={handleCancel} className="cancel-button">
                Cancelar
              </button>
              <button
                onClick={handleSaveClick}
                disabled={estado}
                className="submit-button"
              >
                Activar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default ModalConfigurarPuntos;
