import React, { useState, useEffect } from "react";
import "./styles.css";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";

const CloseButton = styled(Button)({
  position: "absolute",
  top: "0px",
  right: "0px",
  background: "none",
  border: "none",
  cursor: "pointer",
  fontSize: "1rem",
  padding: "0px",
  borderRadius: "25px", // Hacer que el borde sea completamente redondo
});

function ModelName({ isVisible, onClose }) {
  const [nombre, setNombre] = useState(localStorage.getItem("Nombre") || "");
  const [isNombreValid, setIsNombreValid] = useState(!!nombre.trim());
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setIsNombreValid(!!nombre.trim());
  }, [nombre]);

  if (!isVisible) return null;

  const handleNombreChange = (event) => {
    const newNombre = event.target.value;
    localStorage.setItem("Nombre", newNombre);
    window.dispatchEvent(new Event("storage"));
    setNombre(newNombre);
  };

  const validateName = (name) => {
    const trimmedName = name.trim();
    // Verificar que el nombre no esté en blanco o no sea solo un nombre
    if (
      !trimmedName ||
      trimmedName.length === 0 ||
      !trimmedName.includes(" ")
    ) {
      return false;
    }
    if (trimmedName.endsWith(" ")) {
      name = trimmedName.slice(0, -1);
    }
    const nameRegex = /^[a-zA-ZáéíóúñÁÉÍÓÚÑ]+(?:\s+[a-zA-ZáéíóúñÁÉÍÓÚÑ]+)*$/;
    return nameRegex.test(trimmedName);
  };

  const handleSaveClick = () => {
    if (isNombreValid) {
      if (validateName(nombre)) {
        setErrorMessage("");
        onClose();
      } else {
        setErrorMessage("Nombres incorrecto, ingrese un nombre y apellido.");
      }
    } else {
      setErrorMessage("Ingrese su nombre para continuar.");
    }
  };

  const handleCancel = () => {
    localStorage.setItem("Nombre", "");
    window.dispatchEvent(new Event("storage"));
    setNombre("");
    setErrorMessage("");
    onClose();
  };

  return (
    <Backdrop
      sx={{
        color: "#000",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={isVisible}
    >
      <div>
        <div className="modal-container">
          <div className="modal-body">
            <CloseButton variant="outlined" onClick={onClose}>
              x
            </CloseButton>
            <div className="input-container">
              <div className="modal-header">
                <h5>Nombre y apellido</h5>
              </div>
              <div className="form-floating">
                <input
                  type="text"
                  name="nombre"
                  value={nombre}
                  onChange={handleNombreChange}
                  className="input-register my-3"
                  placeholder=""
                />
              </div>
              <div className="example" style={{ textAlign: "right" }}>
                Ejemplo: <span style={{ color: "blue" }}>Juan Pérez</span>
              </div>
              {errorMessage && (
                <div className="error-message">{errorMessage}</div>
              )}
              <div className="linea-botones">
                <button onClick={handleCancel} className="cancel-button">
                  Vaciar
                </button>
                <button
                  type="button"
                  onClick={handleSaveClick}
                  className="submit-button"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
}

export default ModelName;
