import React, { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Backdrop,
  InputLabel,
  Input,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import { styled } from "@mui/material/styles";
import { uploadFileToFB } from "../../../firebaseConfig";
import Dialog from "@mui/material/Dialog";
import MuiDialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { Card } from "@mui/material";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ListaUsuarios from "./modals/ListaUsuarios";
import { UsuarioForm } from "./modals/UsuarioForm";

const CustomDialog = styled(Dialog)(({ theme }) => ({
  maxWidth: "800px",
  margin: "0 auto",
  display: "flex",
  alignItems: "center",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));

const DialogTitleRoot = styled(MuiDialogTitle)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(2),
  "& .closeButton": {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const DialogTitle = (props) => {
  const { children, onClose } = props;
  return (
    <DialogTitleRoot disableTypography>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className="closeButton"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitleRoot>
  );
};

const UploadFormContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "16px",
  border: "2px dashed",
  borderColor: "#3f51b5",
  borderRadius: "4px",
  backgroundColor: "#f5f5f5",
  color: "#616161",
  outline: "none",
  transition: "border .24s ease-in-out",
  "&:hover": {
    borderColor: "#283593",
  },
});

const baseUrl = process.env.REACT_APP_BACKEND_URL;

function NegocioCreationForm({ tipoNegocio, actualizarListado }) {
  console.log(tipoNegocio);
  const [tipoPuntos, setTipoPuntos] = useState([
    { id: 1, nombre_tipo: "GLOBALES" },
    { id: 2, nombre_tipo: "LOCALES" },
    // { id: 3, nombre_tipo: "Ambos" },
  ]);
  const { authorisation } = JSON.parse(localStorage.getItem("user"));
  const usuarios = JSON.parse(localStorage.getItem("usuarios"));
  const usuario = authorisation.data.id;
  const rol = authorisation.data.rol;
  const [formValues, setFormValues] = useState({
    // nombre_comercial: "Mr. Shake",
    // razon_social: "comida",
    // ruc: "0993354058123",
    // direccion: "6 de diciembre y tomas de berlanga ",
    // telefono: "0987654123",
    // whatsapp: "0987654123",
    // correo: "shake@gmail.com",
    // logo: "https://firebasestorage.googleapis.com/v0/b/imagenestarjeta.appspot.com/o/logoNegocio%2F1716502070398.png%20?alt=media&token=a238699d-6fd4-41c6-a7ed-528e6efef61e",
    // sitio_web: "https://www.shake.com",
    // tipo_comercio: "e62c741e-419c-4c31-a83f-e4b2a2e53fa4",
    // puntos: "GLOBALES",
    // cantidad_puntos: "",
    // cantidad_sellos: "",
    nombre_comercial: "",
    razon_social: "",
    ruc: "",
    direccion: "",
    telefono: "",
    whatsapp: "",
    correo: "",
    logo: "",
    sitio_web: "",
    tipo_comercio: "",
    puntos: "",
    cantidad_puntos: "",
    cantidad_sellos: "",
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showImage, setShowImage] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [imgFile, setImgFile] = useState(null);
  const [urlPerfil, setUrlPerfil] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [value, setValue] = React.useState(0);
  const [negocio, setNegocio] = useState({});
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleImageChange = async (e) => {
    setLoading(true);

    const file = e.target.files[0];
    const validExts = ["png", "jpg", "jpeg", ".PNG"];

    if (file) {
      const ext = file.name.split(".")[1];
      const uploadedUrl = await uploadFileToFB(file, "logoNegocio");
      setUrlPerfil(uploadedUrl.url);
      setFormValues({ ...formValues, logo: uploadedUrl.url });
      if (!validExts.includes(ext)) {
        alert(
          "Error",
          `El archivo con extensión .${ext} no está permitido como logo`,
          "error"
        );
        setLoading(false);
        return;
      }
      setImgFile(file);
    }
    const url = URL.createObjectURL(file);
    setImageUrl(url);
    setShowImage(true);
    //localStorage.setItem("FotoPerfil", urlPerfil);
    setLoading(false);
  };

  const handleRemoveImage = () => {
    setImageUrl("");
    setShowImage(false);
    setImgFile(null);
    setUrlPerfil("");
    setLoading(false);
    setErrorMessage("");
    setFormValues({ ...formValues, logo: "" });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    if (errorMessage) setErrorMessage(""); // Limpiar el mensaje de error al modificar cualquier campo
  };

  const handleCancel = () => {
    setFormValues({
      nombre_comercial: "",
      razon_social: "",
      ruc: "",
      direccion: "",
      telefono: "",
      whatsapp: "",
      correo: "",
      logo: "",
      sitio_web: "",
      tipo_comercio: "",
      puntos: "",
      cantidad_puntos: "",
      cantidad_sellos: "",
    });
    setLoading(false);
    setErrorMessage("");
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = (informacion) => {
    setNegocio(informacion);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      //lógica para enviar los datos a tu backend
      console.log(formValues);
      setLoading(true);

      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      };

      const response = await axios.post(
        baseUrl + `comercio/crear`,
        formValues,
        { headers }
      );
      const { data } = response;
      console.log(response);
      if (response.status === 201) {
        const informacion = {
          ...data.data,
          ...formValues,
        };
        handleClickOpen(informacion);
        setLoading(true);
        handleCancel();
      }
    } catch (error) {
      setLoading(false);
      // Revisamos si el error proviene de una respuesta del servidor y tiene un estado 422
      if (error.response && error.response.status === 422) {
        const errores = error.response.data; // Obtenemos los detalles del error del servidor

        // Convertimos los mensajes de error en un array de strings
        const mensajesDeError = Object.values(errores.data).flat(); // Usamos .flat() para aplanar en caso de que haya arrays anidados

        // Unimos todos los mensajes de error en un solo string, separados por un salto de línea
        const textoDeErrores = mensajesDeError.join("\n");

        // Mostramos el Swal con los errores específicos
        Swal.fire({
          icon: "error",
          title: "Ha ocurrido un error al crear el negocio",
          text: "\n" + textoDeErrores,
          confirmButtonText: "Aceptar",
          heightAuto: false, // Asegura que el modal se muestre correctamente si hay muchos errores
        });
      } else {
        // Manejo de otros tipos de errores no relacionados con la respuesta del servidor
        Swal.fire({
          icon: "error",
          title: "Error al crear el negocio",
          text: "Ha ocurrido un error desconocido al crear el negocio",
          confirmButtonText: "Aceptar",
        });
      }
      console.error("Error al crear el negocio:", error);
    }
  };

  const handleConercioCreado = (negocio) => {
    handleCancel();
    actualizarListado(negocio);
    setOpen(false);
  };

  return (
    <div
      style={{
        // borderRadius: "10px",
        // border: "1px solid #E0E0E0",
        padding: "20px",
        backgroundColor: "white",
      }}
    >
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col">
            <h5>Nombre comercial *</h5>
            <input
              type="text"
              name="nombre_comercial"
              value={formValues.nombre_comercial}
              onChange={handleChange}
              className="input-register"
              style={{
                width: "100%",
                borderRadius: "10px",
                backgroundColor: "#F5F5F5",
                padding: "10px",
              }}
              required
            />
            <div className="col">
              <br />
              <h5>Ruc *</h5>
              <input
                type="number"
                name="ruc"
                value={formValues.ruc}
                onChange={handleChange}
                className="input-register"
                style={{
                  width: "100%",
                  borderRadius: "10px",
                  backgroundColor: "#F5F5F5",
                  padding: "10px",
                }}
                required
              />
            </div>
            <div className="col">
              <br />
              <h5>Telefono *</h5>
              <input
                type="number"
                name="telefono"
                value={formValues.telefono}
                onChange={handleChange}
                className="input-register"
                style={{
                  width: "100%",
                  borderRadius: "10px",
                  backgroundColor: "#F5F5F5",
                  padding: "10px",
                }}
                required
              />
            </div>
            <div className="col">
              <br />
              <h5>Correo *</h5>
              <input
                type="email"
                name="correo"
                value={formValues.correo}
                onChange={handleChange}
                className="input-register"
                style={{
                  width: "100%",
                  borderRadius: "10px",
                  backgroundColor: "#F5F5F5",
                  padding: "10px",
                }}
                required
              />
            </div>
            <div className="col">
              <br />
              <h5>Sitio web*</h5>
              <input
                type="text"
                name="sitio_web"
                value={formValues.sitio_web}
                onChange={handleChange}
                className="input-register"
                style={{
                  width: "100%",
                  borderRadius: "10px",
                  backgroundColor: "#F5F5F5",
                  padding: "10px",
                }}
                required
              />
            </div>
          </div>
          <div className="col">
            <h5>Razon social *</h5>
            <input
              type="text"
              name="razon_social"
              value={formValues.razon_social}
              onChange={handleChange}
              className="input-register"
              style={{
                width: "100%",
                borderRadius: "10px",
                backgroundColor: "#F5F5F5",
                padding: "10px",
              }}
              required
            />
            <div className="col">
              <br />
              <h5>Dirección *</h5>
              <input
                type="text"
                name="direccion"
                value={formValues.direccion}
                onChange={handleChange}
                className="input-register"
                style={{
                  width: "100%",
                  borderRadius: "10px",
                  backgroundColor: "#F5F5F5",
                  padding: "10px",
                }}
                required
              />
            </div>
            <div className="col">
              <br />
              <h5>Whatsapp *</h5>
              <input
                type="number"
                name="whatsapp"
                value={formValues.whatsapp}
                onChange={handleChange}
                className="input-register"
                style={{
                  width: "100%",
                  borderRadius: "10px",
                  backgroundColor: "#F5F5F5",
                  padding: "10px",
                }}
                required
              />
            </div>
            <div className="col">
              <br />
              <h5>Tipo de negocio *</h5>
              <select
                name="tipo_comercio"
                value={formValues.tipo_comercio}
                onChange={handleChange}
                className="input-register"
                style={{
                  width: "100%",
                  borderRadius: "10px",
                  backgroundColor: "#F5F5F5",
                  padding: "10px",
                }}
                required
              >
                <option value="">Seleccione el tipo de negocio</option>
                {tipoNegocio.map((rolPermitido) => (
                  <option key={rolPermitido.id} value={rolPermitido.id}>
                    {rolPermitido.nombre_tipo}
                  </option>
                ))}
              </select>
            </div>
            <div className="col">
              <br />
              <h5>Acumulación de puntos *</h5>
              <select
                name="puntos"
                value={formValues.puntos}
                onChange={handleChange}
                className="input-register"
                style={{
                  width: "100%",
                  borderRadius: "10px",
                  backgroundColor: "#F5F5F5",
                  padding: "10px",
                }}
                required
              >
                <option value="">
                  Seleccione la forma de acumulación de puntos
                </option>
                {tipoPuntos.map((rolPermitido) => (
                  <option
                    key={rolPermitido.id}
                    value={rolPermitido.nombre_tipo}
                  >
                    {rolPermitido.nombre_tipo}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col">
            {formValues.puntos === "LOCALES" && (
              <>
                <div className="col">
                  <h5>Cantidad de dólares por sello *</h5>
                  <input
                    type="number"
                    name="cantidad_puntos"
                    value={formValues.cantidad_puntos}
                    onChange={handleChange}
                    className="input-register"
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      backgroundColor: "#F5F5F5",
                      padding: "10px",
                    }}
                    required
                  />
                </div>
                <div className="col">
                  <br />
                  <h5>Cantidad de sellos por tarjeta *</h5>
                  <input
                    type="number"
                    name="cantidad_sellos"
                    value={formValues.cantidad_sellos}
                    onChange={handleChange}
                    className="input-register"
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      backgroundColor: "#F5F5F5",
                      padding: "10px",
                    }}
                    required
                  />
                </div>
              </>
            )}
            <div className="input-container">
              <div className="modal-header">
                <h5>Logo</h5>
              </div>
              {showImage ? (
                <>
                  <div className="form-floating mb-5">
                    <div className="image-preview-container">
                      <img
                        src={imageUrl}
                        alt="Preview"
                        width={200}
                        height={200}
                      />
                      <button
                        type="button"
                        className="remove-image-button"
                        onClick={handleRemoveImage}
                      >
                        X
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <UploadFormContainer>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <InputLabel
                        htmlFor="upload-input"
                        style={{ marginBottom: "8px" }}
                      >
                        Subir foto selfie
                      </InputLabel>
                      <Input
                        id="upload-input"
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleImageChange}
                        className="input-register my-3"
                      />
                      <label htmlFor="upload-input">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                        >
                          Seleccionar foto
                        </Button>
                      </label>
                    </>
                  )}
                </UploadFormContainer>
              )}
              <div className="example" style={{ textAlign: "right" }}>
                Archivos permitidos:{" "}
                <span style={{ color: "blue" }}>
                  "png", "jpg", "jpeg", ".PNG"
                </span>
              </div>
              {errorMessage && (
                <div className="error-message">{errorMessage}</div>
              )}
            </div>
            <div className="col">
              <br />
              <div className="linea-botones">
                <button
                  type="button"
                  onClick={handleCancel}
                  className="cancel-button"
                >
                  Vaciar
                </button>
                <div>
                  <button
                    type="submit"
                    // onClick={handleSubmit}
                    className="submit-button"
                  >
                    Guardar
                  </button>
                  {open && (
                    <CustomDialog
                      aria-labelledby="customized-dialog-title"
                      open={open}
                      id="create-category"
                      // onClose={onClose}
                    >
                      <DialogTitle
                        id="customized-dialog-title"
                        onClose={handleClose}
                      >
                        <Card>
                          <Box sx={{ width: "100%" }}>
                            <Box
                              sx={{ borderBottom: 1, borderColor: "divider" }}
                            >
                              <Tabs
                                value={tabValue}
                                onChange={handleTabChange}
                                aria-label="basic tabs example"
                              >
                                <Tab
                                  label="Seleccionar propietario"
                                  {...a11yProps(0)}
                                />
                                <Tab label="Nuevo usuario" {...a11yProps(1)} />
                              </Tabs>
                            </Box>
                            <TabPanel value={tabValue} index={0}>
                              <ListaUsuarios
                                tarjeta={usuarios}
                                handleClose={handleClose}
                                negocio={negocio}
                                handleConercioCreado={handleConercioCreado}
                              />
                            </TabPanel>
                            <TabPanel value={tabValue} index={1}>
                              <UsuarioForm
                                usuarioProp={usuario}
                                tarjeta={negocio}
                                handleClose={handleClose}
                                limpiarSensor={usuarios.limpiarSensor}
                              />
                            </TabPanel>
                          </Box>
                        </Card>
                      </DialogTitle>
                    </CustomDialog>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </form>
    </div>
  );
}

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default NegocioCreationForm;
