import React, { useState, useEffect } from "react";
import "./styles.css";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";

const CloseButton = styled(Button)({
  position: "absolute",
  top: "0px",
  right: "0px",
  background: "none",
  border: "none",
  cursor: "pointer",
  fontSize: "1rem",
  padding: "0px",
  borderRadius: "25px", // Hacer que el borde sea completamente redondo
});

function ModelAddress({ isVisible, onClose }) {
  const [ubicacion, setUbicacion] = useState(
    localStorage.getItem("Ubicacion") || ""
  );
  const [isUbicacionValid, setIsUbicacionValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setIsUbicacionValid(!!ubicacion.trim());
  }, [ubicacion]);

  if (!isVisible) return null;

  const handleUbicacionChange = (event) => {
    const newUbicacion = event.target.value;
    localStorage.setItem("Ubicacion", newUbicacion);
    window.dispatchEvent(new Event("storage"));
    setUbicacion(newUbicacion);
  };

  const validateGoogleMapsURL = (url) => {
    const regex = /^https:\/\/maps\.app\.goo\.gl\/.+$/;
    return regex.test(url);
  };

  const handleSaveClick = () => {
    if (isUbicacionValid) {
      if (validateGoogleMapsURL(ubicacion)) {
        setErrorMessage("");
        onClose();
      } else {
        setErrorMessage("Dirección incorrecta");
      }
    } else {
      setErrorMessage("Ingrese su dirección para continuar.");
    }
  };

  const handleCancel = () => {
    localStorage.removeItem("Ubicacion");
    window.dispatchEvent(new Event("storage"));
    setUbicacion("");
    setErrorMessage("");
    onClose();
  };

  return (
    <Backdrop
      sx={{
        color: "#000",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={isVisible}
    >
      <div>
        <div className="modal-container">
          <div className="modal-body">
            <CloseButton variant="outlined" onClick={onClose}>
              x
            </CloseButton>
            <div className="input-container">
              <div className="modal-header">
                <h5>Dirección</h5>
              </div>
              <div className="form-floating">
                <input
                  type="text"
                  name="ubicacion"
                  value={ubicacion}
                  onChange={handleUbicacionChange}
                  className="input-register my-3"
                  placeholder=""
                />
              </div>
              <div className="example" style={{ textAlign: "right" }}>
                Ejemplo:
                <span style={{ color: "blue" }}>
                  https://maps.app.goo.gl/TT7cFknyxix9LsW36
                </span>
              </div>
              {errorMessage && (
                <div className="error-message">{errorMessage}</div>
              )}
              <div className="linea-botones">
                <button onClick={handleCancel} className="cancel-button">
                  Vaciar
                </button>
                <button
                  type="button"
                  onClick={handleSaveClick}
                  // disabled={!isUbicacionValid}
                  className="submit-button"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
}

export default ModelAddress;
