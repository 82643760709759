import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Table,
  TableBody,
  Box,
  Paper,
  TableContainer,
  Grid,
  Icon,
  Skeleton,
  IconButton,
  styled,
} from "@mui/material";
import { esES } from "@mui/material/locale";
import Button from "@mui/material/Button";
import { EnhancedTableHead } from "../../../../compontents/EnhancedTableHead";

import {
  getComparator,
  StyledTableCell,
  StyledTableRow,
  Container,
  stableSort,
} from "../../../../compontents/TableFunctions";
import { useNavigate, Link } from "react-router-dom";
import AsignarUsuarioModal from "../../../../views/Dashboard/Components/modals/AsignarUsuarioModal";

/**
 * Cabecera de la tabla
 */

const headCells = [
  {
    id: "identificacion",
    numeric: false,
    disablePadding: false,
    label: "Identificación",
  },
  {
    id: "nombre",
    numeric: false,
    disablePadding: false,
    label: "Nombre",
  },
  {
    id: "asignar",
    numeric: false,
    disablePadding: false,
    label: "Asignar",
  },
];

const theme = createTheme(esES);

/**
 *
 * @param  listaEntidades los nuevos registros que vienen de 10en 10 de la paginación
 * @param  getPage función para obtener la pagina que se consulta
 * @param  totalRegs el total de registros que trae la consulta a la api
 * @returns  el componente tabla
 */

export const UsuariosTable = ({
  listaEntidades = [],
  handleClose,
  negocio,
  handleConercioCreado,
}) => {
  const [estilo, setEstilo] = useState(null);
  const navigate = useNavigate();

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("estado");
  const [registros, setRegistros] = useState(listaEntidades);

  const handleRequestSort = (event, property) => {
    // console.log('handleRequestSort called')
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  return (
    <>
      <Container>
        <Grid container>
          <Grid item xs={12} md={12}>
            <Box with="100%" overflow="auto">
              <ThemeProvider theme={theme}>
                <Paper sx={{ with: "100%", overflow: "scroll" }}>
                  <TableContainer sx={{ maxHeight: "100%" }}>
                    <Table
                      sx={{ minWidth: 500 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={registros.length}
                        headCells={headCells}
                      />

                      <TableBody>
                        {registros.map((row) => (
                          <StyledTableRow key={row.id}>
                            <StyledTableCell align="center">
                              {row.identificacion}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.nombres} {row.apellidos}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <AsignarUsuarioModal
                                usuario={row}
                                tarjeta={negocio}
                                handleClose={handleClose}
                                handleConercioCreado={handleConercioCreado}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </ThemeProvider>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
