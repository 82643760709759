import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { InformacionEntidadForm } from "./InformacionEntidadForm";
import { PuntosLocales } from "./PuntosLocales";
import ListaUsuario from "./ListaUsuario";
import { CargarLogoEntidad } from "./CargarLogoEntidad";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";

const DetallesEntidadComercial = ({ entidad, handleClose, role }) => {
  console.log(entidad);
  const [tabValue, setTabValue] = useState(0);
  const [entidadComercial, setEntidadComercial] = useState(entidad);
  const [comercios, setComercios] = useState([]);
  const [rol, setRol] = React.useState("");
  const theme = useTheme();

  useEffect(() => {
    const { authorisation } = JSON.parse(localStorage.getItem("user"));
    setComercios(authorisation?.data?.comercios);
    setRol(authorisation?.data?.rol);
  }, []);

  useEffect(() => {
    if (entidad) {
      setEntidadComercial(entidad);
    } else {
      const comercioEncontrado = comercios.find(
        (comercio) => comercio.rol === rol
      );
      if (comercioEncontrado) {
        setEntidadComercial(comercioEncontrado);
      }
    }
  }, [entidad, comercios, rol]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", padding: "10px" }}>
      <IconButton
        aria-label="Close"
        className="closeButton"
        onClick={handleClose}
        sx={{
          color: theme.palette.grey[500],
          position: "static",
          right: theme.spacing(9),
        }}
      >
        <CloseIcon />
      </IconButton>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="Información" {...a11yProps(0)} />
          <Tab label="Usuarios " {...a11yProps(1)} />
          {entidad?.configuracion_puntos?.length !== 0 && (
            <Tab label="Puntos locales" {...a11yProps(2)} />
          )}
          <Tab label="Detalle y colores " {...a11yProps(3)} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <InformacionEntidadForm comercio={entidadComercial} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <ListaUsuario comercio={entidadComercial} />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <PuntosLocales comercio={entidadComercial.configuracion_puntos} />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <CargarLogoEntidad />
      </TabPanel>
    </Box>
  );
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default DetallesEntidadComercial;
