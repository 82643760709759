import React, { useState } from "react";
import { Button, Card, styled } from "@mui/material";
import Typography from "@mui/material/Typography";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useEffect } from "react";
import { CambiarEstadoEntidadDialog } from "./CambiarEstadoEntidadDialog";
import { SelectValidator } from "react-material-ui-form-validator";
import CircularProgress from "@mui/material/CircularProgress";
import { useNumericStringValues } from "../../utils/useNumbers";
import SaveIcon from "@mui/icons-material/Save";
import "./puntosLocales.css";
import { SimpleCard } from "../../compontents/SimpleCard";
import { Box, Grid, Badge, Avatar, Container } from "@mui/material";
import logoMarcaAgua from "../../assets/img/logo.png";

// import ModalDetalles from "../Usuarios/ModalDetalles";

const Parrafo = styled("h5")(({ theme }) => ({
  padding: "0px",
  color: "#242532",
  fontSize: "16px",
  fontWeight: "bold",
  margin: "10px 0px 0px 0px",
}));

const ParrafoC = styled("h5")(({ theme }) => ({
  padding: "0px",
  color: "#242532",
  fontSize: "14px",
  fontWeight: "normal",
  margin: "0px 0px 20px 0px",
  align: "center",
}));

const TipoUsuario = styled("small")(({ bgcolor }) => ({
  height: 30,
  width: "max-content",
  alignSelf: "center",
  fontSize: "14px",
  color: "#ffffff",
  padding: "10px 15px",
  borderRadius: "4px",
  background: bgcolor,
  borderRadius: "50px",
}));

const TextField = styled(TextValidator)(() => ({
  width: "100%",
  border: 0,
  marginTop: "0px",
  marginBottom: "0px",
}));

const GridContainer = styled(Grid)(() => ({
  display: "flex",
  alignContent: "start",
  alignItems: "center",
  borderBottom: "2px solid #DCDCDC",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));

export const PuntosLocales = ({ comercio }) => {
  const [estilo, setEstilo] = useState(null);
  const [numericValues, validInputChange] = useNumericStringValues({
    puntos: comercio[0]?.cantidad_puntos,
    sellos: comercio[0]?.cantidad_sellos,
  });

  const initInfo = {
    nombre_comercial: "",
    razon_social: "",
    ruc: "",
    direccion: "",
    telefono: "",
    whatsapp: "",
    correo: "",
    logo: "",
    sitio_web: "",
    estado: true,
    tipo_comercio: "",
  };

  const [entidad, setEntidad] = useState(comercio || initInfo);
  const [entidadAEditar, setEntidadAEditar] = useState(comercio || initInfo);
  const [disabledFields, setDisabledFields] = useState(true);

  const handleInputChange = (e) => {
    setEntidad({
      ...entidad,
      [e.target.name]: e.target.value,
    });
  };
  const handleTipoEntidadChange = (e) => {
    return setEntidad({
      ...entidad,
      tipo_entidad: e.target.value,
    });
  };

  const getEstadoModal = (estado) => {
    // console.log('Estado desde el modal: ', estado);
    setEntidad({
      ...entidad,
      estado,
    });
  };

  const coloresRol = {
    ADMIN: "#053D6B",
    PROPIETARIO: "#667AD9",
    GERENTE: "#DA4040",
    CAJERO: "#1F858A",
    MESERO: "#2BAE5A",
    COCINERO: "#FFA000",
    USER: "#4CAF50",
  };

  return entidad === null ? (
    <CircularProgress />
  ) : (
    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <Box minWidth={"85%"} sx={{ padding: 4, alignSelf: "center" }}>
        <Card sx={{ padding: 2, alignContent: "center" }}>
          <ValidatorForm>
            <GridContainer container spacing={2}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography variant="p" fontWeight={"bold"}>
                  Cantidad de puntos
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <TextField
                  type="text"
                  name="puntos"
                  placeholder="PUNTOS"
                  id="standard-basic"
                  variant="standard"
                  value={numericValues?.puntos}
                  onChange={validInputChange}
                  validators={["required"]}
                  errorMessages={["Este campo es requerido"]}
                />
              </Grid>
            </GridContainer>

            <GridContainer container spacing={2}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography variant="p" fontWeight={"bold"}>
                  Cantidad de sellos
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <TextField
                  type="text"
                  name="sellos"
                  placeholder="SELLOS"
                  id="standard-basic"
                  variant="standard"
                  value={numericValues?.sellos || ""}
                  onChange={validInputChange}
                  errorMessages={["Este campo es requerido"]}
                  validators={["required"]}
                />
              </Grid>
            </GridContainer>

            <Box
              sx={{
                pt: 3,
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <StyledButton
                size="large"
                variant="contained"
                color="primary"
                type="submit"
                sx={{
                  color: "#ffffff",
                  width: "max-content",
                  background: !estilo ? "#053D6B" : estilo.color_principal,
                  borderRadius: "10px",
                  fontWeight: "bold",
                }}
              >
                <SaveIcon />
                Guardar Información
              </StyledButton>
            </Box>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid
                  container
                  sx={{
                    position: "relative",
                    display: "table-caption",
                  }}
                >
                  <Grid item xs={12} sm={5} md={3} lg={3}></Grid>
                  <Grid
                    className="containerMayor"
                    key={comercio.id}
                    item
                    xs={12}
                    sm={5}
                    md={3}
                    lg={4}
                    sx={{
                      borderRadius: "20px",
                      textAlignLast: "center",
                      boxShadow:
                        "inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0  0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3)",
                      margin: "10px 20px 10px 20px",
                      minWidth: "350px",
                      minHeight: "250px",
                      maxHeight: "250px",
                    }}
                  >
                    <div className="container">
                      <div class="white-border"></div>
                      <div class="container-content">
                        <div className="card-header">
                          <h2>Tarjeta de Sellos</h2>
                        </div>
                        <div className="stamp-container">
                          {Array(numericValues?.puntos)
                            .fill(0)
                            .map((_, index) => (
                              <div
                                className={`stamp ${
                                  index < numericValues?.sellos
                                    ? "filled"
                                    : "empty"
                                }`}
                                key={index}
                              >
                                {index + 1}
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                    <img
                      src={logoMarcaAgua}
                      alt="Marca de agua"
                      style={{
                        position: "absolute",
                        bottom: "10px",
                        right: "10px",
                        width: "25%", // Ajusta el tamaño de la marca de agua según tus necesidades
                        height: "10%", // Ajusta el tamaño de la marca de agua según tus necesidades
                        opacity: "0.7", // Ajusta la opacidad de la marca de agua según tus necesidades
                        margin: "0px 15px 6px 0px",
                      }}
                    />
                  </Grid>

                  <Grid xs={12} sm={5} md={3} lg={3}></Grid>
                </Grid>
              </Grid>
            </Grid>
          </ValidatorForm>
        </Card>
      </Box>
    </Box>
  );
};
