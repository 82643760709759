import React, { useEffect, useState } from "react";
import { Box, styled } from "@mui/system";
import { SimpleCard } from "../../../src/compontents/SimpleCard";
import { Icon, Button, Grid, Typography } from "@mui/material";
import Dropzone from "react-dropzone";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Swal from "sweetalert2";
import { useTheme } from "@emotion/react";

const TextField = styled(TextValidator)(() => ({
  width: "90%",
  marginBottom: "16px",
}));

const UploadFileButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: 50,
}));

export const CargarLogoEntidad = () => {
  const [estilo, setEstilo] = useState(null);

  useEffect(() => {
    // console.log(interfaz)
    if (localStorage.getItem("interfaz")) {
      // console.log(JSON.parse(localStorage.getItem("interfaz")))
      const slug = JSON.parse(localStorage.getItem("interfaz"));
      setEstilo(slug);
    }
  }, []);

  const theme = useTheme();

  // console.log('Roles: ', categoria , subcategoria)

  const [imgFile, setImgFile] = useState(null);
  const [urlImgPreview, setUrlImgPreview] = useState("");
  const [colores, setColores] = useState({
    primary: "#ffffff",
    secondary: "#ffffff",
  });

  const { primary, secondary } = colores;
  const [slug, setSlug] = useState("");

  const [gradiente, setGradiente] = useState("");

  // useEffect(() => {
  //     if (entidadAEditar?.interfaz) {
  //         const { url_logo, color_principal, color_secundario, color_gradient, slug } = entidadAEditar?.interfaz;
  //         setUrlImgPreview(url_logo);
  //         setColores({
  //             primary: color_principal,
  //             secondary: color_secundario,
  //         })
  //         setGradiente(color_gradient);
  //         setSlug(slug)
  //     }
  // }, [])

  useEffect(() => {
    if (imgFile) {
      setUrlImgPreview(URL.createObjectURL(imgFile));
    }
  }, [imgFile]);

  useEffect(() => {
    setGradiente(
      `linear-gradient(90deg,${colores.primary},${colores.secondary})`
    );
  }, [colores, setColores]);

  const handleSlugChange = (e) => {
    const slug = e.target.value;
    const regex = new RegExp("^[a-zA-Z 0-9]+$");
    if (regex.test(slug) || slug.length === 0) {
      setSlug(slug.toLowerCase().trim());
    }
  };

  const onDropFiles = (files) => {
    // console.log(files[0])
    const validExts = ["png", "jpg", "jpeg"];
    if (files) {
      const ext = files[0].name.split(".")[1];
      if (!validExts.includes(ext)) {
        return Swal.fire(
          "Error",
          `El archivo con extensión .${ext} no está permitido como logo`,
          "error"
        );
      }
      setImgFile(files[0]);
    }
  };
  const handleColorChange = (e) => {
    setColores({
      ...colores,
      [e.target.name]: e.target.value,
    });
  };

  // const handleSubmit = async () => {

  //     if (!urlImgPreview || !primary || !secondary || !slug || !gradiente) {
  //         return Swal.fire('Error', 'Campos incompletos', 'error')
  //     }

  //     try {

  //         let interfaz = {
  //             // entidad_id: entidadAEditar.id,
  //             color_principal: colores.primary,
  //             color_secundario: colores.secondary,
  //             color_gradient: gradiente,
  //             slug: slug,
  //         }

  //         let urlLogo = urlImgPreview;
  //         //si existe un archivo en el estado se carga a fb alv

  //         interfaz = {
  //             ...interfaz,
  //             url_logo: urlLogo
  //         }

  //         //CREANDO NUEVO
  //         if (!entidadAEditar?.interfaz) {
  //             console.log('Creando nueva interfaz');
  //             console.log('interfaz nueva: ', interfaz);

  //             return dispatch(crearInterfazEntidad(interfaz))

  //         }
  //         //TODO: Método para actualizar la interfaz
  //         console.log('Interfaz actualizada: ', interfaz);
  //         return dispatch(actualizarInterfazEntidad(interfaz, entidadAEditar?.interfaz?.id))
  //     } catch (error) {
  //         console.log(error.message)
  //     }
  // }

  return (
    <SimpleCard>
      <Grid container spacing={2}>
        {/* Para subir la imagen */}
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <ValidatorForm>
              <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="" color={theme.palette.text.secondary}>
                    Seleccione los colores
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      paddingTop: 2,
                    }}
                  >
                    <input
                      //   disabled={!canEdit}
                      placeholder="Primario"
                      required
                      type="color"
                      name="primary"
                      value={primary}
                      onChange={handleColorChange}
                    />
                    <input
                      //   disabled={!canEdit}
                      placeholder="Primario"
                      required
                      type="color"
                      name="secondary"
                      value={secondary}
                      onChange={handleColorChange}
                    />
                  </Box>
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="" color={theme.palette.text.secondary}>
                    Gradiente final
                  </Typography>
                  <Box
                    sx={{
                      border: "2px solid #8F8E9C",
                      height: "40px",
                      margin: 1,
                      borderRadius: 3,
                      background: gradiente,
                    }}
                  ></Box>
                </Grid>
              </Grid>

              {/* Drag and drop */}

              <Box
                sx={{
                  border: "3px dashed grey",
                  borderRadius: 3,
                  height: "180px",
                  display: "flex",
                  justifyContent: "center",
                  margin: 1,
                }}
              >
                <Dropzone
                  onDrop={(acceptedFiles) => onDropFiles(acceptedFiles)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <Box sx={{ flex: 1, heigth: "100%" }}>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          height: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        {...getRootProps()}
                      >
                        <input
                          {...getInputProps()}
                          accept=".png, .jpg, .jpeg"
                          //   disabled={!canEdit}
                        />
                        <Icon sx={{ width: "20%", height: "20%" }}>
                          cloud_upload
                        </Icon>
                        <Typography color={theme.palette.text.secondary}>
                          Click, o arrastre y suelte aquí el logo
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Dropzone>
              </Box>

              <Typography variant="" color={theme.palette.text.secondary}>
                Slug
              </Typography>
              <Box>
                <TextField
                  type="text"
                  name="slug"
                  id="standard-basic"
                  fullWidth
                  value={slug}
                  onChange={handleSlugChange}
                  //   disabled={!canEdit}
                  errorMessages={[
                    "Este campo es requerido",
                    "Debe tener al menos 4 caracteres",
                    "Debe tener máximo 25 caracteres",
                  ]}
                  label="Identificador"
                  validators={[
                    "required",
                    "minStringLength: 4",
                    "maxStringLength: 25",
                  ]}
                  sx={{ marginLeft: 2 }}
                />
              </Box>
              {/* Boton */}
              <Box sx={{ padding: 1, justifyContent: "center" }}>
                <UploadFileButton
                  size="large"
                  variant="contained"
                  type="submit"
                  //   disabled={!canEdit}
                  sx={{
                    color: "#ffffff",
                    background: !estilo ? "#053D6B" : estilo.color_principal,
                    borderRadius: "10px",
                    fontWeight: "bold",
                  }}
                >
                  <Icon sx={{ marginRight: 3 }}>
                    {/* {entidadAEditar?.interfaz ? "update" : "save"} */}
                  </Icon>
                  {/* {entidadAEditar?.interfaz ? " Actualizar" : "Crear"} */}
                </UploadFileButton>
              </Box>
            </ValidatorForm>
          </Box>
        </Grid>

        {/* para previsualizar */}
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* Previsualizacion */}
          {urlImgPreview && (
            <Box sx={{ width: "100%", heigth: 150 }}>
              <Box
                component="img"
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt="The house from the offer."
                src={urlImgPreview}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </SimpleCard>
  );
};
