import React, { useState, useEffect } from "react";
import "react-phone-number-input/style.css";
import "./styles.css";
import TextField from "@mui/material/TextField";
import axios from "axios";
import Swal from "sweetalert2";
import Backdrop from "@mui/material/Backdrop";
import { Button, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";

const baseUrl = process.env.REACT_APP_BACKEND_URL;

const CloseButton = styled(Button)({
  position: "absolute",
  top: "0px",
  right: "0px",
  background: "none",
  border: "none",
  cursor: "pointer",
  fontSize: "1rem",
  padding: "0px",
  borderRadius: "25px",
});

const styleDisponibles = {
  backgroundColor: "#ffffff", // amarillo claro
  borderRadius: "5px",
};

const ModalCanjearPuntos = ({
  isVisible,
  onClose,
  activarModal,
  id,
  actualizarPuntos,
  puntos,
}) => {
  console.log("ID de la tarjeta:", id);
  const [numTarjetas, setNumTarjetas] = useState("");
  const [estado, setEstado] = useState(true);
  const [tarjetas, setTarjetas] = useState([]);
  const [user, setUser] = useState("");
  const [searchText, setSearchText] = useState("");
  const [rol, setRol] = useState("");
  const [tipoPuntos, setTipoPuntos] = useState("activo");
  const [comercio, setComercio] = useState("");
  const [wallet, setWallet] = useState([]);

  const [sumaPuntos, setSumaPuntos] = useState(0);
  const [sumaEstadoCero, setSumaEstadoCero] = useState(0);
  const [sumaEstadoUno, setSumaEstadoUno] = useState(0);

  useEffect(() => {
    const { authorisation } = JSON.parse(localStorage.getItem("user"));
    const userDataJSON = localStorage.getItem("user");
    if (userDataJSON) {
      const { authorisation } = JSON.parse(userDataJSON);
      setUser(authorisation.data.user_tarjeta[0].id);
      setRol(authorisation.data.rol);
    }
    // console.log(rol);
    if (authorisation.data.rol !== "ADMIN") {
      const userComercios = authorisation?.data?.comercios;
      let comercioActualizado = "";

      if (userComercios) {
        // console.log(userComercios);
        userComercios.forEach((comercio) => {
          if (comercio.rol !== "USER") {
            comercioActualizado = comercio.comercio;
          }
        });
        // console.log(comercioActualizado.configuracion_puntos);

        if (comercioActualizado.configuracion_puntos[0]?.tipo === "LOCALES") {
          setTipoPuntos("activo");
        } else {
          setTipoPuntos("inactivo");
        }
      }
      setComercio(comercioActualizado);
    }
    if (id) {
      console.log("ID de la tarjeta:", id.wallet);
      setWallet(id.wallet);
    }
  }, [isVisible, onClose, id]);

  useEffect(() => {
    if (isVisible) {
      const fetchData = async () => {
        try {
          const response = await axios.post(
            baseUrl + "promociones/fidelizacion/listar"
          );
          let { data } = response.data;
          setTarjetas(data);
        } catch (error) {
          console.error("Error al obtener datos de la API:", error);
        }
      };

      fetchData();
    }
  }, [isVisible]);

  useEffect(() => {
    if (numTarjetas === "") {
      setEstado(true);
    } else {
      setEstado(false);
    }
  }, [numTarjetas]);

  useEffect(() => {
    if (wallet) {
      const { sumaCero, sumaUno } = wallet.reduce(
        (acc, pago) => {
          if (parseInt(pago.estado) === 0) {
            acc.sumaCero += parseFloat(pago.total_puntos);
          } else if (parseInt(pago.estado) === 2) {
            acc.sumaUno += parseFloat(pago.total_puntos);
          }
          return acc;
        },
        { sumaCero: 0, sumaUno: 0 }
      );
      setSumaPuntos(sumaCero - sumaUno);
      setSumaEstadoCero(sumaCero);
      setSumaEstadoUno(sumaUno);
    }
  }, [wallet, setWallet]);

  if (!isVisible) return null;

  const handleCanjearClick = async (promo) => {
    onClose();
    const confirmacion = await Swal.fire({
      title: `¿Estás seguro de que deseas canjear la promoción "${
        JSON.parse(promo).nombre
      }"?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sí, canjear",
      cancelButtonText: "Cancelar",
    });

    if (confirmacion.isConfirmed) {
      try {
        const token = localStorage.getItem("token");
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        };

        console.log(JSON.parse(promo).puntos, id.id, user, promo);
        const response = await axios.post(
          baseUrl + `puntos/fidelizacion/crear`,
          {
            cantidad: JSON.parse(promo).puntos,
            user_tarjeta_id: id.id,
            user_registra: user,
            promocion_id: JSON.parse(promo).id,
            detalle: JSON.parse(promo).nombre,
            estado: 0,
            comercio_id: comercio.id,
            tipo_puntos: tipoPuntos === "activo" ? "LOCALES" : "GLOBALES",
          },
          { headers }
        );
        if (response.status === 201) {
          console.log("Respuesta de la API:", response);
          Swal.fire({
            icon: "success",
            title: "Promoción canjeada",
            text: `La promoción "${
              JSON.parse(promo).nombre
            }" se ha canjeado correctamente.`,
            confirmButtonText: "Aceptar",
          });
        } else {
          console.error("Error al canjear la promoción:", response);
          Swal.fire({
            icon: "error",
            title: "Error al canjear promoción",
            text: `Hubo un error al intentar canjear la promoción "${
              JSON.parse(promo).nombre
            }". Por favor, inténtalo de nuevo más tarde.`,
            confirmButtonText: "Aceptar",
          });
        }
      } catch (error) {
        console.error("Error al canjear la promoción:", error);
        // Si hay un error, mostrar un mensaje de error
        Swal.fire({
          icon: "error",
          title: "Error al canjear promoción",
          text: `Hubo un error al intentar canjear la promoción "${promo}". Por favor, inténtalo de nuevo más tarde.`,
          confirmButtonText: "Aceptar",
        });
      }
    } else {
      activarModal();
    }
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const filteredPromotions = tarjetas.filter((promo) => {
    const nombre = promo.nombre ? promo.nombre.toLowerCase() : "";
    const puntos = promo.puntos ? promo.puntos.toString() : "";
    return (
      nombre.includes(searchText.toLowerCase()) || puntos.includes(searchText)
    );
  });

  console.log("Promociones filtradas:", filteredPromotions);

  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={isVisible}
    >
      <div className="modal-container">
        <div className="modal-body">
          <CloseButton variant="outlined" onClick={onClose}>
            x
          </CloseButton>
          <div className="input-container">
            <div className="modal-header">
              <h5>
                Seleccione la promocion a canjear
                <br />
                <strong>Puntos:</strong> {(sumaPuntos / puntos).toFixed(2)}
                <br />
                <strong>ID de la tarjeta:</strong> {id.id}
                <br />
                <strong>Nombre del titular:</strong> {id.nombre}
              </h5>
            </div>
            <TextField
              label="Buscar por nombre o puntos"
              variant="outlined"
              value={searchText}
              onChange={handleSearchChange}
              fullWidth
              style={{ backgroundColor: "#ffffff" }}
            />
            {filteredPromotions.filter((promo) => promo.puntos).length ===
              0 && (
              <div
                style={{
                  backgroundColor: "#ffffff",
                  padding: "10px",
                  borderRadius: "5px",
                  margin: "10px 0",
                }}
              >
                <p style={{ color: "#f44336" }}>
                  No hay promociones disponibles para canjear en este momento.
                </p>
              </div>
            )}
            {filteredPromotions.filter((promo) => promo.puntos).length > 0 && (
              <ul className="list-group" style={{ marginTop: "revert" }}>
                {filteredPromotions
                  // .filter((promo) => promo.puntos < id.wallet)
                  .filter((promo) => promo.puntos)
                  .map((promo) => (
                    <li
                      key={promo.id}
                      className="list-group-item"
                      style={{ padding: "4px 8px" }}
                    >
                      <div
                        className="promo-container"
                        style={{ display: "flex" }}
                      >
                        <div
                          className="promo-text"
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <h6>
                            {(promo.puntos / puntos).toFixed(2)} P -{" "}
                            {promo.nombre}
                          </h6>
                        </div>
                        <div
                          className="promo-button"
                          style={{ marginLeft: "auto" }}
                        >
                          {sumaPuntos / puntos >= promo.puntos / puntos ? (
                            <Button
                              variant="text"
                              onClick={() =>
                                handleCanjearClick(JSON.stringify(promo))
                              }
                              // onClick={() =>
                              //   console.log(
                              //     "Canjear promocion: ",
                              //     JSON.stringify(promo)
                              //   )
                              // }
                              style={{
                                padding: "0px",
                                textDecoration: "none",
                                color: "blue",
                              }}
                            >
                              Canjear
                            </Button>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default ModalCanjearPuntos;
