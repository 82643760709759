import React, { useState, useEffect } from "react";
import "./styles.css";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";

const CloseButton = styled(Button)({
  position: "absolute",
  top: "0px",
  right: "0px",
  background: "none",
  border: "none",
  cursor: "pointer",
  fontSize: "1rem",
  padding: "0px",
  borderRadius: "25px", // Hacer que el borde sea completamente redondo
});

function ModelFacebook({ isVisible, onClose }) {
  const [facebook] = useState(
    JSON.parse(localStorage.getItem("Facebook")) || ""
  );
  const [isFacebookValid, setIsFacebookValid] = useState(false);
  const [valueFacebook, setValueFacebook] = useState(
    facebook.tipo_social || ""
  );
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setIsFacebookValid(valueFacebook.trim() !== "");
  }, [valueFacebook]);

  if (!isVisible) return null;

  const handleFacebookChange = (event) => {
    const facebookNew = event.target.value;
    let estado = "1";
    const newFacebook = {
      id: facebook.id,
      text_label: "facebook",
      tipo_social: facebookNew,
      estado: estado,
    };
    localStorage.setItem("Facebook", JSON.stringify(newFacebook));
    window.dispatchEvent(new Event("storage"));
    setValueFacebook(newFacebook.tipo_social);
  };

  const isValidFacebook = (facebook) => {
    // Expresión regular para verificar si la cadena sigue el formato de una URL de Facebook
    const facebookRegex = /^https:\/\/(www\.)?facebook\.com\/.+$/;
    return facebookRegex.test(facebook.trim());
  };

  const handleSaveClick = () => {
    if (isFacebookValid) {
      if (isValidFacebook(valueFacebook)) {
        setErrorMessage("");
        onClose();
      } else {
        setErrorMessage(
          "Facebook incorrecto. Ingrese una dirección de cuenta válida."
        );
      }
    } else {
      setErrorMessage("Ingrese su cuenta de Facebook para continuar.");
    }
  };

  const handleCancel = () => {
    localStorage.removeItem("Facebook");
    window.dispatchEvent(new Event("storage"));
    setValueFacebook("");
    setErrorMessage("");
    onClose();
  };

  return (
    <Backdrop
      sx={{
        color: "#000",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={isVisible}
    >
      <div>
        <div className="modal-container">
          <div className="modal-body">
            <CloseButton variant="outlined" onClick={onClose}>
              x
            </CloseButton>
            <div className="input-container">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Facebook
                </h5>
              </div>
              <div className="form-floating">
                <input
                  type="text"
                  className="input-register my-3"
                  name="facebook"
                  placeholder=""
                  value={valueFacebook}
                  onChange={handleFacebookChange}
                />
              </div>
              <div className="example" style={{ textAlign: "right" }}>
                Ejemplo:{" "}
                <span style={{ color: "blue" }}>
                  https://www.facebook.com/username
                </span>
              </div>
              {errorMessage && (
                <div className="error-message">{errorMessage}</div>
              )}
              <div className="linea-botones">
                <button onClick={handleCancel} className="cancel-button">
                  Vaciar
                </button>
                <button
                  type="button"
                  onClick={handleSaveClick}
                  // disabled={!isFacebookValid}
                  className="submit-button"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
}

export default ModelFacebook;
