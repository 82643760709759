import React, { useState, useEffect } from "react";
import "./styles.css";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";

const CloseButton = styled(Button)({
  position: "absolute",
  top: "0px",
  right: "0px",
  background: "none",
  border: "none",
  cursor: "pointer",
  fontSize: "1rem",
  padding: "0px",
  borderRadius: "25px", // Hacer que el borde sea completamente redondo
});

function ModelEmail({ isVisible, onClose }) {
  const [mail, setMail] = useState(localStorage.getItem("Mail") || "");
  const [isMailValid, setIsMailValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setIsMailValid(!!mail.trim());
  }, [mail]);

  if (!isVisible) return null;

  const handleMailChange = (event) => {
    const newMail = event.target.value;
    localStorage.setItem("Mail", newMail);
    window.dispatchEvent(new Event("storage"));
    setMail(newMail);
  };

  const handelClose = (e) => {
    if (e.target.id === "close") onClose();
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSaveClick = () => {
    if (isMailValid) {
      if (validateEmail(mail)) {
        setErrorMessage("");
        onClose();
      } else {
        setErrorMessage("Correo incorrecto");
      }
    } else {
      setErrorMessage("Ingrese su correo para continuar.");
    }
  };

  const handleCancel = () => {
    // localStorage.removeItem("Mail");
    localStorage.setItem("Mail", "");
    window.dispatchEvent(new Event("storage"));
    setMail("");
    setErrorMessage("");
    onClose();
  };

  return (
    <Backdrop
      sx={{
        color: "#000",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={isVisible}
    >
      <div className="modal-container">
        <div className="modal-body">
          <CloseButton variant="outlined" onClick={onClose}>
            x
          </CloseButton>
          <div className="input-container">
            <div className="modal-header">
              <h5>Email</h5>
            </div>
            <div className="form-floating">
              <input
                type="text"
                value={mail}
                name="mail"
                onChange={handleMailChange}
                className="input-register my-3"
                placeholder=""
              />
            </div>
            <div className="example" style={{ textAlign: "right" }}>
              Ejemplo: <span style={{ color: "blue" }}>correo@ejemplo.com</span>
            </div>
            {errorMessage && (
              <div className="error-message">{errorMessage}</div>
            )}
            <div className="linea-botones">
              <button onClick={handleCancel} className="cancel-button">
                Vaciar
              </button>
              <button
                type="button"
                onClick={handleSaveClick}
                // disabled={!isMailValid}
                className="submit-button"
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
}

export default ModelEmail;
