import React, { useEffect, useRef, useState } from 'react'
import { SimpleCard } from '../../compontents/SimpleCard'
import { Alert } from '@mui/lab';
import { AccesosTableModal } from './AccesosTableModal';

const ListaAccesosUsuario = ( {id} ) => {

  const [page, setPage] = useState(0);
  //TODO: Cambiar en el store el total de registros
  // const [totalRegistros, setTotalRegistros] = useState(1);
  const [accesosUser, setAccesosUser] = useState([]);

  let pages = [];
  const pagesRef = useRef(pages);

  // useEffect(() => {

  //   if (!pagesRef.current.includes(page)) {
  //     pagesRef.current.push(page)
  //     dispatch(startGetAccesosUser(id,page))
  //     // setSetEntidades(entidades.concat(entidadesComerciales))
  //   }

  // }, [page])



  const getPage = (page) => {
    setPage(page)
    //console.log('Page del padre: ', page)
  }

  return (
    <SimpleCard title={'Accesos'}>
      {(accesosUser === undefined)
        ? <Alert severity="info">No hay registro de Accesos.</Alert>
        : <AccesosTableModal
          listaEntidades={accesosUser}
          getPage={getPage}
          // totalRegs={totalRegsAccesosUser}
        />}



    </SimpleCard>
  )
}

export default ListaAccesosUsuario