import React, { useState, useEffect } from "react";

import styles from "../../../Register/RegisterView.module.css";
import FormEdit from "./FormEdit";
import Tarjeta from "../../../Presentasion/Tarjeta";
import axios from "axios";
import perfil from "../../../../assets/img/onlytap.jpg";
import portada from "../../../../assets/img/fondo.png";

const CardEdit = () => {
  const baseUrl = process.env.REACT_APP_BACKEND_URL;
  const tarjetaId = JSON.parse(localStorage.getItem("Tarjeta")).id;
  const [info, setInfo] = React.useState(null);

  const fetchDatos = async () => {
    try {
      const { data } = await axios.post(
        baseUrl + `usuario/tarjeta/ver/${tarjetaId}`,
        null // aquí debe ir el body, pero en este caso no es necesario enviar datos adicionales
      );

      setInfo(data.data);
      localStorage.setItem("Tarjeta", JSON.stringify(data.data));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDatos();
  }, [tarjetaId]);

  useEffect(() => {
    // Escuchar los cambios de local storage
    window.addEventListener("storage", (event) => {
      // Obtener el nuevo contenido de local storage
      const newData = event.currentTarget.localStorage;
      setInfo({
        ...info,
        img_perfil: newData.FotoPerfil || perfil,
        img_portada: newData.FotoPortada || portada,
        nombre: newData.Nombre || "Nombre Usuario",
        profesion: newData.Profesion || "Profesión Usuario",
        empresa: newData.Descripcion || "Descripción del Usuario",
        telefono: newData.Phone || "0987654321",
        direccion: newData.Ubicacion || "Dirección del Usuario",
        correo: newData.Mail || "ejemplo@correo.com",
        sitio_web: newData.Url || "https://www.ejemplo.com",
        titulo: newData.Titulo || "Título sitio web",
        whatsapp: newData.Whatsapp || "0987654321",
        telegram: newData.Telegram || "0987654321",
      });
    });
  }, []);

  return (
    <div>
      <section id={styles.division}>
        <div className={`${styles.tarjeta} overflow-auto d-none d-lg-flex`}>
          {info !== null && <Tarjeta data={info} />}
        </div>
        <div className="form overflow-auto">
          <FormEdit></FormEdit>
        </div>
      </section>
    </div>
  );
};

export default CardEdit;
