import React, { useEffect, useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "../../styles/card.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Tarjeta from "./Tarjeta";
import { useNavigate } from "react-router-dom";
// import animationData from "../../assets/img/lotties/animation_llbn6fo4.json";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const baseUrl = process.env.REACT_APP_BACKEND_URL;

export default function ActionAreaCardPresentacion() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [datos, setDatos] = useState(null);
  const [abrir, setAbrir] = React.useState(false);
  // const [urlWhatsapp, setUrlWhatsapp] = useState("");
  // const [id, setId] = useState("");
  // const phoneNumber = "593987441522";
  // const defaultMessag =
  //   "Me gustaría obtener mi tarjeta digital. He conseguido este código de descuento: " +
  //   id;
  // const openWhatsAp = () => {
  //   const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
  //     defaultMessag
  //   )}`;
  //   setUrlWhatsapp(url);
  //   if (datos) {
  //     console.log(datos);
  //     const clicReferidos = parseInt(datos.clic_referidos, 10);
  //     const nuevoClicReferidos = clicReferidos + 1;
  //     axios.post(baseUrl + `usuario/tarjeta/actualizar/${id}`, {
  //       clic_referidos: nuevoClicReferidos,
  //     });
  //   }
  //   window.open(url, "_blank");
  // };
  async function fetchDatos() {
    try {
      setAbrir(true);
      const url = window.location.pathname;
      const value = url.split("/Presentacion/")[1];
      localStorage.setItem("tarjetaID", value);
      // setId(value);

      const { data } = await axios.post(
        baseUrl + `usuario/tarjeta/ver/${value}`,
        null
      );
      console.log(data);

      if (data.data && data.data.usuario_id) {
        setDatos(data.data);
        // obtener las veces que se visito la tarjeta desde BD
        const clicsRealizados = parseInt(data.data.clics_realizados, 10);
        // aumentar el contador en 1 por cada visita realizada a la presentacion
        const nuevoClicsRealizados = clicsRealizados + 1;
        // almacenar el contador para mostrar en pantalla
        await axios.post(baseUrl + `usuario/tarjeta/actualizar/${value}`, {
          clics_realizados: nuevoClicsRealizados,
        });
      } else {
        setDatos(undefined);
        navigate("/OnlyTap/Register");
      }
      // console.log(datos);
      setAbrir(false);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchDatos();
  }, []);

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={abrir}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {datos !== null && datos !== undefined ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: isSmallScreen ? "100%" : "auto",
            marginTop: "0px",
          }}
        >
          <Tarjeta data={datos} />
        </div>
      ) : datos === null ? (
        <div></div>
      ) : (
        <div>
          <h1>Usuario no encontrado</h1>
          <p>
            Lo sentimos, el usuario que estás buscando no ha sido encontrado en
            la tarjeta consultada.
          </p>
        </div>
      )}
    </>
  );
}
