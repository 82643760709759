import React, { useEffect, useRef, useState } from "react";
import { SimpleCard } from "../../../compontents/SimpleCard";
import { EntidadComercialTable } from "../../Negocios/EntidadComercialTable";
import { Alert } from "@mui/lab";
import Form from "./negociosForm";
import Button from "@mui/material/Button";
import axios from "axios";
import { ModalConfigurarPuntos } from "../../Dashboard/Components/modals/index";
import { Card } from "@mui/material";
import { Box, styled } from "@mui/system";

const ListaEntidadComercial = () => {
  const baseUrl = process.env.REACT_APP_BACKEND_URL;
  const [entidadesComerciales, setEntidadesComerciales] = useState([]);
  const [totalRegs, setTotalRegs] = useState(1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const api = process.env.REACT_APP_BACKEND_URL;
  const [open, setOpen] = React.useState(false);
  const [tipoNegocio, setTipoNegocio] = useState([]);
  const [rol, setRol] = React.useState("");
  const [abrirModal, setAbrirModal] = useState(false);
  const [configuracion, setConfiguracion] = useState([]);

  useEffect(() => {
    const { authorisation } = JSON.parse(localStorage.getItem("user"));
    setRol(authorisation?.data?.rol);
  }, []);

  const handleAbrir = () => {
    setOpen(!open);
  };

  const verModal = () => {
    setAbrirModal(!abrirModal);
  };

  useEffect(() => {
    const fetchEntidadesComerciales = async () => {
      try {
        const response = await fetch(
          `${api}comercio/listar?page=${page}&limit=${rowsPerPage}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Ha ocurrido un error");
        }
        const data = await response.json();
        setEntidadesComerciales(data.data.data);
        localStorage.setItem(
          "entidadesComerciales",
          JSON.stringify(data.data.data)
        );
        setTotalRegs(data.data.total);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };
    fetchEntidadesComerciales();
  }, [page, rowsPerPage]);
  //TODO: Cambiar en el store el total de registros
  // const [totalRegistros, setTotalRegistros] = useState(1);

  let pages = [];
  const pagesRef = useRef(pages);

  useEffect(() => {
    if (!pagesRef.current.includes(page)) {
      pagesRef.current.push(page);
      // setSetEntidades(entidades.concat(entidadesComerciales))
    }
  }, [page]);

  const getPage = (page) => {
    setPage(page);
    //console.log('Page del padre: ', page)
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        };
        const { data } = await axios.post(
          baseUrl + "tipo/comercio/listar",
          null,
          config
        );
        // console.log(data.data);
        const negocios = data.data;
        setTipoNegocio(negocios);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(baseUrl + "usuario/listar");
        localStorage.setItem("usuarios", JSON.stringify(response.data.data));
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchConfiguracion = async () => {
      try {
        const token = localStorage.getItem("token");
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        };
        const { data } = await axios.post(
          baseUrl + "configuracion/puntos/listar",
          null,
          config
        );
        console.log(data);
        setConfiguracion(data.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchConfiguracion();
  }, []);

  const actualizar = (newConfiguracion) => {
    console.log(configuracion);
    if (configuracion.length === 0) {
      setConfiguracion([newConfiguracion.data]);
    } else {
      const nuevoComponente = newConfiguracion.data;

      const nuevaConfiguracion = configuracion.map((item, index) => {
        // Si es el primer componente, lo actualizamos
        if (item.comercio_id === null) {
          return nuevoComponente;
        }
        // Si no es el primer componente, lo dejamos igual
        return item;
      });
      // Usar setConfiguracion para actualizar el estado
      console.log(nuevaConfiguracion);
      setConfiguracion(nuevaConfiguracion);
    }
  };

  const actualizarListado = (comercio) => {
    setOpen(false);
    console.log(comercio.data);
    console.log(
      setEntidadesComerciales([
        ...entidadesComerciales,
        comercio.data[0].comercio,
      ])
    );
  };

  const CardRoot = styled(Card)(() => ({
    height: "100%",
    padding: "9px",
  }));

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <CardRoot elevation={5}>
          <span
            style={{
              backgroundColor: "greenyellow",
              borderRadius: "50%",
              padding: "5px 9px",
              fontWeight: "bold",
              color: "greenyellow",
              marginRight: "6px",
            }}
          >
            *
          </span>
          {configuracion[0]?.cantidad_puntos && (
            <span>
              Puntos globales: {configuracion[0]?.cantidad_puntos} P = 1$
            </span>
          )}
        </CardRoot>
        {rol === "ADMIN" && (
          <>
            <Button
              onClick={verModal}
              variant="contained"
              sx={{
                width: "30%",
                marginBottom: "10px",
                margin: "9px 0px",
              }}
            >
              Configurar puntos globales
            </Button>
            <Button
              onClick={handleAbrir}
              variant="contained"
              sx={{
                width: "30%",
                marginBottom: "10px",
                margin: "9px 0px",
              }}
            >
              Crear Negocio
            </Button>
          </>
        )}
      </div>
      <ModalConfigurarPuntos
        isVisible={abrirModal}
        onClose={verModal}
        actualizar={actualizar}
      />
      {open && (
        <CardRoot
          elevation={5}
          sx={{
            marginBottom: "10px",
          }}
        >
          <Form
            tipoNegocio={tipoNegocio}
            actualizarListado={actualizarListado}
          />
        </CardRoot>
      )}
      <SimpleCard title={"Entidades comerciales"}>
        {entidadesComerciales.length === 0 ? (
          <Alert severity="info">
            No se han registrado entidades comerciales
          </Alert>
        ) : (
          <EntidadComercialTable
            listaEntidades={entidadesComerciales}
            getPage={getPage}
            totalRegs={totalRegs}
          />
        )}
      </SimpleCard>
    </>
  );
};

export default ListaEntidadComercial;
