import React, { useState, useEffect } from "react";
import "react-phone-number-input/style.css";
import "./styles.css";
import TextField from "@mui/material/TextField";
import axios from "axios";
import Swal from "sweetalert2";
import { FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";

const StyledTable = styled(Table)`
  background-color: white;
`;

const StyledTableHeader = styled(TableCell)`
  background-color: lightgray;
  font-weight: bold;
`;

const baseUrl = process.env.REACT_APP_BACKEND_URL;

const ModalVerComercios = ({ isVisible, onClose, tarjeta }) => {
  const [paginaActual, setPaginaActual] = React.useState(1);
  const elementosPorPagina = 5;
  const numerosPaginaMostrados = 4;

  const irAPagina = (numeroPagina) => {
    setPaginaActual(numeroPagina);
  };

  const irAPaginaAnterior = () => {
    if (paginaActual > 1) {
      setPaginaActual(paginaActual - 1);
    }
  };

  const irAPaginaSiguiente = () => {
    const totalPages = Math.ceil(tarjeta.length / elementosPorPagina);
    if (paginaActual < totalPages) {
      setPaginaActual(paginaActual + 1);
    }
  };
  if (!isVisible) return null;
  const totalPages = Math.ceil(tarjeta.length / elementosPorPagina);
  const indiceInicioNumerosPagina = Math.max(paginaActual - 1, 0);
  const numerosPagina = Array.from(
    { length: numerosPaginaMostrados },
    (_, index) => index + indiceInicioNumerosPagina + 1
  );

  const formatDate = (dateString) => {
    const date = new Date(dateString); // Crear una instancia de fecha a partir de la cadena
    const day = date.getDate();
    const month = date.getMonth() + 1; // Sumamos 1 porque los meses van de 0 a 11
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    // Agregar ceros a la izquierda si el valor es menor que 10
    const formattedDay = day < 10 ? "0" + day : day;
    const formattedMonth = month < 10 ? "0" + month : month;
    const formattedHours = hours < 10 ? "0" + hours : hours;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;

    // Formatear la fecha y hora en el formato deseado
    return `${formattedDay}/${formattedMonth}/${year} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={isVisible}
    >
      <div className="modal-container">
        <div className="model-body">
          <div className="modal-header">
            <h5>Puntos por entidad comercial</h5>
            <Button onClick={onClose} className="close-button">
              X
            </Button>
          </div>
          <div>
            <div>
              <StyledTable>
                <TableHead>
                  <TableRow>
                    <StyledTableHeader>Comercio</StyledTableHeader>
                    <StyledTableHeader>Rol</StyledTableHeader>
                    <StyledTableHeader>Puntos</StyledTableHeader>
                    <StyledTableHeader>Fecha</StyledTableHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tarjeta.map((pago, index) => (
                    <TableRow key={pago.id}>
                      {/* Entidad comercial */}
                      <TableCell>{pago.comercio.nombre_comercial}</TableCell>
                      {/* Rol del usuario en esa entidad */}
                      <TableCell>{pago.rol}</TableCell>
                      {/* Cantidad de puntos*/}
                      <TableCell>100</TableCell>
                      <TableCell>{formatDate(pago.created_at)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </StyledTable>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginTop: "10px",
                }}
              >
                <Button
                  disabled={paginaActual === 1}
                  onClick={irAPaginaAnterior}
                >
                  <KeyboardArrowLeft />
                </Button>
                <div style={{ display: "flex" }}>
                  {numerosPagina.map((numero) => (
                    <Button
                      key={numero}
                      variant={
                        numero === paginaActual ? "contained" : "outlined"
                      }
                      onClick={() => irAPagina(numero)}
                    >
                      {numero}
                    </Button>
                  ))}
                </div>
                <Button
                  disabled={paginaActual === totalPages}
                  onClick={irAPaginaSiguiente}
                >
                  <KeyboardArrowRight />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default ModalVerComercios;
