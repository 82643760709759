import React, { useState, useRef, useEffect } from "react";
import { Chart } from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ShareIcon from "@mui/icons-material/Share";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ListItemIcon from "@mui/material/ListItemIcon";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import TelegramIcon from "@mui/icons-material/Telegram";
import Swal from "sweetalert2";
import Typography from "@mui/material/Typography";
import { ModalFormularioBancario } from "./modals/index";

const MyButton = styled(Button)({
  backgroundColor: "#c9c9c9", // color de fondo transparente
  color: "#000000", // color de texto
  borderRadius: " 0 0 30px 30px", // borde redondeado// sombra
  "&:hover": {
    backgroundColor: "#9c9c9c", // color de fondo al pasar el mouse
    color: "white", // color de texto al pasar el mouse
  },
  fontSize: "1.2rem", // tamaño de fuente
});
const Reports = () => {
  const [info, setInfo] = useState(null);
  const [clicsRealizados, setClicsRealizados] = useState(0);
  const [clicsGuardar, setClicsGuardar] = useState(0);
  const [clicsCorreo, setClicsCorreo] = useState(0);
  const [clicsSitioWeb, setClicsSitioWeb] = useState(0);
  const [clicsReferidos, setClicsReferidos] = useState(0);
  const [clicsCobrar, setClicsCobrar] = useState(0);
  const [clicsPagados, setClicsPagados] = useState(0);
  const [efectivoReviews, setEfectivoReviews] = useState(0);
  const [efectivoMenu, setEfectivoMenu] = useState(0);
  const [sociales, setSociales] = useState([]);
  const chartRef = useRef(null);
  const chartRef2 = useRef(null);
  const grayscaleColors = [
    "rgba(102, 102, 102, 0.60)",
    "rgba(136, 136, 136, 0.60)",
    "rgba(144, 144, 144, 0.60)",
    "rgba(168, 168, 168, 0.60)",
  ];
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [shareUrl, setShareUrl] = React.useState("");
  const front = "https://onlytap.proatek.com/OnlyTap/Redirect/Referido/";
  const [ShowModalActivar, setShowModalActivar] = useState(false);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleCopyUrl = () => {
    navigator.clipboard.writeText(shareUrl); // Copia el enlace al portapapeles
    handleMenuClose();
    Swal.fire("Enlace copiado al portapapeles", "", "success");
  };
  const defaultMessage = `Si deseas obtener tu propia tarjeta de presentación digital con un descuento de 5$, puedes realizar la compra a través del siguiente enlace: ${shareUrl}`;

  const handleShare = (platform) => {
    switch (platform) {
      case "WhatsApp":
        const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          defaultMessage
        )}`;
        window.open(whatsappUrl, "_blank");
        break;

      case "Facebook":
        const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          shareUrl
        )}`;
        window.open(facebookUrl, "_blank");
        break;

      case "Telegram":
        const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(
          defaultMessage
        )}`;
        window.open(telegramUrl, "_blank");
        break;

      default:
        break;
    }
  };

  const handleAbrir = (max, idList) => {
    // console.log(max, idList);
    setShowModalActivar(true);
  };

  useEffect(() => {
    const tarjeta = localStorage.getItem("Tarjeta");
    // console.log(tarjeta);
    setInfo(tarjeta);

    if (tarjeta !== undefined) {
      const parsedTarjeta = JSON.parse(tarjeta);
      setClicsRealizados(parseInt(parsedTarjeta.clics_realizados, 10));
      setClicsGuardar(parseInt(parsedTarjeta.clics_guardar, 10));
      setClicsCorreo(parseInt(parsedTarjeta.clics_correo, 10));
      setClicsSitioWeb(parseInt(parsedTarjeta.clics_sitio_web, 10));
      setClicsReferidos(parseInt(parsedTarjeta.clic_referidos, 10));
      setClicsPagados(parseInt(parsedTarjeta.clic_pagados, 10));
      setEfectivoMenu(
        parseInt(parsedTarjeta.efectivo_menu, 10) +
          parseInt(parsedTarjeta.efectivo_reviews, 10) * 10 +
          parseInt(parsedTarjeta.clic_cobrar, 10) * 5
      );
      const parsedSociales = parsedTarjeta.sociales_tarjeta.map((social) => {
        return {
          label: social.text_label,
          value: social.clics_realizados,
        };
      });
      setSociales(parsedSociales);
    }
    const tarjetaJson = JSON.parse(tarjeta);
    // console.log(tarjetaJson.id);
    setShareUrl(front + tarjetaJson.id);
  }, []);

  useEffect(() => {
    const maxClicsValue = Math.max(
      clicsRealizados,
      clicsGuardar,
      clicsCorreo,
      clicsSitioWeb
    );
    const maxClicsValueIndex = [
      clicsRealizados,
      clicsGuardar,
      clicsCorreo,
      clicsSitioWeb,
    ].indexOf(maxClicsValue);
    const highestValueColor = "#554CE5";

    const backgroundColors = [
      clicsRealizados === maxClicsValue
        ? highestValueColor
        : grayscaleColors[0], // Gris oscuro para el valor más alto
      clicsGuardar === maxClicsValue ? highestValueColor : grayscaleColors[1], // Gris medio
      clicsCorreo === maxClicsValue ? highestValueColor : grayscaleColors[2], // Gris claro
      clicsSitioWeb === maxClicsValue ? highestValueColor : grayscaleColors[3], // Gris muy claro
    ];

    if (chartRef.current) {
      chartRef.current.destroy();
    }

    const ctx = document.getElementById("clicsChart").getContext("2d");

    chartRef.current = new Chart(ctx, {
      type: "doughnut", // Cambiar el tipo de gráfico a doughnut
      data: {
        labels: ["Visitas", "Guardado", "Correos", "Sitio Web"],
        datasets: [
          {
            label: "Clics",
            data: [clicsRealizados, clicsGuardar, clicsCorreo, clicsSitioWeb],
            backgroundColor: backgroundColors,
            borderColor: (context) => {
              if (context.dataIndex === maxClicsValueIndex) {
                return highestValueColor;
              } else {
                return "white"; // Borde blanco para los demás componentes
              }
            },
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        cutout: "55%", // Controla el tamaño del agujero del centro (para hacerlo dona)
        plugins: {
          legend: {
            position: "bottom", // Cambia la posición de las etiquetas (top, bottom, left, right)
            labels: {
              color: "black", // Puedes cambiar el color aquí
              font: {
                size: 14, // Puedes cambiar el tamaño aquí
              },
            },
          },
          datalabels: {
            display: true,
            color: "black", // Color del texto
            font: {
              size: 14,
            },
            formatter: (value, context) => {
              // Puedes personalizar la forma en que se muestra cada etiqueta.
              // Por ejemplo, si solo quieres mostrar valores no nulos:
              return value !== null ? value : "";
            },
            anchor: "end", // Posición de la etiqueta (center, end, start)
            align: "end", // Alineación de la etiqueta (center, end, start)
          },
        },
      },
    });
  }, [clicsRealizados, clicsGuardar, clicsCorreo, clicsSitioWeb]);

  useEffect(() => {
    if (chartRef2.current) {
      chartRef2.current.destroy();
    }

    const ctx2 = document.getElementById("socialesChart").getContext("2d");

    chartRef2.current = new Chart(ctx2, {
      type: "bar",
      data: {
        labels: sociales.map((social) => social.label),
        datasets: [
          {
            label: "Clics Sociales",
            data: sociales.map((social) => social.value),
            backgroundColor: "#554CE5",
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          x: {
            ticks: {
              color: "black", // Puedes cambiar el color aquí
              font: {
                size: 14, // Puedes cambiar el tamaño aquí
              },
            },
          },
        },
        plugins: {
          datalabels: {
            anchor: "end",
            align: "end",
            color: "black",
            font: {
              weight: "bold",
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  }, [sociales]);

  const handleOpen = () => {
    // console.log("Abriendo", info);
    const { id } = JSON.parse(info);
    const { nombre } = JSON.parse(info);
    const phoneNumber = "593987441522";
    const defaultMessage = `!Hola! Me gustaría solicitar el pago de mi saldo acumulado. \nMi nombre es: ${nombre} \nMi número de tarjeta es: ${id}\nSaldo a cobrar: ${
      clicsCobrar.toFixed(2) * 5 ?? 0.0
    }$`;

    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      defaultMessage
    )}`;
    window.open(url, "_blank");
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div
            className="header"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3 style={{ fontWeight: "bold" }}>Reporteria</h3>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <div
            style={{
              // border: "1px solid #ccc", // Añade un borde alrededor del div
              borderRadius: "30px", // Ajusta el radio del borde si es necesario
              padding: "30px", // Agrega un espacio interno para el borde
              background: "#ffffff",
              display: "grid",
              gap: "20px",
            }}
          >
            <canvas
              id="clicsChart"
              width="100"
              height="100"
              style={{ color: "white" }}
            ></canvas>
            {/* Agrega una cuadrícula de fondo */}
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background:
                  "radial-gradient(ellipse at center, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.05) 100%)",
                backgroundImage:
                  "linear-gradient(to right, rgba(255, 255, 255, 0.05) 1px, transparent 1px), linear-gradient(to bottom, rgba(255, 255, 255, 0.05) 1px, transparent 1px)",
                backgroundSize: "20px 20px", // Tamaño de la cuadrícula
                pointerEvents: "none", // Evita que la cuadrícula interrumpa los eventos
              }}
            ></div>
            <div className="header" style={{ textAlign: "center" }}>
              <h5>Personas alcanzadas</h5>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <div
            style={{
              // border: "1px solid #ccc", // Añade un borde alrededor del div
              borderRadius: "30px", // Ajusta el radio del borde si es necesario
              padding: "30px", // Agrega un espacio interno para el borde
              background: "#ffffff",
              display: "grid",
              gap: "20px",
            }}
          >
            <canvas id="socialesChart" width="400" height="400"></canvas>
            <div className="header" style={{ textAlign: "center" }}>
              <h5>Alcance en redes sociales</h5>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <div
            style={{
              // border: "1px solid #ccc", // Añade un borde alrededor del div
              borderRadius: "30px", // Ajusta el radio del borde si es necesario
              padding: "30px", // Agrega un espacio interno para el borde
              background: "#ffffff",
              display: "grid",
              gap: "20px",
              fontWeight: "bold",
            }}
          >
            Wallet
            <h6>Nº de personas referidas: {clicsReferidos ?? 0}</h6>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="header" style={{ textAlign: "center" }}>
                  <span
                    style={{
                      borderRadius: "30px",
                      padding: "30px",
                      background: "#F5F5F5",
                      position: "relative",
                      display: "inline-block",
                    }}
                  >
                    Alcance:{" "}
                    {(clicsReferidos.toFixed(2) -
                      clicsPagados.toFixed(2) -
                      clicsCobrar.toFixed(2)) *
                      5 ?? 0.0}
                    $
                    <Tooltip
                      title={
                        <Typography style={{ fontSize: "15px" }}>
                          Posible monto que puedes cobrar si cada referido
                          compra una tarjeta
                        </Typography>
                      }
                      arrow
                    >
                      <button
                        style={{
                          background: "transparent",
                          color: "#554CE5",
                          cursor: "pointer",
                          position: "relative",
                          borderRadius: "50%",
                          padding: "1px 9px 1px 9px",
                          margin: "6px 0px 0px 6px",
                        }}
                      >
                        Detalles
                      </button>
                    </Tooltip>
                  </span>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="header" style={{ textAlign: "center" }}>
                  <span
                    style={{
                      borderRadius: "30px", // Ajusta el radio del borde si es necesario
                      padding: "30px", // Agrega un espacio interno para el borde
                      background: "#F5F5F5",
                      position: "relative",
                      display: "inline-block",
                    }}
                  >
                    Efectivo: {efectivoMenu.toFixed(2) ?? 0.0}$
                    <Tooltip
                      arrow
                      title={
                        <Typography style={{ fontSize: "15px" }}>
                          Monto total a cobrar por cada referido que compra una
                          tarjeta
                        </Typography>
                      }
                    >
                      <button
                        style={{
                          background: "transparent",
                          color: "#554CE5",
                          cursor: "pointer",
                          position: "relative",
                          borderRadius: "50%",
                          padding: "1px 9px 1px 9px",
                          // margin: "6px 0px 0px 6px",
                        }}
                      >
                        Detalles
                      </button>
                    </Tooltip>
                  </span>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Button
                  type="button"
                  onClick={() => handleAbrir()}
                  fullWidth
                  variant="contained"
                  sx={{ backgroundColor: "#554CE5" }}
                  disabled={efectivoMenu === 0}
                >
                  Cobrar
                </Button>

                <div>
                  <Button
                    type="button"
                    onClick={handleMenuOpen}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 1, mb: 1, backgroundColor: "#554CE5" }}
                  >
                    Referir
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem>
                      <TextField
                        label="Enlace"
                        value={shareUrl}
                        variant="outlined"
                        disabled={true}
                        fullWidth
                      />
                      <IconButton onClick={handleCopyUrl}>
                        <ContentCopyIcon />
                      </IconButton>
                    </MenuItem>
                    <hr />
                    <MenuItem onClick={() => handleShare("WhatsApp")}>
                      <ListItemIcon>
                        <WhatsAppIcon />
                      </ListItemIcon>
                      WhatsApp
                    </MenuItem>

                    <MenuItem onClick={() => handleShare("Facebook")}>
                      <ListItemIcon>
                        <FacebookIcon />
                      </ListItemIcon>
                      Facebook
                    </MenuItem>

                    <MenuItem onClick={() => handleShare("Telegram")}>
                      <ListItemIcon>
                        <TelegramIcon />
                      </ListItemIcon>
                      Telegram
                    </MenuItem>
                  </Menu>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <ModalFormularioBancario
          isVisible={ShowModalActivar}
          onClose={() => setShowModalActivar(false)}
        />
      </Grid>
    </div>
  );
};

export { Reports };
