import React from "react";
import {
  LinearProgress,
  Box,
  Button,
  Grid,
  styled,
  Badge,
  Avatar,
  Icon,
  Typography,
  Card,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MuiDialogActions from "@mui/material/DialogActions";
import { useState, useEffect } from "react";
import { SvgIcon } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import AsignarUsuarioModal from "./AsignarUsuarioModal";

const MedalIcon = ({ type }) => {
  switch (type) {
    case "Gold":
      return (
        <Avatar
          src="/assets/images/beer/gold.png"
          sx={{ cursor: "pointer", width: 100, height: 100 }}
        />
      );
    case "Silver":
      return (
        <Avatar
          src="/assets/images/beer/silver.png"
          sx={{ cursor: "pointer", width: 100, height: 100 }}
        />
      );
    case "Bronce":
      return (
        <Avatar
          src="/assets/images/beer/bronce.png"
          sx={{ cursor: "pointer", width: 100, height: 100 }}
        />
      );
    default:
      return null;
  }
};
const SmallEstadoActivo = styled("small")(({ activo }) => ({
  height: 30,
  width: "max-content",
  alignSelf: "center",
  fontSize: 20,
  fontWeight: "bold",
  color: activo ? "#84CDA4" : "#F16360",
  padding: "2px 8px",
  borderRadius: "4px",
  overflow: "hidden",
  background: activo ? "#DAF4E9" : "#FDEDED",
  boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)",
}));

const UserInfo = styled("div")(({ theme }) => ({
  paddingRight: "0",
  paddingLeft: "0",
  paddingTop: "0",
  paddingBottom: "0",
  textAlign: "center",
}));

const H5 = styled("h5")(({ theme }) => ({
  padding: "0px",
  color: "#053D6B",
  fontSize: "16px",
  fontWeight: "bold",
  marginTop: "0",
  marginBottom: "0",
}));

const Parrafo = styled("h5")(() => ({
  padding: "0px",
  color: "#242532",
  fontSize: "16px",
  fontWeight: "normal",
  marginTop: "10px",
  marginBottom: "10px",
}));

const Titulo = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#053D6B",
}));

const GridContainer = styled(Grid)(() => ({
  display: "flex",
  alignContent: "start",
  alignItems: "center",
  borderBottom: "2px solid #DCDCDC",
}));

export const CardDetalleUsuario = ({
  solicitud,
  tarjeta,
  handleClose,
  limpiarSensor,
}) => {
  const [estilo, setEstilo] = useState(null);
  const [data, setData] = useState(solicitud);
  const [target, setTarget] = useState(tarjeta);
  useEffect(() => {
    console.log(data, tarjeta);
  }, [data, tarjeta]);
  return (
    <Card sx={{ marginTop: 4, width: "100%" }}>
      <Titulo
        id="responsive-dialog-title"
        sx={{ background: data?.usuario ? "#053D6B" : "red" }}
      >
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
          <UserInfo>
            <Grid container sx={{ paddingRight: "30px", paddingLeft: "30px" }}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Parrafo
                    sx={{
                      color: !estilo ? "#ffffff" : "#000000",
                      fontSize: 25,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <>
                      {data.nombres} {data.apellidos}
                      {/* <AsignarUsuarioModal
                        usuario={data}
                        tarjeta={target.codigo_sensor}
                        handleClose={handleClose}
                        limpiarSensor={limpiarSensor}
                      /> */}
                    </>
                  </Parrafo>
                </Grid>
              </Grid>
            </Grid>
          </UserInfo>
        </Grid>
      </Titulo>

      <Grid container sx={{ paddingRight: "30px", paddingLeft: "30px" }}>
        <GridContainer container spacing={2}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <H5>Correo</H5>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Parrafo>{data?.correo}</Parrafo>
          </Grid>
        </GridContainer>

        <GridContainer container spacing={2}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <H5></H5>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Parrafo>
              {/* {data?.cupo_max} {data?.cupo_max ? 'Litros' : '-'} */}
            </Parrafo>
          </Grid>
        </GridContainer>

        <GridContainer container spacing={2}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <H5>Identificación</H5>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Parrafo>{data?.identificacion}</Parrafo>
          </Grid>
        </GridContainer>
      </Grid>
    </Card>
  );
};
