import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Table,
  TableBody,
  Box,
  Paper,
  TableContainer,
  Grid,
  Icon,
  Skeleton,
  IconButton,
  styled,
} from "@mui/material";
import { esES } from "@mui/material/locale";
import { EnhancedTableHead } from "../../compontents/EnhancedTableHead";
import { PaginatorServer } from "../../compontents/Paginator";
import { usePagination } from "../../compontents/usePagination";
import {
  getComparator,
  StyledTableCell,
  StyledTableRow,
  Container,
  stableSort,
} from "../../compontents/TableFunctions";
import { useNavigate, Link } from "react-router-dom";

/**
 * Cabecera de la tabla
 */

const headCells = [
  {
    id: "ultimoacceso",
    numeric: false,
    disablePadding: false,
    label: "Último acceso",
  },
  {
    id: "ip",
    numeric: false,
    disablePadding: false,
    label: "Ip de acceso",
  },
];

const theme = createTheme(esES);

/**
 *
 * @param  listaEntidades los nuevos registros que vienen de 10en 10 de la paginación
 * @param  getPage función para obtener la pagina que se consulta
 * @param  totalRegs el total de registros que trae la consulta a la api
 * @returns  el componente tabla
 */

export const AccesosTableModal = ({
  listaEntidades = [],
  getPage,
  totalRegs,
}) => {

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");

  const { page, handleChangePage, loading, registros } = usePagination(
    listaEntidades,
    listaEntidades.length,
    getPage
  );

  // console.log("lista", listaEntidades)

  const coloresRol = {
    MASTER: "#053D6B",
    SUPERVISOR: "#667AD9",
    AUDITOR: "#DA4040",
    FINANCIERO: "#1F858A",
    VENDEDOR: "#2BAE5A",
  };

  const handleRequestSort = (event, property) => {
    // console.log('handleRequestSort called')
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    // setPage(0);
  };

  const skeletonArray = Array(10).fill("");

  const setFecha = (fecha) => {
    const fechaActual = new Date(fecha);
    return fechaActual.toLocaleString();
  };

  return (
    <>
      <Container>
        <Grid container>
          <Grid item xs={12} md={12}>
            <Box with="100%" overflow="auto">
              <ThemeProvider theme={theme}>
                <Paper sx={{ with: "100%", overflow: "scroll" }}>
                  <TableContainer sx={{ maxHeight: "100%" }}>
                    <Table
                      sx={{ minWidth: 500 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={registros.length}
                        headCells={headCells}
                      />

                      <TableBody>
                        {loading &&
                          skeletonArray.map((item, index) => (
                            <StyledTableRow key={index}>
                              {headCells.map((head) => (
                                <StyledTableCell key={head.id}>
                                  <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={20}
                                  />
                                </StyledTableCell>
                              ))}
                            </StyledTableRow>
                          ))}
                        {!loading &&
                          stableSort(registros, getComparator(order, orderBy))
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row, i) => (
                              <StyledTableRow key={row.id}>
                                <StyledTableCell align="left">
                                  {setFecha(row.fecha)}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {row.ip_acceso}
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>

                <PaginatorServer
                  xs={12}
                  md={12}
                  rowsPerPage={rowsPerPage}
                  handleChangePage={handleChangePage}
                  page={page}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  length={totalRegs}
                />
              </ThemeProvider>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
