import React, { useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import "./styles.css";
import TextField from "@mui/material/TextField";
import axios from "axios";
import Swal from "sweetalert2";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";

const baseUrl = process.env.REACT_APP_BACKEND_URL;

const ModalAsignarComercio = ({
  isVisible,
  onClose,
  tarjeta,
  actualizarPago,
  role,
  negocios,
}) => {
  const [informacion_bancaria, setInformacion_bancaria] = useState("");
  const [valor, setValor] = useState("");
  const [entidades, setEntidades] = useState([]);
  const [user, setUser] = useState("");
  const [rol, setRol] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [rolesPermitidos, setRolesPermitidos] = useState([]);

  useEffect(() => {
    if (negocios) {
      console.log(negocios);
      setEntidades(negocios);
    }
    const { authorisation } = JSON.parse(localStorage.getItem("user"));
    setUser(authorisation.data.id);
  }, [isVisible]);

  useEffect(() => {
    console.log(role);
    const rolesPermitidos = {
      ADMIN: [
        "PROPIETARIO",
        "GERENTE",
        "CAJERO",
        "MESERO",
        "COCINERO",
        "USUARIO",
      ],
      PROPIETARIO: ["GERENTE", "CAJERO", "MESERO", "COCINERO"],
      GERENTE: ["CAJERO", "MESERO", "COCINERO"],
      CAJERO: ["MESERO", "COCINERO"],
    };

    setRolesPermitidos(rolesPermitidos[role]);
  }, [role]);

  if (!isVisible) return null;

  const handleSaveClick = async () => {
    try {
      const objetoJSON = {
        usuario_id: tarjeta.id,
        comercio_id: informacion_bancaria,
        rol: rol ? rol : tarjeta.rol,
        estado: 1,
        registrado_por: user,
      };

      console.log(objetoJSON);

      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      };

      const pago = await axios.post(
        baseUrl + `usuario/entidad/crear`,
        JSON.stringify(objetoJSON),
        { headers }
      );
      if (pago.status === 201) {
        console.log("Usuario Asignado con éxito", pago);
        Swal.fire({
          icon: "success",
          title: "Usuario Asignado con éxito",
          text: "",
          confirmButtonText: "Aceptar",
        });
        onClose();
      }
    } catch (error) {
      console.error("Error asignando el usuario :", error);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setRol(value);
    if (errorMessage) setErrorMessage(""); // Limpiar el mensaje de error al modificar cualquier campo
  };

  const handleCancel = () => {
    window.dispatchEvent(new Event("storage"));
    onClose();
  };

  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={isVisible}
    >
      <div className="modal-container">
        <div className="modal-body">
          <div className="input-container">
            <div className="modal-header">
              <h5>
                <b>Seleccione el nogocio para asignar al usuario</b>
              </h5>
              <Button onClick={onClose} className="close-button">
                X
              </Button>
            </div>
            <div className="col">
              <br />
              <select
                name="negocio"
                value={informacion_bancaria}
                onChange={(e) => setInformacion_bancaria(e.target.value)}
                className="input-register"
                style={{
                  width: "100%",
                  borderRadius: "10px",
                  backgroundColor: "#F5F5F5",
                  padding: "10px",
                }}
                required
              >
                <option value="">Seleccione una entidad</option>
                {entidades.map((entidad) => (
                  <option key={entidad.id} value={entidad.id}>
                    {entidad.nombre_comercial}
                  </option>
                ))}
              </select>
            </div>
            <div className="col">
              <br />
              <select
                name="rol"
                value={rol}
                onChange={handleChange}
                className="input-register"
                style={{
                  width: "100%",
                  borderRadius: "10px",
                  backgroundColor: "#F5F5F5",
                  padding: "10px",
                }}
                required
              >
                <option value="">Seleccione un rol</option>
                {rolesPermitidos.map((rolPermitido) => (
                  <option key={rolPermitido} value={rolPermitido}>
                    {rolPermitido}
                  </option>
                ))}
              </select>
            </div>
            <br />
            <div className="linea-botones">
              <button onClick={handleCancel} className="cancel-button">
                Cancelar
              </button>
              <button
                type="button"
                onClick={handleSaveClick}
                // disabled={estado}
                className="submit-button"
              >
                Asignar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default ModalAsignarComercio;
