import React, { useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import "./styles.css";
import TextField from "@mui/material/TextField";
import axios from "axios";
import Swal from "sweetalert2";
import Grid from "@mui/material/Grid";
import { FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";

const baseUrl = process.env.REACT_APP_BACKEND_URL;

const ModalFormularioBancario = ({ isVisible, onClose }) => {
  const [entidad, setEntidad] = useState("pichincha");
  const [tipoCuenta, setTipoCuenta] = useState("ahorros");
  const [identificacion, setIdentificacion] = useState("");
  const [numeroCuenta, setNumeroCuenta] = useState("");
  const [errorMessage, setErrorMessage] = useState([]);
  const [id, setId] = useState(localStorage.getItem("tarjetaID"));

  if (!isVisible) return null;

  const handleSaveClick = async () => {
    try {
      const objetoJSON = {
        entidad,
        tipoCuenta,
        identificacion,
        numeroCuenta,
      };

      console.log(objetoJSON, id);

      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      };

      const response = await axios.post(
        baseUrl + `usuario/tarjeta/actualizar/${id}`,
        { informacion_bancaria: objetoJSON },
        { headers }
      );
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Solicitud ingresada",
          text: "En el transcurso de 24 a 48 horas se le notificará el estado de su solicitud",
          confirmButtonText: "Aceptar",
        });
        onClose();
      }
    } catch (error) {
      console.error("Error al registrar la información bancaria:", error);
    }
  };

  const handleIdentificacionChange = (event) => {
    const newIdentificacion = event.target.value;
    if (newIdentificacion !== undefined) {
      setIdentificacion(newIdentificacion);
    }
  };

  const handleNumeroCuentaChange = (event) => {
    const newNumeroCuenta = event.target.value;
    if (newNumeroCuenta !== undefined) {
      setNumeroCuenta(newNumeroCuenta);
    }
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Backdrop
      sx={{
        color: "#000",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={isVisible}
    >
      <div className="modal-container">
        <div className="modal-body">
          <div className="input-container">
            <div className="modal-header">
              <h6>
                Proporcione su información bancaria para recibir sus ganancias.
              </h6>
            </div>
            <Grid container spacing={0}>
              <Grid
                item
                xs={6}
                style={{
                  justifyContent: "flex-end",
                  paddingTop: "0px",
                }}
              >
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="pichincha"
                  name="radio-buttons-group"
                >
                  <b>Entidad financiera</b>
                  <FormControlLabel
                    value="pichincha"
                    control={<Radio />}
                    label="Pichincha"
                    onChange={(event) => setEntidad(event.target.value)}
                  />
                  <FormControlLabel
                    value="produbanco"
                    control={<Radio />}
                    label="Produbanco"
                    onChange={(event) => setEntidad(event.target.value)}
                  />
                </RadioGroup>
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  justifyContent: "flex-end",
                  paddingTop: "0px",
                }}
              >
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="ahorros"
                  name="radio-buttons-group"
                >
                  <b>Tipo de cuenta</b>
                  <FormControlLabel
                    value="ahorros"
                    control={<Radio />}
                    label="Ahorros"
                    onChange={(event) => setTipoCuenta(event.target.value)}
                  />
                  <FormControlLabel
                    value="corriente"
                    control={<Radio />}
                    label="Corriente"
                    onChange={(event) => setTipoCuenta(event.target.value)}
                  />
                </RadioGroup>
              </Grid>
            </Grid>
            <div className="form-floating">
              <input
                type="number"
                name="identificacion"
                value={identificacion}
                onChange={handleIdentificacionChange}
                className="input-register my-1"
                placeholder="Identificación"
              />
            </div>
            <div className="form-floating">
              <input
                type="number"
                name="numeroCuenta"
                value={numeroCuenta}
                onChange={handleNumeroCuentaChange}
                className="input-register my-1"
                placeholder="Número de cuenta"
              />
            </div>

            <div className="linea-botones">
              <button onClick={handleCancel} className="cancel-button">
                Cancelar
              </button>
              <button
                type="submit"
                onClick={handleSaveClick}
                // disabled={estado}
                className="submit-button"
              >
                Aceptar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default ModalFormularioBancario;
