import React, { useEffect } from "react";
import styles from "./RegisterView.module.css";
import StepForm from "./Components/MultiStepFromRegister";
import Tarjeta from "../Presentasion/Tarjeta";
import perfil from "../../assets/img/onlytap.jpg";
import portada from "../../assets/img/fondo.png";

function RegisterView() {
  const [tarjetaAux, setTarjetaAux] = React.useState({
    id: "TarjetaId",
    usuario_id: "UsuarioId",
    comercio_id: "ComercioId",
    estado: 0,
    img_perfil: perfil,
    img_portada: portada,
    nombre: "Nombre Usuario",
    profesion: "Profesión Usuario",
    empresa: "Descripción del Usuario",
    acreditaciones: null,
    telefono: "0987654321",
    direccion: "Dirección del Usuario",
    correo: "ejemplo@correo.com",
    sitio_web: "https://www.ejemplo.com",
    wallet: null,
    clics_realizados: 0,
    clics_guardar: 0,
    clics_correo: 0,
    clics_sitio_web: 0,
    sociales_tarjeta: [
      {
        id: "TwitterId",
        user_tarjeta_id: "TarjetaId",
        estado: 1,
        text_label: "twitter",
        url_label: null,
        tipo_social: "https://twitter.com/",
        icon_social: "URL",
        clics_realizados: 0,
        created_at: "2023-06-05T21:55:55.000000Z",
        updated_at: "2023-09-26T18:12:16.000000Z",
      },
      {
        id: "InstagramId",
        user_tarjeta_id: "TarjetaId",
        estado: 1,
        text_label: "instagram",
        url_label: null,
        tipo_social: "https://instagram.com/",
        icon_social: "URL",
        clics_realizados: 0,
        created_at: "2023-06-05T21:55:55.000000Z",
        updated_at: "2023-09-26T18:12:16.000000Z",
      },
      {
        id: "FacebookId",
        user_tarjeta_id: "TarjetaId",
        estado: 1,
        text_label: "facebook",
        url_label: null,
        tipo_social: "https://facebook.com/",
        icon_social: "URL",
        clics_realizados: 0,
        created_at: "2023-06-05T21:55:55.000000Z",
        updated_at: "2023-09-26T18:12:16.000000Z",
      },
      {
        id: "YoutubeId",
        user_tarjeta_id: "TarjetaId",
        estado: 1,
        text_label: "youtube",
        url_label: null,
        tipo_social: "https://youtube.com/",
        icon_social: "URL",
        clics_realizados: 0,
        created_at: "2023-06-05T21:55:55.000000Z",
        updated_at: "2023-09-26T18:12:16.000000Z",
      },
      {
        id: "TiktokId",
        user_tarjeta_id: "TarjetaId",
        estado: 1,
        text_label: "tiktok",
        url_label: null,
        tipo_social: "https://tiktok.com/",
        icon_social: "URL",
        clics_realizados: 0,
        created_at: "2023-06-05T21:55:55.000000Z",
        updated_at: "2023-09-26T18:12:16.000000Z",
      },
      {
        id: "LinkedinId",
        user_tarjeta_id: "TarjetaId",
        estado: 1,
        text_label: "linkedin",
        url_label: null,
        tipo_social: "https://linkedin.com/",
        icon_social: "URL",
        clics_realizados: 0,
        created_at: "2023-06-05T21:55:55.000000Z",
        updated_at: "2023-09-26T18:12:16.000000Z",
      },
    ],
    configuraciones_tarjeta: [],
  });
  // Función para cargar la imagen de perfil desde el localStorage
  const cargarImagenesDesdeLocalStorage = () => {
    const imagenPerfilLocalStorage = localStorage.getItem("FotoPerfil");
    const imagenPortadaLocalStorage = localStorage.getItem("FotoPortada");

    setTarjetaAux((prevTarjetaAux) => {
      // Crear una copia del estado anterior
      const updatedTarjetaAux = { ...prevTarjetaAux };

      if (imagenPerfilLocalStorage) {
        // Actualizar la imagen de perfil si existe en el localStorage
        updatedTarjetaAux.img_perfil = imagenPerfilLocalStorage;
      }

      if (imagenPortadaLocalStorage) {
        // Actualizar la imagen de portada si existe en el localStorage
        updatedTarjetaAux.img_portada = imagenPortadaLocalStorage;
      }

      return updatedTarjetaAux; // Devolver el nuevo estado actualizado
    });
  };

  // Escuchar cambios en el localStorage
  useEffect(() => {
    cargarImagenesDesdeLocalStorage();
  }, []);

  useEffect(() => {
    // Escuchar los cambios de local storage
    window.addEventListener("storage", (event) => {
      // Obtener el nuevo contenido de local storage
      const newData = event.currentTarget.localStorage;
      // console.log(newData);
      setTarjetaAux({
        ...tarjetaAux,
        id: "TarjetaId",
        usuario_id: "UsuarioId",
        comercio_id: "ComercioId",
        estado: 0,
        img_perfil: newData.FotoPerfil || perfil,
        img_portada: newData.FotoPortada || portada,
        nombre: newData.Nombre || "Nombre Usuario",
        profesion: newData.Profesion || "Profesión Usuario",
        empresa: newData.Descripcion || "Descripción del Usuario",
        acreditaciones: null,
        telefono: newData.Phone || "0987654321",
        direccion: newData.Ubicacion || "Dirección del Usuario",
        correo: newData.Mail || "ejemplo@correo.com",
        sitio_web: newData.Url || "https://www.ejemplo.com",
        wallet: null,
        clics_realizados: 0,
        clics_guardar: 0,
        clics_correo: 0,
        clics_sitio_web: 0,
        sociales_tarjeta: [
          {
            id: "TwitterId",
            user_tarjeta_id: "TarjetaId",
            estado: 1,
            text_label: "twitter",
            url_label: null,
            tipo_social: "https://twitter.com/",
            icon_social: "URL",
            clics_realizados: 0,
            created_at: "2023-06-05T21:55:55.000000Z",
            updated_at: "2023-09-26T18:12:16.000000Z",
          },
          {
            id: "InstagramId",
            user_tarjeta_id: "TarjetaId",
            estado: 1,
            text_label: "instagram",
            url_label: null,
            tipo_social: "https://instagram.com/",
            icon_social: "URL",
            clics_realizados: 0,
            created_at: "2023-06-05T21:55:55.000000Z",
            updated_at: "2023-09-26T18:12:16.000000Z",
          },
          {
            id: "FacebookId",
            user_tarjeta_id: "TarjetaId",
            estado: 1,
            text_label: "facebook",
            url_label: null,
            tipo_social: "https://facebook.com/",
            icon_social: "URL",
            clics_realizados: 0,
            created_at: "2023-06-05T21:55:55.000000Z",
            updated_at: "2023-09-26T18:12:16.000000Z",
          },
          {
            id: "YoutubeId",
            user_tarjeta_id: "TarjetaId",
            estado: 1,
            text_label: "youtube",
            url_label: null,
            tipo_social: "https://youtube.com/",
            icon_social: "URL",
            clics_realizados: 0,
            created_at: "2023-06-05T21:55:55.000000Z",
            updated_at: "2023-09-26T18:12:16.000000Z",
          },
          {
            id: "TiktokId",
            user_tarjeta_id: "TarjetaId",
            estado: 1,
            text_label: "tiktok",
            url_label: null,
            tipo_social: "https://tiktok.com/",
            icon_social: "URL",
            clics_realizados: 0,
            created_at: "2023-06-05T21:55:55.000000Z",
            updated_at: "2023-09-26T18:12:16.000000Z",
          },
          {
            id: "LinkedinId",
            user_tarjeta_id: "TarjetaId",
            estado: 1,
            text_label: "linkedin",
            url_label: null,
            tipo_social: "https://linkedin.com/",
            icon_social: "URL",
            clics_realizados: 0,
            created_at: "2023-06-05T21:55:55.000000Z",
            updated_at: "2023-09-26T18:12:16.000000Z",
          },
        ],
        configuraciones_tarjeta: [],
      });
      // Actualizar el estado del componente
      // setDatos(newData);
    });
  }, []);

  return (
    <div>
      <section id={styles.division}>
        <div className={`${styles.tarjeta} overflow-auto d-none d-lg-flex`}>
          {/* <ActionAreaCard /> */}
          <Tarjeta data={tarjetaAux} />
        </div>
        <div className="form overflow-auto">
          <StepForm />
        </div>
      </section>
    </div>
  );
}

export default RegisterView;
